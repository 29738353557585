import React, { FC, useEffect, useMemo } from 'react'
import CleaningPCIcon from '@/assets/images/v2/cleaning-pc.svg'
import CoinIcon from '@/assets/images/v2/coin.svg'
import DataProcessingIcon from '@/assets/images/v2/data-processing.svg'
import FinanceAnalyticsIcon from '@/assets/images/v2/finance-analytics.svg'
import WalletIcon from '@/assets/images/v2/wallet.svg'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import PaperContractDeadline from '@/components/v2/molecules/PaperContractDeadline'
import PaperTitleWelcome from '@/components/v2/molecules/PaperTitleWelcome'
import ServiceNotice from '@/components/v2/molecules/ServiceNotice'
import DashboardPanelStepper from '@/templates/v2/dashboards/_dashboardPanelStepper'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  fetchAccount,
  fetchAccountPayment,
  fetchAccountShowStepStatusV2,
  updateAccountSummaryCheckStatusV2,
} from '@/containers/accountsSlice'
import {
  fetchSummaryBudgetDetail,
  fetchSummaryBudgets,
} from '@/containers/summariesSlice'
import MyDataGraphIncomeBody from '@/templates/v2/myData/_myDataGraphIncomeBody'
import MyDataGraphSpendingBody from '@/templates/v2/myData/_myDataGraphSpendingBody'
import MyDataPanelBody from '@/templates/v2/myData/_myDataPanelBody'
import {
  convertToTwoDecimalNumber,
  convertYenToDecimalPointManyen,
} from '@/models/commonsModelFunc'
import moment from 'moment'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { createMtAccessTokenDisconnect } from '@/containers/mtAccessTokensSlice'
import { incomeColorArr, spendingColorArr } from '@/utils/graphColors'
import { setStorageHasOpenedDashboard } from '@/utils/localStorage/hasOpenedDashboardStorage'
import AlertError from '@/components/v2/molecules/AlertError'
import AlertSuccess from '@/components/v2/molecules/AlertSuccess'
import { fetchAifpProposals } from '@/containers/aifpProposalsSlice'
import { find as _find } from 'lodash'

const DashboardIndex: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { stepStatus } = useSelector((state: RootState) => state.accounts)
  const { aifpProposals } = useSelector((state: RootState) => state.aifpProposals)
  const templateIds = aifpProposals.map((item) => item.template_id)

  const hasStepCompleted = useMemo(() => {
    if (!stepStatus) return false

    const uncompletedList = Object.keys(stepStatus).reduce((acc: any, key: any) => {
      if (!stepStatus[key]) {
        acc.push(key)
      }
      return acc
    }, [])

    return uncompletedList.length == 0
  }, [stepStatus])

  const dashboardPanelOptions = useMemo(() => {
    const myData = {
      description: 'まずはざっくり収支の内訳を\n入力してみましょう。',
      buttonLabel: 'マイデータの入力',
      icon: <CoinIcon />,
      isCompleted: stepStatus?.has_input_simple_data,
      onClick: () => handleMovePage('/v2/myData/simple'),
    }
    const moneytree = {
      description:
        'あなたの情報を詳細に入力し、\nさらに詳しくみていきましょう。\nMoneytreeの連携で簡単に入力できます。',
      buttonLabel: 'Moneytreeの連携',
      icon: <CleaningPCIcon />,
      isCompleted: stepStatus?.has_connected_with_moneytree,
      onClick: () => {
        dispatch(
          createMtAccessTokenDisconnect(() =>
            handleMovePage('/v2/plans/moneytreeConnection'),
          ),
        )
      },
    }
    const asset = {
      description:
        '資産や家族情報などを\n詳しく入力することで\nシミュレーションの精度が上がります。',
      buttonLabel: '資産管理の準備',
      icon: <FinanceAnalyticsIcon />,
      isCompleted: stepStatus?.is_ready_asset_management,
      onClick: () => handleMovePage('/v2/myData/families'),
    }
    const mainPlan = {
      description: 'シミュレーションを作成し、\nライフプランを見直しましょう。',
      buttonLabel: 'メインプランの設定',
      icon: <WalletIcon />,
      isCompleted: stepStatus?.has_main_plan,
      onClick: () => handleMovePage('/v2/simulations'),
    }

    if (!stepStatus?.has_input_simple_data) return [myData, moneytree, asset]

    return [moneytree, asset, mainPlan]
  }, [stepStatus])

  const { summaryBudgets } = useSelector((state: RootState) => state.summaries)
  const { account } = useSelector((state: RootState) => state.accounts)

  const isLoadingAccounts = useSelector((state: RootState) => state.accounts.isLoading)
  const isLoadingSummary = useSelector((state: RootState) => state.summaries.isLoading)

  const isFinishingTrialInOneWeek = useMemo(() => {
    return (
      !account['is_app_member'] &&
      !account?.['is_paid_account?'] &&
      moment(account.trial_end_at).isBefore(moment().add(7, 'days'))
    )
  }, [account])

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'ダッシュボード',
        description: 'ダッシュボード',
        keyword: 'ダッシュボード',
      }),
    )

    dispatch(fetchAifpProposals())
    if ((account?.id ?? 0) < 1) dispatch(fetchAccount())
    dispatch(fetchAccountPayment())
    dispatch(fetchAccountShowStepStatusV2())
    dispatch(fetchSummaryBudgets())
    if (account['is_done_with_steps?']) setStorageHasOpenedDashboard(true)
  }, [])

  const transferTableData = useMemo(() => {
    return (
      summaryBudgets.budget_detail.transfer?.category_grouped.map((item) => ({
        name: item.category_name,
        category_key: item.category_key,
        amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
          item.last_term_ratio_percent
            ? '( ' + String(item.last_term_ratio_percent) + '% )'
            : ''
        }`,
        planned_amount: convertToTwoDecimalNumber(item.planned_amount),
        graphs: [{ name: item.category_name, value: item.ratio_percent }],
        journals:
          summaryBudgets.budget_detail.transfer?.all_journals.reduce(
            (arr: any[], obj) => {
              if (obj.category_key == item.category_key) {
                arr.push(obj)
              }
              return arr
            },
            [],
          ) || [],
      })) || []
    )
  }, [summaryBudgets])

  return (
    <>
      <LoadingOverlay isLoading={isLoadingSummary || isLoadingAccounts} />
      <MarginContainer className="px-[8px] md:px-[32px] lg:px-[0] pb-[8px] md:pb-[40px]">
        {!stepStatus?.has_main_plan && (
          <PaperTitleWelcome className="mb-[24px] md:mb-[32px] lg:mb-[40px]" />
        )}

        {isFinishingTrialInOneWeek && (
          <PaperContractDeadline className="mt-[24px] mb-[24px] md:mb-[32px] lg:mb-[40px]" />
        )}

        {templateIds.map((id: number) => {
          const enabeldIds = [10, 11, 12, 13]
          if (!enabeldIds.includes(id))
            return <React.Fragment key={`alert_error_${id}`}></React.Fragment>

          const aifpProposal = _find(aifpProposals, { template_id: id })
          return (
            !!aifpProposal && (
              <AlertError
                className="my-[10px] last:mb-[0] cursor-pointer"
                key={`alert_error_${id}`}
                message={aifpProposal.text}
                onClick={() => {
                  const isOtherApp = aifpProposal.link_path.slice(0, 4) === 'http'
                  isOtherApp
                    ? window.open(aifpProposal.link_path)
                    : handleMovePage(aifpProposal.link_path)
                }}
                hideCloseButton
              />
            )
          )
        })}

        {!hasStepCompleted && (
          <Paper className="mt-[24px] bg-white p-[24px] lg:p-[40px]">
            <Typography
              className="mb-[10px] md:mb-[16px] pb-[10px] text-[18px] md:text-[24px] lg:text-[28px] text-black-800 border-b-2 border-black-300"
              isBold
            >
              情報登録を行いましょう
            </Typography>
            <Typography className="pb-[32px] text-[12px] text-black-700">
              あなたの情報を詳細に登録することで改善案などのご提案が可能になります。
              <br className="hidden md:block lg:hidden" />
              少し大変かと思いますが、頑張って入力してみてください。
            </Typography>
            <DashboardPanelStepper options={dashboardPanelOptions} />
          </Paper>
        )}

        {hasStepCompleted && (
          <Paper className="mt-[24px] bg-white p-[24px] lg:p-[40px]">
            <Typography
              className="mb-[10px] md:mb-[16px] pb-[10px] text-[18px] md:text-[24px] lg:text-[28px] text-black-800 border-b-2 border-black-300"
              isBold
            >
              今月のサマリー
            </Typography>
            <MyDataPanelBody className="pb-80" date={moment().format('YYYY年MM月')} />
            <MyDataGraphIncomeBody
              className="pb-73"
              onJournalChange={() => {
                dispatch(
                  fetchSummaryBudgetDetail({
                    params: { year_month: moment().format('YYYY/MM') },
                  }),
                )
              }}
              balanceCurrentMonth={convertYenToDecimalPointManyen(
                summaryBudgets.current_budgets_info.income_total,
                1,
              )}
              balanceLastMonth={convertYenToDecimalPointManyen(
                summaryBudgets.current_budgets_info.income_defference,
                1,
              )}
              pieGraphData={
                summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                  name: item.category_name,
                  value: item.amount_manyen,
                })) || []
              }
              pieGraphLegendData={
                summaryBudgets.budget_detail.income?.category_grouped.map(
                  (item: any, index: number) => ({
                    name: item.category_name,
                    value: convertToTwoDecimalNumber(item.amount),
                    percent: Math.round(
                      (item.amount / summaryBudgets.current_budgets_info.income_total) *
                        100,
                    ),
                    color: incomeColorArr[index],
                    journals: {},
                  }),
                ) || []
              }
              tableData={
                summaryBudgets.budget_detail.income?.category_grouped.map((item) => ({
                  name: item.category_name,
                  category_key: item.category_key,
                  amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
                    item.last_term_ratio_percent
                      ? '( ' + String(item.last_term_ratio_percent) + '% )'
                      : ''
                  }`,
                  planned_amount: convertToTwoDecimalNumber(item.planned_amount),
                  graphs: [{ name: item.category_name, value: item.ratio_percent }],
                  journals:
                    summaryBudgets.budget_detail.income?.all_journals.reduce(
                      (arr: any[], obj) => {
                        if (obj.category_key == item.category_key) {
                          arr.push(obj)
                        }
                        return arr
                      },
                      [],
                    ) || [],
                })) || []
              }
              transferTableData={transferTableData}
              term="single"
              isJournal={summaryBudgets.budget_detail.is_from_moneytree}
            />

            <MyDataGraphSpendingBody
              className="pb-73"
              onJournalChange={() => {
                dispatch(
                  fetchSummaryBudgetDetail({
                    params: { year_month: moment().format('YYYY/MM') },
                  }),
                )
              }}
              balanceCurrentMonth={convertYenToDecimalPointManyen(
                summaryBudgets.current_budgets_info.spending_total,
                1,
              )}
              balanceLastMonth={convertYenToDecimalPointManyen(
                summaryBudgets.current_budgets_info.spending_defference,
                1,
              )}
              pieGraphData={
                summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                  name: item.category_name,
                  value: item.amount_manyen,
                })) || []
              }
              pieGraphLegendData={
                summaryBudgets.budget_detail.spending?.category_grouped.map(
                  (item: any, index: number) => ({
                    name: item.category_name,
                    value: convertToTwoDecimalNumber(item.amount),
                    percent: Math.round(
                      (item.amount / summaryBudgets.current_budgets_info.spending_total) *
                        100,
                    ),
                    color: spendingColorArr[index],
                    journals: {},
                  }),
                ) || []
              }
              tableData={
                summaryBudgets.budget_detail.spending?.category_grouped.map((item) => ({
                  name: item.category_name,
                  category_key: item.category_key,
                  amount: `${convertToTwoDecimalNumber(item.amount)}万円 ${
                    item.last_term_ratio_percent
                      ? '( ' + String(item.last_term_ratio_percent) + '% )'
                      : ''
                  }`,
                  planned_amount: convertToTwoDecimalNumber(item.planned_amount),
                  graphs: [{ name: item.category_name, value: item.ratio_percent }],
                  journals:
                    summaryBudgets.budget_detail.spending?.all_journals.reduce(
                      (arr: any[], obj) => {
                        if (obj.category_key == item.category_key) {
                          arr.push(obj)
                        }
                        return arr
                      },
                      [],
                    ) || [],
                })) || []
              }
              term="single"
              transferTableData={transferTableData}
              isJournal={summaryBudgets.budget_detail.is_from_moneytree}
            />

            <ButtonPrimary
              className="w-[188px] md:w-[160px] lg:w-[188px] h-[45px] mt-auto mx-auto px-[17px] md:px-[5px] lg:px-[17px]"
              onClick={() => {
                handleMovePage('/v2/myData')
              }}
            >
              <Typography
                className="relative bottom-[2px] flex-1 text-[14px] text-white"
                isBold
              >
                詳しく見る
              </Typography>
              <ChevronRightIcon />
            </ButtonPrimary>
          </Paper>
        )}
      </MarginContainer>
    </>
  )
}

export default DashboardIndex
