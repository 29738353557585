import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import { times as _times, find as _find, isNull as _isNull } from 'lodash'
import { isNotEmptyValue } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { FutureFamilyChildbirth } from '@/models/futures/futureFamilyChildbirthsModel'
import { TWO_TYPES_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS } from '@/constants/formItem/futures/futureFamilyChildbirths'
import { LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS } from '@/constants/formItem/futures/futureFamilyHours'
import { convertDateYear,
  extractYearOrMonthFromDate,
  THIS_YEAR,
} from '@/models/commonsModelFunc'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import {
  createFutureFamilyV2,
  updateFutureFamilyV2,
} from '@/containers/futures/futureFamiliesSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { required } from '@/utils/validate'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import { FutureFamilyBase } from '@/models/futures/futureFamiliesModel'
import { renderStartAtYearOptions } from '@/utils/formItem'

interface ChildbirthScheduledAtsProps {
  num: number
  startChildIndex: number
  childbirthScheduledAt?: string
}


const ChildbirthScheduledAts: FC<ChildbirthScheduledAtsProps> = ({
  num,
  startChildIndex,
  childbirthScheduledAt,
}) => {
  return (
    <div>
      <div className="md:flex items-center pb-16 justify-between">
        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
          <Typography className="pr-16 text-14 text-black-800" isBold>
            {`${startChildIndex + num}人目のお子様の出産予定`}
          </Typography>
        </div>
        <div className="flex items-center justify-end">
          <SelectBox
            placeholder="年を選択"
            name={`childbirth_scheduled_ats[${num}]`}
            widthClassName="w-155 mr-16"
            options={renderStartAtYearOptions(childbirthScheduledAt)}
            validate={required}
          />
        </div>
      </div>
      <Divider className="border-black-300 border-dashed border-t-2" />
    </div>
  )
}

interface ChildbirthCostProps {
  num: number
  startChildIndex: number
}

const ChildbirthCosts: FC<ChildbirthCostProps> = ({ num, startChildIndex }) => {
  return (
    <div>
      <div className="md:flex items-center pb-16 justify-between">
        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
          <Typography className="pr-16 text-14 text-black-800" isBold>
            {`${startChildIndex + num}人目のお子様の出産費用`}
          </Typography>
        </div>
        <div className="flex items-center justify-end">
          <NumberFieldDecimal
            name={`childbirth_costs_manyen[${num}]`}
            className="max-w-[140px]"
            validate={required}
            defaultValue={47.3}
          />
          <Typography className="pl-5 text-14 text-black-800" isBold>
            万円
          </Typography>
        </div>
      </div>
      <Divider className="border-black-300 border-dashed border-t-2" />
    </div>
  )
}

interface ChildcareLeaveMonthsPersonsProps {
  num: number
  startChildIndex: number
}

const ChildcareLeaveMonthsPersons: FC<ChildcareLeaveMonthsPersonsProps> = ({
  num,
  startChildIndex,
}) => {
  return (
    <div>
      <div className="md:flex items-center pb-16 justify-between">
        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
          <Typography className="pr-16 text-14 text-black-800" isBold>
            {`${startChildIndex + num}人目のお子様の育休取得`}
            <br />
            （ご本人）
          </Typography>
        </div>
        <div className="flex items-center justify-end">
          <SelectBox
            placeholder="選択する"
            name={`childcare_leave_months_persons[${num}]`}
            widthClassName="w-155 mr-16"
            options={CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS}
            validate={required}
          />
        </div>
      </div>
      <Divider className="border-black-300 border-dashed border-t-2" />
    </div>
  )
}

interface ChildcareLeaveMonthsSpousesProps {
  num: number
  startChildIndex: number
}

const ChildcareLeaveMonthsSpouses: FC<ChildcareLeaveMonthsSpousesProps> = ({
  num,
  startChildIndex,
}) => {
  return (
    <div>
      <div className="md:flex items-center pb-16 justify-between">
        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
          <Typography className="pr-16 text-14 text-black-800" isBold>
            {`${startChildIndex + num}人目のお子様の育休取得`}
            <br />
            （配偶者）
          </Typography>
        </div>
        <div className="flex items-center justify-end">
          <SelectBox
            placeholder="選択する"
            name={`childcare_leave_months_spouses[${num}]`}
            widthClassName="w-155 mr-16"
            options={CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS}
            validate={required}
          />
        </div>
      </div>
      <Divider className="border-black-300 border-dashed border-t-2" />
    </div>
  )
}

interface FutureChildModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean
  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureChildModalForm: FC<FutureChildModalFormProps> = ({
  isOpen,
  onClose,
  isEdit,
  id,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const familyInfo = useSelector(
    (state: RootState) => state.accounts.futureItems?.family_info,
  )
  const startChildIndex = familyInfo!.total_child_count + 1

  const futureFamilyBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_family_bases,
  )

  const targetFutureFamilyBase: FutureFamilyBase | undefined = useMemo(
    () => (!_isNull(id) ? _find(futureFamilyBases, { id }) : undefined),
    [futureFamilyBases, id],
  )

  const handleSubmit = (values: any) => {
    const { child_number } = values

    const newValues: any = {
      future_family_childbirths_attributes: [],
      future_family_hours_attributes: [
        {
          person_flg: PERSON_FLG.person,
          leaving_years_child_becomes: values.leaving_years_child_becomes_person,
          income_ratio_percent: values.income_person ?? 0,
        },
        {
          person_flg: PERSON_FLG.spouse,
          leaving_years_child_becomes: values.leaving_years_child_becomes_spouse,
          income_ratio_percent: values.income_spouse ?? 0,
        },
      ],
    }

    newValues.future_family_childbirths_attributes = _times(
      Number(child_number),
      (index) => {
        const futureFamilyChildBirth: Partial<FutureFamilyChildbirth> = {}
        const childIndex = index

        futureFamilyChildBirth.id = values.childbirth_ids?.[childIndex]
        futureFamilyChildBirth.childbirth_scheduled_at = convertDateYear(
          values.childbirth_scheduled_ats?.[childIndex],
        )
        futureFamilyChildBirth.childbirth_cost_manyen =
          values.childbirth_costs_manyen?.[childIndex]
        futureFamilyChildBirth.childcare_leave_months_person =
          values.childcare_leave_months_persons?.[childIndex]
        futureFamilyChildBirth.childcare_leave_months_spouse =
          values.childcare_leave_months_spouses?.[childIndex]

        return futureFamilyChildBirth
      },
    )

    return isEdit ? handleUpdate(newValues) : handleCreate(newValues)
  }

  const handleCreate = (newValues: any) => {
    return new Promise((resolve) =>
      dispatch(
        createFutureFamilyV2(
          { account: { future_family_bases_attributes: [newValues] } },
          () => {
            dispatch(fetchAccountFutureItems(futureBaseId))
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            onClose()
          },
          resolve,
        ),
      ),
    )
  }

  const handleUpdate = (newValues: any) => {
    return new Promise((resolve) =>
      dispatch(
        updateFutureFamilyV2({ future_family_base: newValues }, id!, () => {
          dispatch(fetchAccountFutureItems(futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        }),
      ),
    )
  }

  const initialValues = useMemo(() => {
    return isEdit
      ? {
          child_number: targetFutureFamilyBase?.future_family_childbirths.length,
          childbirth_ids: targetFutureFamilyBase?.future_family_childbirths?.map(
            (v: any) => v.id,
          ),
          childbirth_scheduled_ats: targetFutureFamilyBase?.future_family_childbirths?.map(
            (v: any) => extractYearOrMonthFromDate(v.childbirth_scheduled_at, 'year'),
          ),
          childbirth_costs_manyen: targetFutureFamilyBase?.future_family_childbirths?.map(
            (v: any) => v.childbirth_cost_manyen,
          ),
          childcare_leave_months_persons: targetFutureFamilyBase?.future_family_childbirths?.map(
            (v: any) => v.childcare_leave_months_person,
          ),
          childcare_leave_months_spouses: targetFutureFamilyBase?.future_family_childbirths?.map(
            (v: any) => v.childcare_leave_months_spouse,
          ),

          leaving_years_child_becomes_person: _find(
            targetFutureFamilyBase?.future_family_hours,
            { person_flg: PERSON_FLG.person },
          )?.leaving_years_child_becomes,
          leaving_years_child_becomes_spouse: _find(
            targetFutureFamilyBase?.future_family_hours,
            { person_flg: PERSON_FLG.spouse },
          )?.leaving_years_child_becomes,
          income_person: _find(targetFutureFamilyBase?.future_family_hours, {
            person_flg: PERSON_FLG.person,
          })?.income_ratio_percent,
          income_spouse: _find(targetFutureFamilyBase?.future_family_hours, {
            person_flg: PERSON_FLG.spouse,
          })?.income_ratio_percent,
        }
      : {}
  }, [isEdit, targetFutureFamilyBase])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="将来のお子様について"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => {
          const {
            child_number,
            leaving_years_child_becomes_person,
            leaving_years_child_becomes_spouse,
          } = values

          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <div>
                <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
                  <div>
                    <div className="md:flex items-center pb-16 justify-between">
                      <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                        <Typography className="pr-16 text-14 text-black-800" isBold>
                          将来想定する子供の人数
                        </Typography>
                      </div>
                      <div className="flex items-center justify-end">
                        <SelectBox
                          placeholder="選択する"
                          name="child_number"
                          widthClassName="w-155 mr-16"
                          options={[
                            { value: 0, label: '想定しない' },
                            { value: 1, label: '1人' },
                            { value: 2, label: '2人' },
                            { value: 3, label: '3人' },
                            { value: 4, label: '4人' },
                            { value: 5, label: '5人' },
                            { value: 6, label: '6人' },
                            { value: 7, label: '7人' },
                            { value: 8, label: '8人' },
                            { value: 9, label: '9人' },
                          ]}
                          validate={required}
                        />
                      </div>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>
                  <div className="block" />
                </Grid>

                {child_number != '0' && (
                  <>
                    {_times(child_number, (index) => (
                      <>
                        <CircleTitle
                          className="py-[32px]"
                          title={`${index + startChildIndex}人目のお子様について`}
                          classNameTitle="text-[19px] md:text-[24px]"
                        />
                        <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
                          <React.Fragment key={index}>
                            <ChildbirthScheduledAts
                              num={index}
                              startChildIndex={startChildIndex}
                              childbirthScheduledAt={values.childbirth_scheduled_ats?.[index]}
                            />
                            <div className="hidden xl:block" />
                            <ChildbirthCosts
                              num={index}
                              startChildIndex={startChildIndex}
                            />
                            <div className="hidden xl:block" />
                            <ChildcareLeaveMonthsPersons
                              num={index}
                              startChildIndex={startChildIndex}
                            />
                            <ChildcareLeaveMonthsSpouses
                              num={index}
                              startChildIndex={startChildIndex}
                            />
                          </React.Fragment>
                        </Grid>
                      </>
                    ))}
                  </>
                )}

                {child_number != '0' && (
                  <CircleTitle
                    className="py-[32px]"
                    title="時短勤務について"
                    classNameTitle="text-[19px] md:text-[24px]"
                  />
                )}

                <Grid className="gap-x-40 gap-y-16 grid-cols-1 xl:grid-cols-2 pb-24 md:pb-40">
                  {child_number != '0' && (
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            時短勤務
                            <br />
                            （ご本人）
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            placeholder="選択する"
                            name="leaving_years_child_becomes_person"
                            widthClassName="w-[185px] mr-16"
                            options={LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                  )}

                  {child_number != '0' &&
                    (!isNotEmptyValue(leaving_years_child_becomes_person) ||
                      leaving_years_child_becomes_person == '0') && (
                      <div className="hidden xl:block" />
                    )}

                  {child_number != '0' &&
                    isNotEmptyValue(leaving_years_child_becomes_person) &&
                    leaving_years_child_becomes_person != '0' && (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              時短勤務後の給与
                              <br />
                              （ご本人）
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberFieldDecimal
                              name="income_person"
                              className="max-w-[140px]"
                              validate={required}
                            />
                            <Typography className="pl-16 text-14 text-black-800" isBold>
                              %
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    )}

                  {child_number != '0' && (
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <div>
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              時短勤務
                              <br />
                              （配偶者）
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            placeholder="選択する"
                            name="leaving_years_child_becomes_spouse"
                            widthClassName="w-[185px] mr-16"
                            options={LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                  )}

                  {child_number != '0' &&
                    (!isNotEmptyValue(leaving_years_child_becomes_spouse) ||
                      leaving_years_child_becomes_spouse == '0') && (
                      <div className="hidden xl:block" />
                    )}

                  {child_number != '0' &&
                    isNotEmptyValue(leaving_years_child_becomes_spouse) &&
                    leaving_years_child_becomes_spouse != '0' && (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              時短勤務後の給与
                              <br />
                              （配偶者）
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberFieldDecimal
                              name="income_spouse"
                              className="max-w-[140px]"
                              validate={required}
                            />
                            <Typography className="pl-16 text-14 text-black-800" isBold>
                              %
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    )}
                </Grid>
                {child_number != '0' && (
                  <Typography className="text-14 text-center pb-16 text-black-700">
                    一般的に、末子が3歳、６歳、９歳、１２歳になるまで時短勤務ができる制度を設けている会社が多いです。
                    <br />
                    詳細はお勤めの会社にご確認ください。
                  </Typography>
                )}
              </div>
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureChildModalForm