import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { convertLocaleString, convertCostTypeToJp } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import { filter as _filter, find as _find } from 'lodash'
import { PAYMENT_MODE } from '@/models/inputs/inputCustomItemsModelFunc'
import MyDataRiskEdit, { CATEGORY_SELECT_ITEMS, PAYMENT_TARGET_SELECT_ITEMS } from '../myData/risks/_Form'
import Modal from '@/components/v2/atoms/Modal'
import Button from '@/components/v2/atoms/Button'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { fetchInputRisk } from '@/containers/inputs/inputRisksSlice'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'
import SimulationNewButton from './_simulationNewButton'

export const RiskTable: FC<{ onUpdate?: () => void }> = (props) => {
  const { inputRisk } = useSelector((state: RootState) => state.inputRisk)
  const { account } = useSelector((state: RootState) => state.accounts)
  const [modalState, setModalState] = useState<{ isOpen: boolean; id?: number, target?: string }>({
    isOpen: false,
  })
  const dispatch = useDispatch()

  // ご本人が亡くなった場合の想定
  const inputRiskPerson = useMemo(() => {
    return _filter(inputRisk, (item) => Number(item?.['person_flg']) === 0)
  }, [inputRisk])

  // 配偶者が亡くなった場合の想定
  const inputRiskSpouse = useMemo(() => {
    return _filter(inputRisk, (item) => Number(item?.['person_flg']) === 1)
  }, [inputRisk])

  const classes = {
    td: 'w-[30%] px-[16px]',
    tdData: 'w-[70%] px-[16px]',
  }

  return (
    <>
      {inputRiskPerson.length === 0 && (
        <>
          <Typography isBold>ご本人の万が一の保証</Typography>
          <Typography
            className="text-[14px] md:text-[16px] text-center mt-[10px] mb-[20px]"
            isBold
          >
            ご本人が亡くなった場合の
            <br />
            万が一の保障が登録されていません。
          </Typography>

          <SimulationNewButton onClick={() => setModalState({ isOpen: true, target: "person" })} />
        </>
      )}

      {inputRiskPerson.map((item: any, index: number) => (
        <div key={item.id} className="mb-[20px] last:mb-0">
          <Typography isBold>ご本人の万が一の保証</Typography>
          <div className="flex justify-end mb-[8px]">
            <Button onClick={() => setModalState({ isOpen: true, id: item.id, target: "person" })}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>

          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>想定するケース</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>ご本人が亡くなった場合</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>死亡想定年齢</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.death_age)} 歳
                  </Typography>
                </td>
              </tr>


              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>死亡退職金</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.dead_severance_pay_manyen)} 万円
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>弔慰金</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.condolence_pay_manyen)} 万円
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>香典</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.incense_pay_manyen)} 万円
                  </Typography>
                </td>
              </tr>

              {item.input_risk_custom_items.map((customItem: any, index: number) => {
                const isOnce = customItem.payment_mode == PAYMENT_MODE.once
                return (
                  <React.Fragment key={`${index}_income`}>
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className={classes.td}>
                        {index === 0 && (
                          <Typography isBold>
                            会社から貰える
                            <br />
                            その他の保障
                          </Typography>
                        )}
                      </td>
                      <td className={classes.tdData}>
                        <Typography>{customItem.name}</Typography>
                        <Typography>
                          {
                            _find(CATEGORY_SELECT_ITEMS, (items) => {
                              return items.value === customItem.category
                            })?.label
                          }
                        </Typography>
                        <Typography>
                          {
                            _find(PAYMENT_TARGET_SELECT_ITEMS, (items) => {
                              let targetPersonFlg: number = Number(customItem.person_flg)
                              if (customItem.child_index !== null) {
                                targetPersonFlg = customItem.child_index + 2
                              }
                              return Number(items.value) === targetPersonFlg
                            })?.label
                          }
                          が
                          <br />
                          {convertLocaleString(customItem.payment_start_age)}
                          {isOnce ? `歳のとき` : `歳から`}
                          <br />
                          {isOnce
                            ? undefined
                            : `${convertLocaleString(customItem.payment_end_age)}歳まで`}
                        </Typography>
                        <Typography>
                          {convertLocaleString(customItem.amount_manyen)}{' '}
                          {isOnce ? `万円` : `万円/年`}
                        </Typography>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>生活費水準</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>{item.living_cost_rate_percent} %に抑える</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>
                    必要保障額に
                    <br />
                    金融資産を
                  </Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {item.includes_financial_asset === 0 ? '含める' : '含めない'}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>ご葬儀について</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>{convertCostTypeToJp(item.funeral_cost_type)}</Typography>
                  <Typography>
                    {convertLocaleString(item.custom_funeral_cost_manyen)} 万円
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}

      { account['has_spouse?'] && inputRiskSpouse.length === 0 && (
        <>
          <Typography isBold>配偶者の万が一の保証</Typography>
          <Typography className="text-[14px] md:text-[16px] text-center mt-[10px]" isBold>
            配偶者が亡くなった場合の
            <br />
            万が一の保障が登録されていません。
          </Typography>

          <SimulationNewButton onClick={() => setModalState({ isOpen: true, target: "spouse" })} />
        </>
      )}

      {account['has_spouse?'] && inputRiskSpouse.map((item: any, index: number) => (
        <div key={item.id} className="mb-[20px] last:mb-0">
          <Typography isBold>配偶者の万が一の保証</Typography>
          <div className="flex justify-end mb-[8px]">
            <Button onClick={() => setModalState({ isOpen: true, id: item.id, target: "spouse" })}>
              <i className="ico edit text-[14px] text-black-700" />
              <Typography className="pl-[5px] text-[14px] text-black-700">
                編集
              </Typography>
            </Button>
          </div>

          <table className="w-[100%] text-[12px] md:text-[14px]">
            <tbody>
              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>想定するケース</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>配偶者が亡くなった場合</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>死亡想定年齢</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.death_age)} 歳
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>死亡退職金</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.dead_severance_pay_manyen)} 万円
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>弔慰金</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.condolence_pay_manyen)} 万円
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>香典</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {convertLocaleString(item.incense_pay_manyen)} 万円
                  </Typography>
                </td>
              </tr>

              {item.input_risk_custom_items.map((customItem: any, index: number) => {
                const isOnce = customItem.payment_mode == PAYMENT_MODE.once
                return (
                  <React.Fragment key={`${index}_income`}>
                    <tr className="h-[45px] odd:bg-black-100">
                      <td className={classes.td}>
                        {index === 0 && (
                          <Typography isBold>
                            会社から貰える
                            <br />
                            その他の保障
                          </Typography>
                        )}
                      </td>
                      <td className={classes.tdData}>
                        <Typography>{customItem.name}</Typography>
                        <Typography>
                          {
                            _find(CATEGORY_SELECT_ITEMS, (items) => {
                              return items.value === customItem.category
                            })?.label
                          }
                        </Typography>
                        <Typography>
                          {
                            _find(PAYMENT_TARGET_SELECT_ITEMS, (items) => {
                              let targetPersonFlg: number = Number(customItem.person_flg)
                              if (customItem.child_index !== null) {
                                targetPersonFlg = customItem.child_index + 2
                              }
                              return Number(items.value) === targetPersonFlg
                            })?.label
                          }
                          が
                          <br />
                          {convertLocaleString(customItem.payment_start_age)}
                          {isOnce ? `歳のとき` : `歳から`}
                          <br />
                          {isOnce
                            ? undefined
                            : `${convertLocaleString(customItem.payment_end_age)}歳まで`}
                        </Typography>
                        <Typography>
                          {convertLocaleString(customItem.amount_manyen)}{' '}
                          {isOnce ? `万円` : `万円/年`}
                        </Typography>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>生活費水準</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>{item.living_cost_rate_percent} %に抑える</Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>
                    必要保障額に
                    <br />
                    金融資産を
                  </Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>
                    {item.includes_financial_asset === 0 ? '含める' : '含めない'}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className={classes.td}>
                  <Typography isBold>ご葬儀について</Typography>
                </td>
                <td className={classes.tdData}>
                  <Typography>{convertCostTypeToJp(item.funeral_cost_type)}</Typography>
                  <Typography>
                    {convertLocaleString(item.custom_funeral_cost_manyen)} 万円
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}

      <Modal className="lg:w-[1024px] w-[95%]"
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ isOpen: false })}
      >
        <CircleTitle className="pb-[32px]" title="万が一の保証" />
        <MyDataRiskEdit
          riskId={modalState.id}
          target={modalState.target}
          handleCancel={() => setModalState({ isOpen: false })}
          submitCallback={() => {
            dispatch(fetchInputRisk())
            dispatch(updateResetFlg())
            setModalState({ isOpen: false })
            if (props.onUpdate != null) props.onUpdate()
          }}
        />
      </Modal>
    </>
  )
}

const InputRiskBody: FC<{ onUpdate?: () => void }> = (props) => {
  return (
    <SimulationAccordion className="pb-[16px]" title="万が一の保証">
      <RiskTable onUpdate={props.onUpdate} />
    </SimulationAccordion>
  )
}

export default InputRiskBody
