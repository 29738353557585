import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import { isNull as _isNull, find as _find, filter as _filter } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import {
  CANCELLATION_REFUND_TYPE_RADIO_ITEMS,
  CONTRACTOR_TYPE_SELECT_ITEMS,
  CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS,
  INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS,
  INSURANCE_TYPE1_SELECT_ITEMS,
  INSURANCE_TYPE2_SELECT_ITEMS,
  INSURED_TYPE_SELECT_ITEMS,
  INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS,
  INSURANCE_CLASSIFICATION_SELECT_ITEMS,
  NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS,
  NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS,
  REJECT_INSURANCE_TYPE2_SELECT_ITEMS,
} from '@/constants/formItem/commonInsurances'
import {
  AGE_SELECT_ITEMS,
  MONTH_KANJI_SELECT_ITEMS,
  TWO_TYPES_YEAR_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import {
  CANCELLATION_REFUND_TYPE,
  CONTRACTOR_TYPE,
  INSURANCE_FEE_TYPE,
  INSURANCE_PAYMENT_PERIOD_TYPE,
  INSURANCE_PERIOD_TYPE,
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  INSURED_TYPE,
  PURPOSE,
  NON_LIFE_INSURANCE_TYPE1,
  NON_LIFE_INSURANCE_FEE_MONTHS,
  isNotTargetInsuranceType2Selected,
  selectTargetType,
} from '@/models/commonInsurancesModelFunc'
import { convertDateMonth, convertDateToJapaneseFormat } from '@/models/commonsModelFunc'
import {
  createFutureInsuranceV2,
  updateFutureInsuranceV2,
} from '@/containers/futures/futureInsurancesSlice'
import { buildFutureInsurancesInitialValues } from '@/models/futures/futureInsurancesModelFunc'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { RootState } from '@/store'
import {
  buildAgeLimitSelectItems,
  buildStandardSelectItems,
  renderStartAtYearOptions,
} from '@/utils/formItem'
import { required } from '@/utils/validate'

interface FutureInsuranceModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** true: 更新モーダル, false: 新規登録モーダル */
  isEdit: boolean
  /** true: 現在/将来配偶者あり, false: 配偶者なし */
  hasSpouse: boolean
  /** 編集時のID */
  id: number | null
  /** onCloseメソッド */
  onClose: () => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureInsuranceModalForm: FC<FutureInsuranceModalFormProps> = ({
  isOpen,
  isEdit,
  hasSpouse,
  id,
  onClose,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const handleSubmit = (values: any) => {
    const newValues = {
      future_insurance: {
        ...values.future_insurance,
        contracted_at: convertDateMonth(
          values.contracted_year,
          values.contracted_month || '01',
        ),
      },
    }
    const {
      insurance_type1,
      insurance_type2,
      purpose,
      contractor_type,
      insured_type,
      insurance_period_type,
      insurance_fee_payment_period_type,
      insurance_fee_type,
      maturity_money_type,
    } = values.future_insurance

    // 「保険種別1, 保険種別2
    if (isNotTargetInsuranceType2Selected(insurance_type1, insurance_type2)) {
      newValues.future_insurance.insurance_type2 = null
    }

    // 保険種別1: 生命保険以外を選択
    if (insurance_type1 !== INSURANCE_TYPE1.life) {
      newValues.future_insurance.insurance_amount_manyen = null
      newValues.future_insurance.cancellation_refund_amount_manyen = null
      newValues.future_insurance.insured_income_monthly_manyen = null
      newValues.future_insurance.maturity_money = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型以外を選択」
    if (
      insurance_type1 === INSURANCE_TYPE1.life &&
      insurance_type2 !== INSURANCE_TYPE2.wholeLife
    ) {
      newValues.future_insurance.cancellation_refund_amount_input_type = null
      newValues.future_insurance.cancellation_refund_amount_manyen = null
      newValues.future_insurance.cancellation_refund_rate_percent = null
      newValues.future_insurance.cancellation_refund_at = null
      newValues.future_insurance.cancellation_refund_at_age = null
      newValues.future_insurance.cancellation_refund_type = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型を選択」 かつ 「利用目的: 貯蓄のためを選択」
    if (
      insurance_type1 === INSURANCE_TYPE1.life &&
      insurance_type2 === INSURANCE_TYPE2.wholeLife &&
      purpose === PURPOSE.savings
    ) {
      newValues.future_insurance.cancellation_refund_at = convertDateMonth(
        values.cancellation_refund_at_year,
        values.cancellation_refund_at_month,
      )
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 終身型を選択」 かつ 「利用目的: 保証のためを選択」
    if (
      insurance_type1 === INSURANCE_TYPE1.life &&
      insurance_type2 === INSURANCE_TYPE2.wholeLife &&
      purpose === PURPOSE.security
    ) {
      newValues.future_insurance.cancellation_refund_amount_input_type = null
      newValues.future_insurance.cancellation_refund_amount_manyen = null
      newValues.future_insurance.cancellation_refund_rate_percent = null
      newValues.future_insurance.cancellation_refund_target = null
      newValues.future_insurance.cancellation_refund_at = null
      newValues.future_insurance.cancellation_refund_at_age = null
      newValues.future_insurance.cancellation_refund_type = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 収入保障保険以外を選択」
    if (
      insurance_type1 === INSURANCE_TYPE1.life &&
      insurance_type2 !== INSURANCE_TYPE2.incomeSecurity
    ) {
      newValues.future_insurance.insured_income_monthly_manyen = null
    }

    // 「保険種別1: 生命保険を選択」 かつ 「保険種別2: 養老保険以外を選択」
    if (
      insurance_type1 === INSURANCE_TYPE1.life &&
      insurance_type2 !== INSURANCE_TYPE2.endowment
    ) {
      newValues.future_insurance.maturity_money_type = null
      newValues.future_insurance.maturity_money = null
    }

    // 保険種別: 個人年金保険以外を選択
    if (insurance_type1 !== INSURANCE_TYPE1.personalAnnuity) {
      newValues.future_insurance.receiving_pension_at = null
      newValues.future_insurance.receiving_pension_at_age = null
      newValues.future_insurance.receiving_pension_amount = null
      newValues.future_insurance.receiving_years = null
      newValues.future_insurance.receiving_guaranteed_years = null
    }

    // 契約者: 自由入力以外を選択
    if (contractor_type !== CONTRACTOR_TYPE.otherPersonContract) {
      newValues.future_insurance.contractor_name = null
      newValues.future_insurance.insurance_fee_payment_period_finish_at = null
    }

    // 契約者: 自由入力を選択
    if (contractor_type === CONTRACTOR_TYPE.otherPersonContract) {
      newValues.future_insurance.insurance_fee_payment_period_finish_at_age = null
    }
    // 契約者: 自由入力を選択 かつ 保険の払い込み: 入力するを選択
    if (
      contractor_type === CONTRACTOR_TYPE.otherPersonContract &&
      insurance_fee_payment_period_type === INSURANCE_PERIOD_TYPE.age
    ) {
      newValues.future_insurance.insurance_fee_payment_period_finish_at = convertDateMonth(
        values.insurance_fee_payment_period_finish_year,
        values.insurance_fee_payment_period_finish_month,
      )
    }

    // 被保険者: 自由入力以外を選択
    if (insured_type !== INSURED_TYPE.otherPersonInsured) {
      newValues.future_insurance.insured_name = null
      newValues.future_insurance.insurance_period_finish_at = null
    }

    // 被保険者: 自由入力を選択
    if (insured_type === INSURED_TYPE.otherPersonInsured) {
      newValues.future_insurance.insurance_period_finish_at_age = null
      newValues.future_insurance.insurance_period_finish_at = convertDateMonth(
        values.insurance_period_finish_year,
        values.insurance_period_finish_month,
      )
    }
    // 被保険者: 自由入力を選択 かつ 保険期間: 入力するを選択
    if (
      insured_type === INSURED_TYPE.otherPersonInsured &&
      insurance_period_type === INSURANCE_PERIOD_TYPE.age
    ) {
      newValues.future_insurance.insurance_period_finish_at = convertDateMonth(
        values.insurance_period_finish_year,
        values.insurance_period_finish_month,
      )
    }

    // 保険期間: 終身を選択
    if (insurance_period_type === INSURANCE_PERIOD_TYPE.wholeLife) {
      newValues.future_insurance.insurance_period_finish_at = null
      newValues.future_insurance.insurance_period_finish_at_age = null
    }

    // 保険の払い込み: 終身を選択
    if (insurance_fee_payment_period_type === INSURANCE_PERIOD_TYPE.wholeLife) {
      newValues.future_insurance.insurance_fee_payment_period_finish_at = null
      newValues.future_insurance.insurance_fee_payment_period_finish_at_age = null
    }

    // 保険の払い込み: 1回のみを選択
    if (insurance_fee_payment_period_type === INSURANCE_PERIOD_TYPE.oneTime) {
      newValues.future_insurance.insurance_fee_payment_period_finish_at = null
      newValues.future_insurance.insurance_fee_payment_period_finish_at_age = null
      newValues.future_insurance.insurance_fee_payment_occurrence_in_months = null
    }

    // 月々の保険料: なしを選択
    if (insurance_fee_type === INSURANCE_FEE_TYPE.none) {
      newValues.future_insurance.insurance_fee = null
    }

    // 満期金: なしを選択
    if (maturity_money_type === INSURANCE_FEE_TYPE.none) {
      newValues.future_insurance.maturity_money = null
    }

    return new Promise((resolve) =>
      isEdit ? handleUpdate(newValues) : handleCreate(newValues, resolve),
    )
  }

  const handleCreate = (newValues: any, resolve: any) => {
    return dispatch(
      createFutureInsuranceV2(
        {
          account: {
            future_insurances_attributes: [newValues.future_insurance],
          },
        } as any,
        () => {
          dispatch(fetchAccountFutureItems(futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
        resolve,
      ),
    )
  }

  const handleUpdate = (newValues: any) => {
    dispatch(
      updateFutureInsuranceV2(
        {
          future_insurance: newValues.future_insurance,
        } as any,
        id as any,
        () => {
          dispatch(fetchAccountFutureItems(futureBaseId))
          showFlashMsg(isEdit ? '更新しました' : '作成しました')
          onClose()
        },
      ),
    )
  }

  const futureInsurances = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_insurances,
  )

  const futureCars = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_cars,
  )

  const futureEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investments,
  )

  const futureHousing = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_housings,
  )

  const futureV2Housings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_v2_housings,
  )

  const { familyInfoV2 } = useSelector((state: RootState) => state.familyStructures)

  const familyAge = {
    person_current_age: familyInfoV2?.person_age,
    spouse_current_age: familyInfoV2?.spouse_age,
  }

  const insuredSubjectPropertySelectItems = useMemo(() => {
    const futureV2HousingSelectItems = futureV2Housings?.map((item: any) => ({
      label: `${convertDateToJapaneseFormat(
        item?.scheduled_at,
        'year',
      )}に購入予定のマイホーム`,
      value: `FutureV2Housing_${item.id}`,
    }))

    const estateInvestmentSelectItems = futureEstateInvestments?.map((item: any) => ({
      label: item.property_name_for_option,
      value: `FutureEstateInvestment_${item.id}`,
    }))

    if (_isNull(futureHousing) || futureHousing?.length == 0) {
      return [...futureV2HousingSelectItems!, ...estateInvestmentSelectItems!]
    }

    const tempFutureHousing: any = futureHousing
    const housingSelectItems = {
      label: `${convertDateToJapaneseFormat(
        tempFutureHousing?.purchased_at,
        'year',
      )}に購入予定のマイホーム`,
      value: `FutureHousing_${tempFutureHousing?.id}`,
    }

    return [
      ...futureV2HousingSelectItems!,
      housingSelectItems,
      ...estateInvestmentSelectItems!,
    ]
  }, [])

  const insuredSubjectCarSelectItems = useMemo(
    () =>
      futureCars?.map((item: any) => ({
        label: `${item.ride_start_at_age}歳から${item.ride_end_at_age}歳まで乗る予定の${item.purchase_price_manyen}万円のお車`,
        value: `FutureCar_${item.id}`,
      })),
    [],
  )

  const initialValues = useMemo(() => {
    const targetFutureInsurances = _find(futureInsurances, { id })

    return isEdit && !!targetFutureInsurances
      ? buildFutureInsurancesInitialValues(targetFutureInsurances)
      : {
          future_insurance: {
            insurance_classification: null,
            insurance_type1: null,
            insurance_type2: null,
            insurance_fee_payment_period_type: null,
            insurance_period_type: null,
            purpose: null,
            contractor_type: null,
            insured_type: null,
            maturity_money_type: null,
            cancellation_refund_amount_input_type: null,
          },
        }
  }, [isEdit, futureInsurances, id])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="将来の保険を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values, errors, submitErrors, submitFailed }) => {
          const {
            insurance_type1,
            insurance_type2,
            insurance_fee_payment_period_type,
            insurance_period_type,
            purpose,
            contractor_type,
            insured_type,
            classification,
            maturity_money_type,
            cancellation_refund_amount_input_type,
            insurance_fee_payment_occurrence_in_months,
            contracted_at
          } = values.future_insurance as any

          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <div>
                <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
                  <div>
                    <div className="md:flex pb-16 justify-between">
                      <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                        <Typography className="pr-16 text-14 text-black-800" isBold>
                          保険の種類
                        </Typography>
                      </div>
                      <div className="justify-end">
                        <SelectBox
                          name="future_insurance.classification"
                          placeholder="選択してください"
                          options={INSURANCE_CLASSIFICATION_SELECT_ITEMS}
                          isFullWidth
                          validate={required}
                        />
                      </div>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>
                </Grid>
                {classification === 'life' && (
                  <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            契約年月
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="年を選択"
                            name="contracted_year"
                            widthClassName="mr-16"
                            options={renderStartAtYearOptions(contracted_at)}
                            validate={required}
                          />
                          <SelectBox
                            widthClassName="w-full md:w-[80px]"
                            placeholder="月を選択"
                            name="contracted_month"
                            options={MONTH_KANJI_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            加入している保険会社
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <TextField
                            isFullWidth
                            type="text-right"
                            name="future_insurance.company_name"
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            保険の種別①
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="選択する"
                            name="future_insurance.insurance_type1"
                            options={INSURANCE_TYPE1_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    {insurance_type1 !== INSURANCE_TYPE1.personalAnnuity ? (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              保険の種別②
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              placeholder="選択する"
                              name="future_insurance.insurance_type2"
                              options={
                                insurance_type1 === INSURANCE_TYPE1.life
                                  ? INSURANCE_TYPE2_SELECT_ITEMS
                                  : REJECT_INSURANCE_TYPE2_SELECT_ITEMS
                              }
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    ) : (
                      <div />
                    )}

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 md:max-w-[280px] pb-10 md:pb-0">
                          <div>
                            <Typography className="pb-8 text-14 text-black-800" isBold>
                              利用目的
                            </Typography>
                            <Typography className="text-10 text-black-700">
                              貯蓄として計上した場合、シミュレーション上で
                              <br className="hidden md:block" />
                              当該保険を解約する時に保障から削減されます。
                            </Typography>
                          </div>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="選択する"
                            name="future_insurance.purpose"
                            options={[
                              {
                                label: '保障のため',
                                value: PURPOSE.security,
                              },
                              {
                                label: '貯蓄のため',
                                value: PURPOSE.savings,
                              },
                            ]}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            契約者
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="選択する"
                            name="future_insurance.contractor_type"
                            options={
                              hasSpouse
                                ? CONTRACTOR_TYPE_SELECT_ITEMS
                                : CONTRACTOR_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS
                            }
                            validate={required}
                          />
                          {contractor_type === CONTRACTOR_TYPE.otherPersonContract && (
                            <TextField
                              isFullWidth
                              type="text-right"
                              name="future_insurance.contractor_name"
                              placeholder="契約者名を入力"
                              className="ml-30 text-[14px] placeholder-black-300"
                              validate={required}
                            />
                          )}
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            被保険者
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="選択する"
                            name="future_insurance.insured_type"
                            options={
                              hasSpouse
                                ? INSURED_TYPE_SELECT_ITEMS
                                : INSURED_TYPE_WITHOUT_SPOUSE_SELECT_ITEMS
                            }
                            validate={required}
                          />
                          {insured_type === INSURED_TYPE.otherPersonInsured && (
                            <TextField
                              isFullWidth
                              type="text-right"
                              placeholder="被保険者を入力"
                              name="future_insurance.insured_name"
                              className="ml-30 text-[14px] placeholder-black-300"
                              validate={required}
                            />
                          )}
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            保険適用期間
                          </Typography>
                        </div>
                        <div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              placeholder="選択する"
                              name="future_insurance.insurance_period_type"
                              options={
                                insured_type !== INSURED_TYPE.otherPersonInsured
                                  ? [
                                      {
                                        label: '終身',
                                        value: INSURANCE_PERIOD_TYPE.wholeLife,
                                      },
                                      {
                                        label: '入力する',
                                        value: INSURANCE_PERIOD_TYPE.age,
                                      },
                                    ]
                                  : [
                                      {
                                        label: '終身',
                                        value: INSURANCE_PERIOD_TYPE.wholeLife,
                                      },
                                      {
                                        label: '入力する',
                                        value: INSURANCE_PERIOD_TYPE.age,
                                      },
                                    ]
                              }
                              validate={required}
                            />
                          </div>
                          {insured_type !== INSURED_TYPE.otherPersonInsured &&
                            insurance_period_type === INSURANCE_PERIOD_TYPE.age && (
                              <div className="u-flex items-center mt-[10px]">
                                <Typography
                                  className="mr-[10px] text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  被保険者が
                                </Typography>
                                <SelectBox
                                  isFullWidth
                                  placeholder="年齢を選択"
                                  name="future_insurance.insurance_period_finish_at_age"
                                  options={buildStandardSelectItems(
                                    buildAgeLimitSelectItems(
                                      selectTargetType(insured_type, familyAge),
                                    ),
                                    60,
                                    '選択する',
                                  )}
                                  validate={required}
                                />
                                <Typography
                                  className="ml-[10px] text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  歳まで
                                </Typography>
                              </div>
                            )}
                          {insured_type === INSURED_TYPE.otherPersonInsured &&
                            insurance_period_type === INSURANCE_PERIOD_TYPE.age && (
                              <div className="u-flex items-center mt-[10px]">
                                <SelectBox
                                  isFullWidth
                                  placeholder="年を選択"
                                  className="mx-[5px]"
                                  name="insurance_period_finish_year"
                                  options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                  validate={required}
                                />
                                <SelectBox
                                  isFullWidth
                                  className="mx-[5px]"
                                  placeholder="月を選択"
                                  name="insurance_period_finish_month"
                                  options={MONTH_KANJI_SELECT_ITEMS}
                                  validate={required}
                                />
                                <Typography
                                  className="pl-[10px] text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  まで
                                </Typography>
                              </div>
                            )}
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            払い込み期間
                          </Typography>
                        </div>
                        <div>
                          <div className="u-tar flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              placeholder="選択する"
                              name="future_insurance.insurance_fee_payment_period_type"
                              options={
                                contractor_type !== CONTRACTOR_TYPE.otherPersonContract
                                  ? [
                                      {
                                        label: '終身',
                                        value: INSURANCE_PAYMENT_PERIOD_TYPE.wholeLife,
                                      },
                                      {
                                        label: '1回のみ',
                                        value: INSURANCE_PAYMENT_PERIOD_TYPE.oneTime,
                                      },
                                      {
                                        label: '入力する',
                                        value: INSURANCE_PAYMENT_PERIOD_TYPE.age,
                                      },
                                    ]
                                  : [
                                      {
                                        label: '終身',
                                        value: INSURANCE_PAYMENT_PERIOD_TYPE.wholeLife,
                                      },
                                      {
                                        label: '1回のみ',
                                        value: INSURANCE_PAYMENT_PERIOD_TYPE.oneTime,
                                      },
                                      {
                                        label: '入力する',
                                        value: INSURANCE_PAYMENT_PERIOD_TYPE.age,
                                      },
                                    ]
                              }
                              validate={required}
                            />
                          </div>
                          {contractor_type !== CONTRACTOR_TYPE.otherPersonContract &&
                            insurance_fee_payment_period_type ===
                              INSURANCE_PAYMENT_PERIOD_TYPE.age && (
                              <div className="u-flex items-center mt-[10px]">
                                <Typography
                                  className="px-16 text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  契約者が
                                </Typography>
                                <SelectBox
                                  isFullWidth
                                  placeholder="年齢を選択"
                                  name="future_insurance.insurance_fee_payment_period_finish_at_age"
                                  options={buildStandardSelectItems(
                                    buildAgeLimitSelectItems(
                                      selectTargetType(contractor_type, familyAge),
                                    ),
                                    60,
                                    '選択する',
                                  )}
                                  validate={required}
                                />
                                <Typography
                                  className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  歳まで
                                </Typography>
                              </div>
                            )}
                          {contractor_type === CONTRACTOR_TYPE.otherPersonContract &&
                            insurance_fee_payment_period_type ===
                              INSURANCE_PAYMENT_PERIOD_TYPE.age && (
                              <div className="u-flex items-center mt-[10px]">
                                <SelectBox
                                  isFullWidth
                                  placeholder="年を選択"
                                  className="mx-[5px]"
                                  name="insurance_fee_payment_period_finish_year"
                                  options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                  validate={required}
                                />
                                <SelectBox
                                  isFullWidth
                                  placeholder="月を選択"
                                  className="mx-[5px]"
                                  name="insurance_fee_payment_period_finish_month"
                                  options={MONTH_KANJI_SELECT_ITEMS}
                                  validate={required}
                                />
                                <Typography
                                  className="ml-[10px] text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  まで
                                </Typography>
                              </div>
                            )}
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    {insurance_fee_payment_period_type !==
                      INSURANCE_PERIOD_TYPE.oneTime && (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              支払い頻度
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              placeholder="選択する"
                              name="future_insurance.insurance_fee_payment_occurrence_in_months"
                              options={
                                INSURANCE_FEE_PAYMENT_OCCURRENCE_IN_MONTHS_RADIO_ITEMS
                              }
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    )}

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            一回あたりの保険料
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <NumberField
                            isFullWidth
                            name="future_insurance.insurance_fee"
                            validate={required}
                            className="md:max-w-[120px]"
                          />
                          <Typography className="pl-16 text-14 text-black-800" isBold>
                            円
                          </Typography>
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    {insurance_type1 === INSURANCE_TYPE1.life &&
                      insurance_type2 !== INSURANCE_TYPE2.incomeSecurity && (
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                死亡保険金額
                              </Typography>
                              <Tooltip
                                item="死亡保険金額とは、保険事故（死亡・病気など）が生じた場合に支払われる金額のことです。"
                                itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                              >
                                <HelpCircleIcon />
                              </Tooltip>
                            </div>
                            <div className="flex items-center justify-end">
                              <NumberField
                                isFullWidth
                                name="future_insurance.insurance_amount_manyen"
                                validate={required}
                              />
                              <Typography
                                className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                                isBold
                              >
                                万円
                              </Typography>
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      )}

                    {insurance_type1 === INSURANCE_TYPE1.life && (
                      <>
                        {insurance_type2 === INSURANCE_TYPE2.wholeLife &&
                          purpose === PURPOSE.savings && (
                            <>
                              <div>
                                <div className="md:flex items-center pb-16 justify-between">
                                  <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                                    <Typography
                                      className="pr-16 text-14 text-black-800"
                                      isBold
                                    >
                                      解約金返還時の年齢
                                    </Typography>
                                  </div>
                                  {insured_type !== INSURED_TYPE.otherPersonInsured ? (
                                    <div className="flex items-center justify-end">
                                      <SelectBox
                                        isFullWidth
                                        placeholder="年齢を選択"
                                        className=""
                                        name="future_insurance.cancellation_refund_at_age"
                                        options={
                                          _filter(
                                            AGE_SELECT_ITEMS,
                                            (item: any) => item.value > 18,
                                          ) as any
                                        }
                                        validate={required}
                                      />
                                      <Typography
                                        className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                                        isBold
                                      >
                                        歳時
                                      </Typography>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-end">
                                      <SelectBox
                                        isFullWidth
                                        placeholder="年を選択"
                                        className="ml-20"
                                        name="cancellation_refund_at_year"
                                        options={TWO_TYPES_YEAR_SELECT_ITEMS}
                                        validate={required}
                                      />
                                      <SelectBox
                                        isFullWidth
                                        placeholder="月を選択"
                                        className="mx-20"
                                        name="cancellation_refund_at_month"
                                        options={MONTH_KANJI_SELECT_ITEMS}
                                        validate={required}
                                      />
                                      <Typography className="">に</Typography>
                                    </div>
                                  )}
                                </div>
                                <Divider className="border-black-300 border-dashed border-t-2" />
                              </div>

                              <div>
                                <div className="md:flex items-center pb-16 justify-between">
                                  <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                                    <Typography
                                      className="pr-16 text-14 text-black-800"
                                      isBold
                                    >
                                      解約返戻金の
                                      <br />
                                      金額の算出方法
                                    </Typography>
                                  </div>
                                  <div className="md:flex items-center justify-end">
                                    <SelectBox
                                      isFullWidth
                                      placeholder="選択する"
                                      name="future_insurance.cancellation_refund_amount_input_type"
                                      className="pr-[10px]"
                                      options={CANCELLATION_REFUND_TYPE_RADIO_ITEMS}
                                      validate={required}
                                    />

                                    {cancellation_refund_amount_input_type ===
                                    CANCELLATION_REFUND_TYPE.amount ? (
                                      <div className="flex items-center mt-[16px] md:mt-0">
                                        <NumberField
                                          isFullWidth
                                          name="future_insurance.cancellation_refund_amount_manyen"
                                          validate={required}
                                        />
                                        <Typography
                                          className="pl-[10px] text-14 text-black-800 min-w-[fit-content]"
                                          isBold
                                        >
                                          万円
                                        </Typography>
                                      </div>
                                    ) : (
                                      <div className="flex items-center mt-[16px] md:mt-0">
                                        <NumberFieldDecimal
                                          isFullWidth
                                          name="future_insurance.cancellation_refund_rate_percent"
                                          validate={required}
                                        />
                                        <Typography className="pl-[10px]">％</Typography>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <Divider className="border-black-300 border-dashed border-t-2" />
                              </div>
                            </>
                          )}

                        {insurance_type2 === INSURANCE_TYPE2.incomeSecurity && (
                          <div>
                            <div className="md:flex items-center pb-16 justify-between">
                              <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                                <Typography
                                  className="pr-16 text-14 text-black-800"
                                  isBold
                                >
                                  毎月の保険金額
                                </Typography>
                              </div>
                              <div className="flex items-center justify-end">
                                <NumberField
                                  isFullWidth
                                  name="future_insurance.insured_income_monthly_manyen"
                                  validate={required}
                                />
                                <Typography
                                  className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                                  isBold
                                >
                                  万円
                                </Typography>
                              </div>
                            </div>
                            <Divider className="border-black-300 border-dashed border-t-2" />
                          </div>
                        )}
                        {insurance_type2 === INSURANCE_TYPE2.endowment && (
                          <div>
                            <div className="md:flex items-center pb-16 justify-between">
                              <div className="flex items-center min-w-[60px] pb-10 md:pb-0">
                                <Typography
                                  className="pr-16 text-14 text-black-800"
                                  isBold
                                >
                                  満期金
                                </Typography>
                              </div>
                              <div className="flex items-center justify-end">
                                <SelectBox
                                  isFullWidth
                                  placeholder="選択する"
                                  name="future_insurance.maturity_money_type"
                                  options={[
                                    {
                                      label: '金額入力',
                                      value: INSURANCE_FEE_TYPE.input,
                                    },
                                    {
                                      label: 'なし',
                                      value: INSURANCE_FEE_TYPE.none,
                                    },
                                  ]}
                                  validate={required}
                                />
                                {maturity_money_type === INSURANCE_FEE_TYPE.input && (
                                  <div className="flex items-center mx-10 lg:mx-30 min-w-[140px] lg:min-w-[160px]">
                                    <NumberField
                                      isFullWidth
                                      name="future_insurance.maturity_money"
                                      validate={required}
                                    />
                                    <Typography className="ml-10 lg:ml-30">円</Typography>
                                  </div>
                                )}
                              </div>
                            </div>
                            <Divider className="border-black-300 border-dashed border-t-2" />
                          </div>
                        )}
                      </>
                    )}
                    {insurance_type1 === INSURANCE_TYPE1.personalAnnuity && (
                      <>
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                年金の受け取り開始年齢
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <SelectBox
                                isFullWidth
                                placeholder="年齢を選択"
                                name="future_insurance.receiving_pension_at_age"
                                options={
                                  _filter(
                                    AGE_SELECT_ITEMS,
                                    (item: any) => item.value > 60,
                                  ) as any
                                }
                                validate={required}
                              />
                              <Typography className="ml-16 text-14 text-black-800" isBold>
                                歳
                              </Typography>
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                1年間の受取額
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <NumberField
                                isFullWidth
                                name="future_insurance.receiving_pension_amount"
                                validate={required}
                              />
                              <Typography
                                className="ml-16 text-14 text-black-800 min-w-[max-content]"
                                isBold
                              >
                                円/年
                              </Typography>
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>

                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                年金の受取期間
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <NumberField
                                isFullWidth
                                name="future_insurance.receiving_years"
                                className="ml-16"
                                validate={required}
                              />
                              <Typography className="ml-16 text-14 text-black-800" isBold>
                                年
                              </Typography>
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                年金の保証期間
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <NumberField
                                isFullWidth
                                name="future_insurance.receiving_guaranteed_years"
                                className="ml-16"
                                validate={required}
                              />
                              <Typography className="ml-16 text-14 text-black-800" isBold>
                                年
                              </Typography>
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      </>
                    )}
                  </Grid>
                )}

                {classification === 'non_life' && (
                  <Grid className="gap-x-40 gap-y-16 grid-cols-1 md:grid-cols-2 pb-24 md:pb-40">
                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            保険の名称
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <TextField
                            isFullWidth
                            type="text-rigth"
                            name="future_insurance.company_name"
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            保険の種類
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="選択する"
                            name="future_insurance.insurance_type1"
                            options={NON_LIFE_INSURANCE_TYPE1_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            保険料の支払い方法
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="選択する"
                            name="future_insurance.insurance_fee_payment_occurrence_in_months"
                            options={NON_LIFE_INSURANCE_FEE_MONTHS_RADIO_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            契約した年
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <SelectBox
                            isFullWidth
                            placeholder="年を選択"
                            name="contracted_year"
                            options={TWO_TYPES_YEAR_SELECT_ITEMS}
                            validate={required}
                          />
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    {insurance_fee_payment_occurrence_in_months ==
                      NON_LIFE_INSURANCE_FEE_MONTHS.bulk && (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              更新頻度
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              isFullWidth
                              name="insurance_fee_payment_occurrence_in_year"
                              validate={required}
                            />
                            <Typography className="pl-16 text-14 text-black-800" isBold>
                              年
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    )}

                    <div>
                      <div className="md:flex items-center pb-16 justify-between">
                        <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                          <Typography className="pr-16 text-14 text-black-800" isBold>
                            保険料
                          </Typography>
                        </div>
                        <div className="flex items-center justify-end">
                          <NumberField
                            isFullWidth
                            name="future_insurance.insurance_fee"
                            validate={required}
                          />
                          <Typography className="pl-16 text-14 text-black-800" isBold>
                            円
                          </Typography>
                        </div>
                      </div>
                      <Divider className="border-black-300 border-dashed border-t-2" />
                    </div>

                    {insurance_type1 == NON_LIFE_INSURANCE_TYPE1.vehicle && (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              保険対象のお車
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              placeholder="選択する"
                              name="insured_subject_cars"
                              options={insuredSubjectCarSelectItems || []}
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    )}

                    {(insurance_type1 == NON_LIFE_INSURANCE_TYPE1.fire ||
                      insurance_type1 == NON_LIFE_INSURANCE_TYPE1.earthquake) && (
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              保険対象の物件
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              placeholder="選択する"
                              name="insured_subject_properties"
                              options={insuredSubjectPropertySelectItems}
                              validate={required}
                            />
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    )}
                  </Grid>
                )}
              </div>
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
                // isDisabled={Object.keys(errors).length > 0}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureInsuranceModalForm