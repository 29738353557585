import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash'
import { parse } from 'query-string'
import ApiClient from '@/api/apiClient'
import MarginContainer from '@/components/v2/atoms/MarginContainer'
import Link from '@/components/v2/atoms/Link'
import Heading from '@/components/v2/atoms/Heading'
import Paper from '@/components/v2/atoms/Paper'
import Typography from '@/components/v2/atoms/Typography'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { accountLogin } from '@/containers/accountsSlice'
import { removeAllFlashAlerts, setFlashAlert } from '@/containers/flashAlertsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import LogindRedirect from '@/layouts/v2/LogindRedirect'
import { AccountLoginResponse } from '@/models/accountsModel'
import { RootState } from '@/store'
import { setStorageHasNoEnoughInputData } from '@/utils/localStorage/hasNoEnoughInputDataStorage'
import { setStorageHasNoFamilyStructure } from '@/utils/localStorage/hasNoFamilyStructureStorage'
import { setStorageIsDisabledTrialAccount } from '@/utils/localStorage/isDisabledTrialAccountStorage'
import { setStorageIsDisabledPaidAccount } from '@/utils/localStorage/isDisabledPaidAccountStorage'
import { setStorageAuthHeaders } from '@/utils/localStorage/authHeadersStorage'
import { composeValidators, mailAddress, password, required } from '@/utils/validate'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'

const Login: FC = () => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()

  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined)
  const { flashAlertMessageObjects } = useSelector(
    (state: RootState) => state.flashAlerts,
  )
  const { isLoading } = useSelector((state: RootState) => state.accounts)

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'ログイン',
        description: 'ログイン',
        keyword: 'ログイン',
      }),
    )

    const {
      token,
      hasNoFamilyStructure,
      hasNoEnoughInputData,
      isDisabledTrialAccount,
      isDisabledPaidAccount,
      redirectUrl,
    } = parse(location.search)

    !_isUndefined(redirectUrl) && setRedirectUrl(redirectUrl as string)
    if (_isUndefined(token)) return

    const headers = setStorageAuthHeaders(token as string)
    ApiClient.setAuthHeaders(headers)

    setStorageHasNoFamilyStructure(hasNoFamilyStructure === 'true')
    setStorageHasNoEnoughInputData(hasNoEnoughInputData === 'true')
    setStorageIsDisabledTrialAccount(isDisabledTrialAccount === 'true')
    setStorageIsDisabledPaidAccount(isDisabledPaidAccount === 'true')

    handleMovePage('/v2/dashboards')
  }, [])

  const showFlashMsg = (isDisabledTrialAccount: boolean) => {
    if (!_isEmpty(flashAlertMessageObjects)) {
      dispatch(removeAllFlashAlerts())
    }

    const msg = isDisabledTrialAccount
      ? 'トライアル期限を過ぎているため、アプリ利用にはプラン更新が必要です'
      : '決済が失敗状態のため、アプリ利用には支払い完了のご確認が必要です'
    dispatch(
      setFlashAlert({
        flashAlertMessageObjects: [{ message: msg, variant: 'error' }],
      }),
    )
  }

  const handleSubmit = (values: any) => {
    const isInStandaloneMode =
      (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
      (window.navigator as any).standalone;
    values.is_standalone = isInStandaloneMode

    return new Promise((resolve) =>
      dispatch(
        accountLogin(
          values,
          (response: AccountLoginResponse) => {
            setStorageHasNoFamilyStructure(response.has_no_family_structure)
            setStorageHasNoEnoughInputData(response.has_no_enough_input_data)
            setStorageIsDisabledTrialAccount(response['is_disabled_trial_account?'])
            setStorageIsDisabledPaidAccount(response['is_disabled_paid_account?'])

            if (
              !response['is_app_member'] &&
              (response['is_disabled_trial_account?'] ||
              response['is_disabled_paid_account?'])
            ) {
              showFlashMsg(response['is_disabled_trial_account?'])
              return handleMovePage('/v2/settings/account')
            }

            if (response.has_no_family_structure) {
              return handleMovePage('/v2/dashboards')
            }

            if (response.has_no_enough_input_data) {
              return handleMovePage('/v2/dashboards')
            }

            handleMovePage(!_isUndefined(redirectUrl) ? redirectUrl : '/v2/dashboards')
          },
          resolve,
        ),
      ),
    )
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <LogindRedirect>
        <MarginContainer className="w-full sm:w-400 mx-auto pt-40 sm:pt-73">
          <Paper className="mx-8 sm:mx-0 px-16 py-24 sm:p-40 bg-white">
            <Heading className="text-center">ログイン</Heading>
            <div className="mt-24">
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, values, errors, submitErrors }) => (
                  <form className="space-y-24" onSubmit={handleSubmit}>
                    <FieldBlock label="メールアドレス">
                      <TextField
                        type="text"
                        name="mail_address"
                        autoComplete="off"
                        placeholder="sample@example.com"
                        isFullWidth
                        validate={composeValidators(required, mailAddress)}
                      />
                    </FieldBlock>
                    <FieldBlock label="パスワード">
                      <TextField
                        type="password"
                        name="password"
                        autoComplete="off"
                        isFullWidth
                        validate={composeValidators(required, password)}
                      />
                    </FieldBlock>

                    <WholeSubmitErrorMessage submitErrors={submitErrors} />

                    <div className="flex justify-center">
                      <ButtonPrimary
                        className="h-64 w-full md:w-188"
                        isDisabled={Object.keys(errors).length > 0 || isLoading}
                        onClick={() => handleSubmit(values)}
                      >
                        <Typography
                          className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                          isBold
                        >
                          ログインする
                        </Typography>
                        <ChevronRightIcon className="mr-17" />
                      </ButtonPrimary>
                    </div>
                  </form>
                )}
              />
            </div>
          </Paper>

          <div className="flex flex-col mt-25 space-y-10 text-center">
            <Link
              className="text-primary-900"
              children="アカウントをお持ちでない方はこちら"
              onClick={() => handleMovePage('/v2/accounts/new')}
            />
            <Link
              className="text-primary-900"
              children="パスワードをお忘れの方はこちら"
              onClick={() => handleMovePage('/v2/accounts/password/forgot')}
            />
            <Link
              className="text-primary-900"
              children="過去に退会された方はこちら"
              onClick={() => handleMovePage('/v2/accounts/activate')}
            />
            <Link
              className="text-primary-900"
              children="仮登録完了後にメールが届かなかった方はこちら"
              onClick={() => handleMovePage('/v2/accounts/password/mailHelp')}
            />
            <Link
              className="text-primary-900"
              children="本登録の有効期限が切れてしまった方はこちら"
              onClick={() => handleMovePage('/v2/accounts/password/expired')}
            />
          </div>
        </MarginContainer>
      </LogindRedirect>
    </>
  )
}

export default Login
