import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Form } from 'react-final-form'
import clsx from 'clsx'
import { cloneDeep as _cloneDeep, isNull as _isNull } from 'lodash'
import { usePostalJp } from 'use-postal-jp'
import Heading from '@/components/v2/atoms/Heading'
import Typography from '@/components/v2/atoms/Typography'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import TextField from '@/components/v2/atoms/TextField'
import Link from '@/components/v2/atoms/Link'
import SelectBox from '@/components/v2/molecules/SelectBox'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import CheckboxGroup from '@/components/v2/molecules/CheckboxGroup'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import {
  PREFECTURE_SELECT_ITEMS,
  NEW_SEX_RADIO_ITEMS,
} from '@/constants/formItem/commons'
import { AccountCommonValues } from '@/models/accountsModel'
import { API_BOOLEAN_FLAG } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import {
  composeValidators,
  required,
  requiredForArray,
  mailAddress,
  kana,
  number,
  specificNumberLength,
  twoSpecificNumbersLength,
} from '@/utils/validate'
import {
  getStorageRegistrationCode,
  removeStorageRegistrationCode,
} from '@/utils/localStorage/registrationCodeStorage'

interface AccountFormProps {
  /** タイトル */
  title: string
  /** ボタンラベル */
  buttonLabel: string
  /** 画面タイプ */
  type: 'new' | 'confirm' | 'edit'
  /** onSubmitイベントメソッド */
  handleSubmit: (values: AccountCommonValues) => void
  /** 修正ボタンメソッド */
  handleFix?: () => void
}

const AccountForm: FC<AccountFormProps> = (props) => {
  const location = useLocation()

  const { title, buttonLabel, type, handleSubmit, handleFix } = props
  const { account, isLoading } = useSelector((state: RootState) => state.accounts)
  const { searchCompany } = useSelector((state: RootState) => state.companies)

  const isConfirm = type === 'confirm'
  const isEdit = type === 'edit'

  const [inputValue, setInputValue] = useState('')
  const [hasRegistrationCode, setHasRegistrationCode] = useState<boolean>(false)
  const { address, setPostalCode } = usePostalJp()

  let formChange: (name: string, address: string | number | undefined) => void

  useEffect(() => {
    const registrationCode = getStorageRegistrationCode()
    setHasRegistrationCode(!_isNull(registrationCode))
  }, [location.search])

  useEffect(() => {
    if (inputValue) {
      setPostalCode(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    if (formChange && address.prefectureCode) {
      formChange('prefectures', Number(address.prefectureCode))
      formChange('address1', `${address.address1}${address.address2}`)
    }
  }, [address])

  const handleDeleteRegistrationCode = () => {
    removeStorageRegistrationCode()
    setHasRegistrationCode(false)
  }

  const handleMoveTermsPage = () => {
    window.open('/v2/terms')
  }

  const handleMovePolicyPage = () => {
    window.open('/v2/policy')
  }

  const handleCommonSubmit = (values: any) => {
    const newValues = _cloneDeep(values)
    newValues.sex = Number(newValues.sex)

    return handleSubmit(newValues)
  }

  const handleChangePostalCode = (value: string) => {
    setInputValue(value)
  }

  return (
    <div className="space-y-30 text-black-800">
      <Heading className="text-center">{title}</Heading>
      <Typography className="mt-24 sm:text-center text-14 text-black-700">
        ユーザー仮登録を行います。
        <br />
        仮登録完了後、ご登録いただいたメールアドレスに本登録用のURLをお送りいたします。
        <br />
        <span className="text-black-900 font-extrabold">３日以内</span> に本登録用のURLからパスワードを設定して、本登録を完了してください。
      </Typography>
      <Form
        onSubmit={handleCommonSubmit}
        initialValues={{
          ...account,
          company_name: searchCompany?.company_name,
        }}
        render={({ handleSubmit, values, errors, submitErrors, form: { change } }) => {
          formChange = change
          return (
            <form className="space-y-24" onSubmit={handleSubmit}>
              {!isEdit && hasRegistrationCode && (
                // <FieldBlock label="所属企業名">
                <FieldBlock label="">
                  <div className="flex flex-col items-end">
                    <TextField type="text" name="company_name" disabled isFullWidth />
                    {/* <Link
                      className={clsx('mt-5', {
                        'text-primary-900': !isConfirm,
                        'text-black-400 pointer-events-none': isConfirm,
                      })}
                      children="この会社に属していない場合はこちら"
                      onClick={handleDeleteRegistrationCode}
                    /> */}
                  </div>
                </FieldBlock>
              )}
              <FieldBlock label="メールアドレス" required>
                <TextField
                  type="text"
                  name="mail_address"
                  disabled={isConfirm}
                  isFullWidth
                  validate={composeValidators(required, mailAddress)}
                />
              </FieldBlock>
              <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-24">
                <FieldBlock label="お名前(姓)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="name_sei"
                    disabled={isConfirm}
                    isFullWidth
                    validate={required}
                  />
                </FieldBlock>
                <FieldBlock label="お名前(名)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="name_mei"
                    disabled={isConfirm}
                    isFullWidth
                    validate={required}
                  />
                </FieldBlock>
              </div>
              <div className="flex flex-col md:flex-row space-y-24 md:space-y-0 md:space-x-24">
                <FieldBlock label="ふりがな(せい)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="name_sei_kana"
                    disabled={isConfirm}
                    isFullWidth
                    validate={composeValidators(required, kana)}
                  />
                </FieldBlock>
                <FieldBlock label="ふりがな(めい)" className="flex-1" required>
                  <TextField
                    type="text"
                    name="name_mei_kana"
                    disabled={isConfirm}
                    isFullWidth
                    validate={composeValidators(required, kana)}
                  />
                </FieldBlock>
              </div>

              <FieldBlock label="性別" required>
                <RadioGroup
                  name="sex"
                  isDispRow
                  disabled={isConfirm}
                  validate={required}
                  datas={NEW_SEX_RADIO_ITEMS}
                  onChange={(event) => change('sex', Number(event.target.value))}
                />
              </FieldBlock>

              {isEdit && (
                <FieldBlock label="電話番号(ハイフンなし)">
                  <TextField
                    type="text"
                    name="tel_number"
                    disabled={isConfirm}
                    isFullWidth
                    validate={composeValidators(number, (value: any) =>
                      twoSpecificNumbersLength(value, 10, 11),
                    )}
                  />
                </FieldBlock>
              )}

              <FieldBlock label="郵便番号(ハイフンなし)">
                <TextField
                  type="text"
                  name="postal_code"
                  disabled={isConfirm}
                  isFullWidth
                  validate={composeValidators(number, (value: any) =>
                    specificNumberLength(value, 7),
                  )}
                  onChange={(e) => {
                    handleChangePostalCode(e.target.value)
                  }}
                />
              </FieldBlock>

              {isEdit && (
                <>
                  <FieldBlock label="都道府県">
                    <SelectBox
                      name="prefectures"
                      isDisabled={isConfirm}
                      options={PREFECTURE_SELECT_ITEMS}
                      isFullWidth
                    />
                  </FieldBlock>

                  <FieldBlock label="市区町村">
                    <TextField
                      type="text"
                      name="address1"
                      placeholder="××区××市××町"
                      disabled={isConfirm}
                      isFullWidth
                    />
                  </FieldBlock>

                  <FieldBlock label="番地、建物名">
                    <TextField
                      type="text"
                      name="address2"
                      placeholder="1丁目1-1 ××マンション×××号室"
                      disabled={isConfirm}
                      isFullWidth
                    />
                  </FieldBlock>
                </>
              )}

              {type !== 'edit' && (
                <FieldBlock label="利用規約への同意" required>
                  <CheckboxGroup
                    name="is_agree_policy"
                    disabled={isConfirm}
                    datas={[
                      {
                        label: (
                          <Typography>
                            私は、マネソルの
                            <Link
                              className={clsx({
                                'text-primary-900': !isConfirm,
                                'text-black-400 pointer-events-none': isConfirm,
                              })}
                              children="利用規約"
                              onClick={handleMoveTermsPage}
                            />
                            と
                            <Link
                              className={clsx({
                                'text-primary-900': !isConfirm,
                                'text-black-400 pointer-events-none': isConfirm,
                              })}
                              children="プライバシーポリシー"
                              onClick={handleMovePolicyPage}
                            />
                            に同意します。
                          </Typography>
                        ),
                        value: API_BOOLEAN_FLAG.on,
                      },
                    ]}
                    validate={requiredForArray}
                  />
                </FieldBlock>
              )}

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <div className="flex flex-col items-center space-y-24">
                <ButtonPrimary
                  className="h-45 w-full md:w-215 text-white"
                  isDisabled={Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSubmit(values)}
                >
                  <Typography
                    className="bottom-2 flex-auto pl-30 relative text-16"
                    isBold
                  >
                    {buttonLabel}
                  </Typography>
                  <i className="ico chevron-right mr-17 text-24" />
                </ButtonPrimary>
                {isConfirm && (
                  <div className="text-center">
                    <Link
                      className={clsx('text-14 text-primary-900', {
                        'text-black-400 pointer-events-none': isLoading,
                      })}
                      children="修正する"
                      onClick={handleFix}
                    />
                  </div>
                )}
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

export default AccountForm
