import React, { FC, useMemo, useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { includes as _includes, filter as _filter } from 'lodash'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from './_simulationNewButton'
import FutureLoanAdvanceModalForm from '@/templates/v2/simulations/_futureLoanAdvanceModalForm'
import { find as _find } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { FutureLoanAdvance } from '@/models/futures/futureLoanAdvancesModel'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import {
  convertAdvanceMode,
  convertRepaymentMode,
} from '@/models/futures/futureLoanAdvancesModelFunc'
import {
  THIS_YEAR_NUMBER,
  convertLocaleString,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { SelectItem } from '@/components/v1/molecules/SelectBox'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { destroyFutureLoanAdvanceV2 } from '@/containers/futures/futureLoanAdvancesSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureLoanAdvanceFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface LoanAdvanceTableProps {
  /** アイテム */
  item: any
  /** ラベル */
  selectLoanItemLabel?: any
  /** td要素のクラス */
  tdClassName?: string
}

export const LoanAdvanceTable: FC<LoanAdvanceTableProps> = ({ item }) => {
  const { applicableLoans } = useSelector((state: RootState) => state.futureBases)
  const loanSelectItems: SelectItem[] = useMemo(() => {
    return applicableLoans.map((option) => ({
      value: `${option.loan_type}-${option.loan_id}-${option.loan_start_year}-${option.loan_end_year}`,
      label: option.display_name,
    }))
  }, [applicableLoans])

  const selectLoanItemLabel = (loanId: number | null) => {
    const label =
      _find(loanSelectItems, (item) => Number(item.value?.split('-')[1]) === loanId)
        ?.label || ''

    if (label.length > 25) {
      const splitedLabel = label?.split(' ')
      return (
        <>
          <Typography>{splitedLabel[0]}</Typography>
          <Typography>{splitedLabel[1]}</Typography>
          <Typography>
            {splitedLabel[2]} {splitedLabel[3]}
          </Typography>
        </>
      )
    }
    return label
  }

  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold className="min-w-[max-content]">
              返済対象のローン
            </Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography className="py-[10px]">
              {(selectLoanItemLabel && selectLoanItemLabel(item?.loan_id)) || '未設定'}
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>返済方法</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{convertRepaymentMode(item.repayment_mode)}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold className="min-w-[max-content]">
              繰上げ返済の頻度
            </Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>{convertAdvanceMode(item.advance_mode)}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>予定年月</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>
              {extractYearOrMonthFromDate(item.repayment_date, 'year')}年
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px] md:px-[30px]">
            <Typography isBold>繰上げ返済額</Typography>
          </td>
          <td className="px-[16px] md:px-[30px]">
            <Typography>
              {convertLocaleString(item.repayment_amount_manyen)}万円
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const FutureLoanAdvanceForm: FC<FutureLoanAdvanceFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [id, setId] = useState<number | null>(null)

  const futureLoanAdvances = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_loan_advances,
  )

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    _filter(futureLoanAdvances, { future_base_id: props.futureBaseId }).forEach((item) =>
      handleSelect(item),
    )
  }, [props.shouldInitialize])

  const handleDestroyV2 = (item: any) => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    const destroyFunc = new Promise((resolve) => {
      dispatch(
        destroyFutureLoanAdvanceV2(item.id, () => {
          resolve('')
          showFlashMsg('削除しました')
        }),
      )
    })

    Promise.all([destroyFunc]).then(() => {
      if (_includes(selectedSimulationId, item.id)) handleSelect(item)
      dispatch(fetchAccountFutureItems(props.futureBaseId))
    })
  }

  const handleEdit = (item: any) => {
    setId(item.id)
    setIsEdit(true)
    setIsOpenModal(true)
  }

  const sortedFutureLoanAdvances = useMemo(() => {
    if (props.futureBaseId == null || futureLoanAdvances == null)
      return futureLoanAdvances
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureLoanAdvances.filter((item) => eqFbId(item)),
      ...futureLoanAdvances.filter((item) => !eqFbId(item)),
    ]
  }, [futureLoanAdvances])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureLoanAdvances == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureLoanAdvances.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  const { applicableLoans } = useSelector((state: RootState) => state.futureBases)

  const loanSelectItems: SelectItem[] = useMemo(() => {
    return applicableLoans.map((option) => ({
      value: `${option.loan_type}-${option.loan_id}-${option.loan_start_year}-${option.loan_end_year}`,
      label: option.display_name,
    }))
  }, [applicableLoans])

  const selectLoanItemLabel = (loanId: number | null) => {
    const label =
      _find(loanSelectItems, (item) => Number(item.value?.split('-')[1]) === loanId)
        ?.label || ''

    if (label.length > 25) {
      const splitedLabel = label?.split(' ')
      return (
        <>
          <Typography>{splitedLabel[0]}</Typography>
          <Typography>{splitedLabel[1]}</Typography>
          <Typography>
            {splitedLabel[2]} {splitedLabel[3]}
          </Typography>
        </>
      )
    }
    return label
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="繰上げ返済予定を設定"
      isSelected={selectedSimulationId != null && selectedSimulationId.length !== 0}
      selectedCount={selectedSimulationId?.length}
      itemCount={sortedFutureLoanAdvances?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureLoanAdvances?.map((item: FutureLoanAdvance, index: number) => {
          return (
            <SimulationItemAccordion
              key={`future_loan_advance_${item.id}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中の繰上げ返済予定'
                  : `繰上げ返済予定${titleIndex(index)}`
              }
              isSelected={_includes(selectedSimulationId, item.id)}
              inputType="checkbox"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
              isDisabled={!selectLoanItemLabel(item?.loan_id)}
              errorMessage={!selectLoanItemLabel(item?.loan_id) ? '返済対象のローンが設定されていないため、反映できません。' : null}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              <LoanAdvanceTable item={item} />
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureLoanAdvanceModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={id}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureLoanAdvanceForm