import React, { FC } from 'react'
import { Form } from 'react-final-form'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import FieldBlock from '@/components/v2/molecules/FieldBlock'
import TextField from '@/components/v2/atoms/TextField'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'
import { CARD_EXP_YEAR_SELECT_ITEMS } from '@/constants/formItem/accounts'
import { MONTH_SELECT_ITEMS } from '@/constants/formItem/commons'
import { composeValidators, minLength, required } from '@/utils/validate'
import { AccountPayment } from '@/models/accountsModel'
import { THIS_YEAR } from '@/models/commonsModelFunc'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import CircleTitle from '@/components/v2/molecules/CircleTitle'

const CreditCardRegistrationForm: FC<{
  title: string
  accountPayment: AccountPayment | null
  isEdit?: boolean
  isLoading: boolean
  handleSubmit: (values: any) => void
  instruction_type?: 'fp_remote'
}> = (props: any) => {
  const { title, accountPayment, isLoading, handleSubmit, instruction_type } = props

  const headYearString = THIS_YEAR.substr(0, 2)

  return (
    <div className="space-y-24">
      <CircleTitle title={title} />
      <Form
        initialValues={{
          _plan: 'paid',
        }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, values, errors, submitErrors }) => (
          <form className="space-y-24 pb-[30px]" onSubmit={handleSubmit}>
            {instruction_type == 'fp_remote' ? (
              <>
                <Typography className="text-12" isBold>
                  FPに相談するには有料プランへの登録が必要です。
                </Typography>
                <div className="bg-secondary-50 p-10">
                  <Typography className="text-secondary-500 text-12 pb-5" isBold>
                    FPに相談すると…
                  </Typography>

                  <Typography className="text-12">
                    <span className="text-secondary-500">・</span>
                    深い話はリモート相談、軽く聞きたいことはチャットでもできる！
                  </Typography>
                  <Typography className="text-12">
                    <span className="text-secondary-500">・</span>
                    経験豊富な資格保有者のFPが伴走します！
                  </Typography>
                  <Typography className="text-12">
                    <span className="text-secondary-500">・</span>
                    資産運用の基本から実践的な方法まで教えてくれる！
                  </Typography>
                  <Typography className="text-12">
                    <span className="text-secondary-500">・</span>
                    保険の正しい入り方を教えてくれるから、老後不安が大幅に解消される！
                  </Typography>
                </div>
              </>
            ) : (
              <div className="bg-secondary-50 p-10">
                <Typography className="text-secondary-500 text-12 pb-5" isBold>
                  マネソルを継続利用すると…！
                </Typography>

                <Typography className="text-12">
                  <span className="text-secondary-500">・</span>
                  FPと相談して、お金のことで困った時いつでもアドバイスをもらえる！
                </Typography>
                <Typography className="text-12">
                  <span className="text-secondary-500">・</span>
                  やるべきリストをもとに正しく安心なライフプランを作れるようになる！
                </Typography>
                <Typography className="text-12">
                  <span className="text-secondary-500">・</span>
                  いつでも自分の資産、不動産、保険の状況が一目でわかるから不動産を買いたい、売りたい、貸したい時にプロに相談できる！
                </Typography>
              </div>
            )}

            <input id="tkn" name="tkn" type="hidden" value="" />
            <div id="EMV3DS_INPUT_FORM"></div>

            <RadioGroup
              name="_plan"
              datas={[
                {
                  label: `有料プラン${
                    accountPayment?.monthly_fee
                      ? `（月額 ${accountPayment?.monthly_fee}円）`
                      : ''
                  }`,
                  value: 'paid',
                },
              ]}
              validate={required}
            />
            <FieldBlock label="カード番号">
              <TextField
                type="text"
                name="cn"
                placeholder="1111 2222 3333 4444"
                isFullWidth
                maxLength={16}
                validate={composeValidators(required, (value: any) =>
                  minLength(value, 15),
                )}
              />
            </FieldBlock>
            <FieldBlock label="カード有効期限">
              <div className="flex items-center space-x-10">
                <SelectBox
                  widthClassName="w-98"
                  name="ed_month"
                  options={MONTH_SELECT_ITEMS}
                  validate={required}
                />
                <Typography>月</Typography>
                <Typography>/</Typography>
                <Typography>{headYearString}</Typography>
                <SelectBox
                  widthClassName="w-98"
                  name="ed_year"
                  options={CARD_EXP_YEAR_SELECT_ITEMS}
                  validate={required}
                />
                <Typography>年</Typography>
              </div>
            </FieldBlock>
            <FieldBlock label="カード名義（名）">
              <TextField
                type="text"
                name="fn"
                placeholder="TARO"
                isFullWidth
                validate={required}
              />
            </FieldBlock>
            <FieldBlock label="カード名義（姓）">
              <TextField
                type="text"
                name="ln"
                placeholder="YAMADA"
                isFullWidth
                validate={required}
              />
            </FieldBlock>
            <FieldBlock label="セキュリティコード">
              <TextField
                type="text"
                name="cvv"
                placeholder="123"
                isFullWidth
                validate={required}
              />
            </FieldBlock>

            <WholeSubmitErrorMessage submitErrors={submitErrors} />

            <div className="flex flex-col items-center space-y-24">
              <ButtonPrimary
                className="h-45 w-full md:w-215 text-white"
                isDisabled={Object.keys(errors).length > 0 || isLoading}
                onClick={() => handleSubmit(values)}
              >
                <Typography className="bottom-2 flex-auto pl-30 relative text-16" isBold>
                  登録する
                </Typography>
                <i className="ico chevron-right mr-17 text-24" />
              </ButtonPrimary>
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default CreditCardRegistrationForm
