import React, { FC, useEffect, useMemo, useContext, useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { split as _split } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import Popover from '@/components/v1/atoms/Popover'
import { FieldArray } from 'react-final-form-arrays'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import TextField from '@/components/v2/atoms/TextField'
import Typography from '@/components/v2/atoms/Typography'
import NumberField from '@/components/v2/molecules/NumberField'
import SelectBox from '@/components/v2/molecules/SelectBox'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { PAYMENT_MODE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputCustomItems'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'
import {
  fetchInputRisk,
  createInputRisk,
  updateInputRisk,
} from '@/containers/inputs/inputRisksSlice'
import { fetchCompanyRisk } from '@/containers/companyRisksSlice'
import { PAYMENT_MODE } from '@/models/inputs/inputCustomItemsModelFunc'
import { InputRiskUpdateValues } from '@/models/inputs/inputRisksModel'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { castNumberWithoutOperator } from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import { required, composeValidators, zeroOrMoreNumber, maxLength } from '@/utils/validate'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { useParams } from 'react-router'
import { AccountContext } from '@/models/accountsModelFunc'
import WholeSubmitErrorMessage from '@/components/v2/molecules/WholeSubmitErrorMessage'

import { find as _find, each as _each, set as _set, first as _first } from 'lodash'
import LoadingOverlay from '@/components/v1/atoms/LoadingOverlay'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

// 科目
export const CATEGORY_SELECT_ITEMS = [
  { value: 'company_bereaved', label: '会社の遺族年金' },
  { value: 'company_bereaved_education', label: '遺児・育英年金' },
  { value: 'company_bereaved_onetime_education', label: 'その他の育英一時金収入' },
]

// 適用対象
export const PAYMENT_TARGET_SELECT_ITEMS = [
  { value: '0', label: '本人' },
  { value: '1', label: '配偶者' },
  { value: '2', label: '子供' },
  { value: '3', label: '第一子' },
  { value: '4', label: '第二子' },
  { value: '5', label: '第三子' },
  { value: '6', label: '第四子' },
  { value: '7', label: '第五子' },
]

const MyDataRiskEdit: FC<{
  handleCancel?: () => void
  submitCallback?: () => void
  riskId?: number
  target?: string
}> = ({ handleCancel, submitCallback, riskId, target, }) => {
  const dispatch = useDispatch()
  const { handleMovePage } = useCustomHistory()
  const { showFlashMsg } = useFlashAlert()
  const { isAdmin } = useContext(AccountContext)
  const { account_id } = useParams<{ account_id?: string }>()

  const { inputRisk, isLoading } = useSelector((state: RootState) => state.inputRisk)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popoverOpenKey, setPopoverOpenIndex] = useState<string | null>(null)

  const handleOpenPopover = (event: MouseEvent<HTMLElement>, key: string) => {
    setAnchorEl(event.currentTarget)
    setPopoverOpenIndex(key)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    setPopoverOpenIndex(null)
  }

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 万が一の保障 編集',
        description: 'マイデータ | 万が一の保障 編集',
        keyword: 'マイデータ | 万が一の保障 編集',
      }),
    )
    if (inputRisk.length == 0) dispatch(fetchInputRisk())
    dispatch(fetchCompanyRisk())
  }, [])

  let id = riskId || Number(useParams<{ id?: string }>().id)
  let targetPerson = target || String(useParams<{ target?: string }>().target)

  const isEdit = id >= 0 && id !== null

  const { companyRisk } = useSelector((state: RootState) => state.companyRiskForUser)

  const initialValues = useMemo(() => {
    const targetInputRisk = _find(inputRisk, { id })
    const targetCompanyRisk = _first(companyRisk)

    if (isEdit && targetInputRisk !== undefined) {
      // child_indexがある場合は、person_flgをセットしなおす
      const customItemList = []
      let customItems = targetInputRisk?.input_risk_custom_items

      let customItem: any

      for (customItem of customItems) {
        // customItemがreadonlyのため、別の配列に移す
        const newCustomItem: { [key: string]: any } = {}
        for (let key in customItem) {
          newCustomItem[key] = customItem[key]
        }

        if (newCustomItem.child_index !== null) {
          newCustomItem['person_flg'] = newCustomItem['child_index'] + 2
          newCustomItem['child_index'] = null
        }

        customItemList.push(newCustomItem)
      }

      return {
        input_risk: {
          person_flg: String(targetInputRisk?.person_flg),
          death_age: targetInputRisk?.death_age,
          dead_severance_pay_manyen: targetInputRisk?.dead_severance_pay_manyen,
          condolence_pay_manyen: targetInputRisk?.condolence_pay_manyen,
          incense_pay_manyen: targetInputRisk?.incense_pay_manyen,
          living_cost_rate_percent: targetInputRisk?.living_cost_rate_percent,
          includes_financial_asset: targetInputRisk?.includes_financial_asset,
          funeral_cost_type: targetInputRisk?.funeral_cost_type,
          custom_funeral_cost_manyen: targetInputRisk?.custom_funeral_cost_manyen,
        },
        input_risk_custom_items_attributes: customItemList,
      }
    }

    if (targetCompanyRisk !== undefined) {
      return {
        input_risk: {
          dead_severance_pay_manyen: targetCompanyRisk?.dead_severance_pay_manyen ?? 0,
          condolence_pay_manyen: targetCompanyRisk?.condolence_pay_manyen ?? 0,
          incense_pay_manyen: targetCompanyRisk?.incense_pay_manyen ?? 0,
          living_cost_rate_percent: 70, // 生活費水準 70
          includes_financial_asset: '0', // 金融資産 含める
          funeral_cost_type: 'cremation', // 葬儀費用 火葬
        },
        input_risk_custom_items_attributes: [
          ...targetCompanyRisk?.company_risk_custom_items,
        ],
      }
    }

    return {
      input_risk: {
        living_cost_rate_percent: 70, // 生活費水準 70
        includes_financial_asset: '0', // 金融資産 含める
        funeral_cost_type: 'cremation', // 葬儀費用 火葬
      },
      input_risk_custom_items_attributes: [],
    }
  }, [companyRisk])

  const handleCreate = (values: any) => {
    for (let index in values.input_risk.input_risk_custom_items_attributes) {
      delete values.input_risk.input_risk_custom_items_attributes[index][
        'company_risk_id'
      ]
      delete values.input_risk.input_risk_custom_items_attributes[index]['amount']
      delete values.input_risk.input_risk_custom_items_attributes[index]['id']
    }

    const newValues: any = {
      account: { input_risks_attributes: [{ ...values.input_risk }] },
    }
    return new Promise((resolve) =>
      dispatch(
        createInputRisk(newValues, () => {
          showFlashMsg('作成しました')
          submitCallback
            ? submitCallback()
            : (() => {
                dispatch(fetchInputRisk())
                dispatch(updateResetFlg())
                handleMovePage(
                  isAdmin ? `/v2/accounts/${account_id}/myData/risk` : '/v2/myData/risk',
                )
              })()
        }),
      ),
    )
  }

  const handleUpdate = (values: InputRiskUpdateValues) => {
    const newValues: any = { input_risk: { ...values.input_risk } }

    dispatch(
      updateInputRisk(newValues, id, () => {
        showFlashMsg('更新しました')
        submitCallback
          ? submitCallback()
          : (() => {
              dispatch(fetchInputRisk())
              dispatch(updateResetFlg())
              handleMovePage(
                isAdmin ? `/v2/accounts/${account_id}/myData/risk` : '/v2/myData/risk',
              )
            })()
      }),
    )
  }

  const handleSubmit = (values: any) => {
    let newValues = { ...values }
    if (newValues.input_risk.funeral_cost_type !== 'custom') {
      newValues.input_risk.custom_funeral_cost_manyen =
        funeralCost[newValues.input_risk.funeral_cost_type] ?? 0
    }

    const customItemList = []
    if(!!newValues.input_risk_custom_items_attributes) {
      for (let customItem of newValues.input_risk_custom_items_attributes) {
        if (customItem.person_flg >= 3) {
          customItem.child_index = customItem.person_flg - 2
          customItem.person_flg = 2
        } else {
          customItem.child_index = null
        }
        customItemList.push(customItem)
      }
    }

    newValues.input_risk.input_risk_custom_items_attributes = customItemList
    delete newValues.input_risk_custom_items_attributes

    newValues.input_risk.person_flg = targetPerson === 'spouse' ? '1' : '0'
    return isEdit ? handleUpdate(newValues) : handleCreate(newValues)
  }

  // 葬儀費用
  const funeralCost: { [key: string]: number } = {
    cremation: 70,
    domestic: 140,
    average: 200,
    plenty: 300,
  }

  // 生活費水準 0~100
  const livingCostSelectItems = useMemo(() => {
    let select_items = []

    for (let i = 0; i < 11; i++) {
      select_items.push({ label: String(i * 10), value: i * 10 })
    }

    return select_items
  }, [])

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        validate={(values) => {
          const validateMessage = {}

          const validatePaymentAge = (key: string) => {
            _each(values[key], (item, index) => {
              if (item.payment_mode == PAYMENT_MODE.repeatedly) {
                if (
                  castNumberWithoutOperator(item.payment_start_age) >
                  castNumberWithoutOperator(item.payment_end_age)
                ) {
                  _set(
                    validateMessage,
                    `${key}[${index}]payment_end_age`,
                    '開始年齢より後の年齢を入力してください',
                  )
                }
              }
            })
          }

          validatePaymentAge('input_risk_custom_items_attributes')

          return validateMessage
        }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            mutators: { push },
          },
        }) => {
          const { funeral_cost_type } = values.input_risk as any
          const getId = () => {
            let tmpIdArr = values.input_risk_custom_items_attributes.map(
              (v: { tmpId: number; [key: string]: any }) => Number(v.tmpId || null),
            )
            tmpIdArr.push(0)
            return Math.max(...tmpIdArr) + 1
          }

          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <Grid className="pb-[40px] grid-cols-1 md:grid-cols-1 gap-x-[40px] gap-y-[16px]">
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      想定するケース
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      {targetPerson === 'spouse'
                        ? '配偶者が亡くなった場合'
                        : 'ご本人が亡くなった場合'}
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      死亡想定年齢
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField
                      name="input_risk.death_age"
                      isFullWidth
                    />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      歳
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  会社から貰える死亡保障について
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="flex min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      死亡退職金
                      <div className="flex items-center mx-[10px]">
                        <Tooltip
                          item="死亡退職金は、就業規則の退職金規定に記載があります。"
                          itemClassName="left-[-26px] w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                        >
                          <HelpCircleIcon />
                        </Tooltip>
                      </div>
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField
                      name="input_risk.dead_severance_pay_manyen"
                      isFullWidth
                    />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      弔慰金
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField name="input_risk.condolence_pay_manyen" isFullWidth />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      香典
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField name="input_risk.incense_pay_manyen" isFullWidth />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  会社からもらえるその他の保障について
                </Typography>

                <table className="lg:table w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300 w-full hidden lg:table-row">
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          項目名
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          科目
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          適用頻度
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          金額
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[10px]">
                        <Typography className="table mx-auto"></Typography>
                      </td>
                    </tr>

                    <FieldArray name="input_risk_custom_items_attributes">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          const targetAttributes =
                            values['input_risk_custom_items_attributes'][index]
                          const paymentMode = targetAttributes['payment_mode']

                          const isOnce = paymentMode == PAYMENT_MODE.once
                          const isRepeatedlyPaymentMode =
                            paymentMode == PAYMENT_MODE.repeatedly

                          return (
                            <React.Fragment
                              key={`${targetAttributes.tmpId}_income_${targetAttributes.id}`}
                            >
                              {fields.value[index] && (
                                <>
                                  <tr className="border-b border-black-300">
                                    {/* 項目名 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell mt-[30px] lg:mt-[0px]">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        項目名
                                      </Typography>
                                      <TextField
                                        widthClassName="w-full lg:w-[110px]"
                                        type="text"
                                        name={`${name}name`}
                                        placeholder="例）長男の入学、第二子の中学入学など"
                                      />
                                    </td>

                                    {/* 科目 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        科目
                                      </Typography>
                                      <SelectBox
                                        widthClassName="w-full lg:w-[110px]"
                                        name={`${name}category`}
                                        placeholder="科目を選択"
                                        options={CATEGORY_SELECT_ITEMS}
                                      />
                                    </td>

                                    {/* 適用頻度 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        適用頻度
                                      </Typography>
                                      <div className="lg:flex items-center w-full">
                                        <SelectBox
                                          widthClassName="w-full lg:w-[110px]"
                                          name={`${name}payment_mode`}
                                          placeholder="頻度を選択"
                                          options={PAYMENT_MODE_SELECT_ITEMS}
                                        />
                                        <div className="pl-[15px]">
                                          <div className="md:flex items-baseline mt-[16px] lg:mt-0">
                                            <SelectBox
                                              widthClassName="w-full lg:w-[110px]"
                                              name={`${name}person_flg`}
                                              placeholder="対象を選択"
                                              options={PAYMENT_TARGET_SELECT_ITEMS}
                                            />
                                            <Typography className="mx-[10px] min-w-[max-content] text-black-800">
                                              が
                                            </Typography>
                                            <div className="flex items-center">
                                              <NumberField
                                                widthClassName="lg:w-[48px] min-w-[40px]"
                                                name={`${name}payment_start_age`}
                                                disableValidationMessage
                                                validate={
                                                  !isOnce
                                                    ? composeValidators(
                                                        zeroOrMoreNumber,
                                                        (value: any) => maxLength(value, 3),
                                                      )
                                                    : undefined
                                                }
                                              />
                                              <Typography className="ml-[10px] min-w-[max-content] text-black-800">
                                                {isRepeatedlyPaymentMode
                                                  ? '歳から'
                                                  : '歳のとき'}
                                              </Typography>
                                            </div>
                                          </div>
                                          {/* カスタムバリデーションメッセージ */}
                                          <ValidMessage
                                            meta={{
                                              touched: true,
                                              error:
                                                errors
                                                  .input_risk_custom_items_attributes?.[
                                                  index
                                                ]?.['payment_start_age'],
                                            }}
                                          />
                                          {isRepeatedlyPaymentMode && (
                                            <>
                                              <div className="flex items-center mt-[10px] justify-end md:left-[34px] left-0 ">
                                                <NumberField
                                                  widthClassName="w-full lg:w-[48px]"
                                                  name={`${name}payment_end_age`}
                                                  disableValidationMessage
                                                  validate={
                                                    !isOnce
                                                      ? composeValidators(
                                                          zeroOrMoreNumber,
                                                          (value: any) => maxLength(value, 3),
                                                        )
                                                      : undefined
                                                  }
                                                />
                                                <Typography className="min-w-[max-content] text-black-800 ml-[10px]">
                                                  歳まで
                                                </Typography>
                                              </div>
                                              {/* カスタムバリデーションメッセージ */}
                                              <ValidMessage
                                                meta={{
                                                  touched: true,
                                                  error:
                                                    errors
                                                      .input_risk_custom_items_attributes?.[
                                                      index
                                                    ]?.['payment_end_age'],
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    {/* 金額 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        金額
                                      </Typography>
                                      <div className="flex flex-auto items-center">
                                        <NumberField
                                          name={`${name}amount_manyen`}
                                          widthClassName="w-full lg:w-[92px]"
                                          defaultValue={
                                            fields.value[index]?.amount_manyen
                                          }
                                        />
                                        <Typography className="ml-[10px] w-[43px] text-black-800 min-w-[fit-content]">
                                          {isRepeatedlyPaymentMode ? '万円/年' : '万円'}
                                        </Typography>
                                      </div>
                                    </td>

                                    {/* 閉じるボタン */}
                                    <td className="align-baseline lg:align-middle">
                                      <div
                                        className="cursor-pointer"
                                        onClick={(event) =>
                                          handleOpenPopover(event, `income-${index}`)
                                        }
                                      >
                                        <MoreVertIcon />
                                      </div>
                                      <Popover
                                        open={popoverOpenKey === `income-${index}`}
                                        anchorEl={anchorEl}
                                        onClick={handleClosePopover}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }}
                                      >
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => {
                                            new Promise((resolve) => {
                                              fields.push({
                                                ...fields.value[index],
                                                id: null,
                                                tmpId: getId(),
                                              })
                                              resolve('')
                                            }).then(() =>
                                              fields.move(fields.value.length, index + 1),
                                            )
                                          }}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            複製する
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => fields.remove(index)}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            削除する
                                          </Typography>
                                        </div>
                                      </Popover>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          )
                        })
                      }}
                    </FieldArray>
                  </tbody>
                </table>

                <Button
                  className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => {
                    push('input_risk_custom_items_attributes', {
                      tmpId: getId(),
                    })
                  }}
                >
                  <Typography isBold>追加</Typography>
                </Button>

                <Typography className="flex md:text-[px] pb-[10px]" isBold>
                  生活費水準
                  <div className="flex items-center mx-[10px]">
                    <Tooltip
                      item="一般的には70%で設定します。。"
                      itemClassName="left-[-26px] w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                    >
                      <HelpCircleIcon />
                    </Tooltip>
                  </div>
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      現状の生活費の
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <SelectBox
                      name="input_risk.living_cost_rate_percent"
                      isFullWidth
                      options={livingCostSelectItems}
                      validate={required}
                    />
                  </div>
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      %に抑える
                    </Typography>
                  </div>
                </div>

                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  金融資産
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      必要保障額に
                      <br />
                      金融資産を
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <SelectBox
                      name="input_risk.includes_financial_asset"
                      isFullWidth
                      options={[
                        { value: '0', label: '含める' },
                        { value: '1', label: '含めない' },
                      ]}
                      validate={required}
                    />
                  </div>
                </div>

                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  ご葬儀について
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      想定する葬儀費用
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <SelectBox
                      name="input_risk.funeral_cost_type"
                      isFullWidth
                      options={[
                        { value: 'cremation', label: '火葬式(70万円)' },
                        { value: 'domestic', label: '家族葬(140万円)' },
                        { value: 'average', label: '人並み(200万円)' },
                        { value: 'plenty', label: 'とことん(300万円)' },
                        { value: 'custom', label: 'カスタム(手入力)' },
                      ]}
                      validate={required}
                    />
                  </div>

                  {funeral_cost_type === 'custom' && (
                    <div className="flex items-center my-[10px] justify-end md:pl-16">
                      <NumberFieldDecimal
                        name="input_risk.custom_funeral_cost_manyen"
                        isFullWidth
                      />
                      <Typography
                        className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>

              <WholeSubmitErrorMessage submitErrors={submitErrors} />
              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[246px] h-[64px] mx-auto mb-[16px] ',
                )}
                isDisabled={isLoading}
                type="submit"
              >
                <Typography
                  className="relative bottom-[2px] pl-[30px] text-[16px] text-white flex-auto"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-[17px]" />
              </ButtonPrimary>
              <Button
                className="mx-auto text-[14px] text-black-700"
                onClick={() =>
                  !!handleCancel
                    ? handleCancel()
                    : handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/risk`
                          : '/v2/myData/risk',
                      )
                }
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
            </form>
          )
        }}
      />
    </>
  )
}

export default MyDataRiskEdit
