import React, { FC, useMemo, ChangeEvent } from 'react'
import { Form } from 'react-final-form'
import { Decorator } from 'final-form'
import createDecorator from 'final-form-calculate'
import { find as _find, isNull as _isNull } from 'lodash'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Divider from '@/components/v2/atoms/Divider'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  MARRIAGE_TYPE_SELECT_ITEMS,
  MARRIAGE_COST_TYPE_SELECT_ITEMS,
} from '@/constants/formItem/futures/futureMarriages'
import {
  buildStandardSelectItems,
  buildTwoTypesRangeYearSelectItems,
  renderStartAtYearOptions,
} from '@/utils/formItem'

import NumberField from '@/components/v2/molecules/NumberField'

import { TWO_TYPES_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  THIS_YEAR_NUMBER,
  convertDateYear,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import {
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  required,
} from '@/utils/validate'
import {
  MARRIAGE_COST_TYPE,
  MARRIAGE_COST,
  MARRIAGE_SUM_FIELDS,
  sumMarriageCost,
} from '@/models/futures/futureMarriagesModelFunc'
import {
  FutureMarriage,
  FutureMarriageCreateValues,
} from '@/models/futures/futureMarriagesModel'
import {
  createFutureMarriageV2,
  updateFutureMarriageV2,
} from '@/containers/futures/futureMarriagesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'

const calculator: Decorator<any> = createDecorator({
  field: MARRIAGE_SUM_FIELDS,
  updates: {
    total_cost: (_, allValues: FutureMarriageCreateValues) =>
      sumMarriageCost(allValues.future_marriage),
  },
})

interface FutureMarriageModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean
  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureMarriageModalForm: FC<FutureMarriageModalFormProps> = ({
  isOpen,
  onClose,
  isEdit,
  id,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const futureMarriages = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_marriages,
  )

  const targetMarriage: FutureMarriage | undefined = useMemo(
    () => (!_isNull(id) ? _find(futureMarriages, { id }) : undefined),
    [futureMarriages, id],
  )

  const handleCreate = (values: any) => {
    const newValues: any = {
      future_marriage: { ...values.future_marriage },
    }

    const { future_marriage } = values

    newValues.future_marriage.get_married_at = convertDateYear(
      future_marriage.get_married_at_year,
    )
    newValues.future_marriage.partner_birth_day = convertDateYear(
      future_marriage.partner_birth_day_year,
    )

    return new Promise((resolve) =>
      dispatch(
        createFutureMarriageV2(
          {
            account: {
              future_marriages_attributes: [{ ...newValues.future_marriage }],
            },
          },
          () => {
            dispatch(fetchAccountFutureItems(futureBaseId))
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            onClose()
          },
          resolve,
        ),
      ),
    )
  }

  const handleUpdate = (values: any) => {
    const newValues = {
      future_marriage: {
        ...values.future_marriage,
        get_married_at: `${values.future_marriage.get_married_at_year}-01-01`,
        partner_birth_day: `${values.future_marriage.partner_birth_day_year}-01-01`,
      },
    }

    dispatch(
      updateFutureMarriageV2(newValues as any, id as any, () => {
        dispatch(fetchAccountFutureItems(futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const initialValues = useMemo(() => {
    return isEdit
      ? {
          future_marriage: {
            ...targetMarriage,
            get_married_at_year: extractYearOrMonthFromDate(
              targetMarriage?.get_married_at,
              'year',
            ),
            partner_birth_day_year: extractYearOrMonthFromDate(
              targetMarriage?.partner_birth_day,
              'year',
            ),
          },
        }
      : {
          total_cost:
            Number(MARRIAGE_COST.honeymoon_manyen[MARRIAGE_COST_TYPE.average]) +
            Number(MARRIAGE_COST.wedding_manyen[MARRIAGE_COST_TYPE.average]) +
            Number(MARRIAGE_COST.wedding_ring_manyen[MARRIAGE_COST_TYPE.average]) +
            Number(MARRIAGE_COST.engagement_ring_manyen[MARRIAGE_COST_TYPE.average]),
          future_marriage: {
            honeymoon_manyen: MARRIAGE_COST.honeymoon_manyen[MARRIAGE_COST_TYPE.average],
            wedding_manyen: MARRIAGE_COST.wedding_manyen[MARRIAGE_COST_TYPE.average],
            wedding_ring_manyen:
              MARRIAGE_COST.wedding_ring_manyen[MARRIAGE_COST_TYPE.average],
            engagement_ring_manyen:
              MARRIAGE_COST.engagement_ring_manyen[MARRIAGE_COST_TYPE.average],
          },
          honeymoon_manyen_type: MARRIAGE_COST_TYPE.average,
          wedding_manyen_type: MARRIAGE_COST_TYPE.average,
          wedding_ring_manyen_type: MARRIAGE_COST_TYPE.average,
          engagement_ring_manyen_type: MARRIAGE_COST_TYPE.average,
        }
  }, [targetMarriage])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="結婚について"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        decorators={[calculator]}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          const { future_marriage } = values
          const { marriage_type } = future_marriage || {}

          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <div>
                <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div>
                    <div className="md:flex pb-16 justify-between">
                      <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                        <Typography className="pr-16 text-14 text-black-800" isBold>
                          結婚のご予定はございますか？
                        </Typography>
                      </div>
                      <div className="justify-end">
                        <SelectBox
                          name="future_marriage.marriage_type"
                          placeholder="選択してください"
                          options={MARRIAGE_TYPE_SELECT_ITEMS}
                          isFullWidth
                          validate={required}
                        />
                      </div>
                    </div>
                    <Divider className="border-black-300 border-dashed border-t-2" />
                  </div>
                </Grid>

                {marriage_type == '0' && (
                  <>
                    <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              結婚のご予定
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              placeholder="年を選択"
                              name="future_marriage.get_married_at_year"
                              options={renderStartAtYearOptions(
                                future_marriage?.get_married_at_year,
                              )}
                              isFullWidth
                              validate={required}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              ごろ
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-[200px] pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              結婚相手の
                              <br className="hidden md:block" />
                              誕生年想定
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <SelectBox
                              isFullWidth
                              name="future_marriage.partner_birth_day_year"
                              isPlaceholderHidden
                              options={buildStandardSelectItems(
                                buildTwoTypesRangeYearSelectItems(
                                  THIS_YEAR_NUMBER - 80,
                                  THIS_YEAR_NUMBER - 18,
                                ),
                                '1990',
                                '年を選択',
                              )}
                              validate={required}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              生まれ
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-[200px] pb-10 md:pb-0 pr-16">
                            <Typography className="text-14 text-black-800" isBold>
                              結婚相手の <br className="hidden md:block" />
                              年収想定
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <Typography
                              className="pr-16 text-14 text-black-800 min-w-[55px]"
                              isBold
                            >
                              年収
                            </Typography>
                            <NumberField
                              isFullWidth
                              name="future_marriage.partner_annual_income_manyen"
                              validate={required}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[55px]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    </Grid>

                    <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                      {!isEdit && (
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0 pr-16">
                              <Typography className="text-14 text-black-800" isBold>
                                結婚費用の想定①:
                                <br className="hidden md:block" />
                                婚約指輪
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <SelectBox
                                isFullWidth
                                placeholder="選択する"
                                name="engagement_ring_manyen_type"
                                options={MARRIAGE_COST_TYPE_SELECT_ITEMS}
                                validate={required}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                  change(
                                    'future_marriage.engagement_ring_manyen',
                                    MARRIAGE_COST.engagement_ring_manyen[
                                      event.target.value
                                    ],
                                  )
                                }
                              />
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      )}

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              {`結婚費用の想定${!isEdit ? '②:' : ''}`}
                              <br className="hidden md:block" />
                              婚約指輪
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              widthClassName="w-full md:w-[120px]"
                              name="future_marriage.engagement_ring_manyen"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      {!isEdit && (
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                結婚費用の想定①:
                                <br className="hidden md:block" />
                                結婚指輪
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <SelectBox
                                isFullWidth
                                placeholder="選択する"
                                name="wedding_ring_manyen_type"
                                options={MARRIAGE_COST_TYPE_SELECT_ITEMS}
                                validate={required}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                  change(
                                    'future_marriage.wedding_ring_manyen',
                                    MARRIAGE_COST.wedding_ring_manyen[event.target.value],
                                  )
                                }
                              />
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      )}

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              {`結婚費用の想定${!isEdit ? '②:' : ''}`}
                              <br className="hidden md:block" />
                              結婚指輪
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              widthClassName="w-full md:w-[120px]"
                              name="future_marriage.wedding_ring_manyen"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      {!isEdit && (
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                結婚費用の想定①:
                                <br className="hidden md:block" />
                                挙式・披露宴
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <SelectBox
                                placeholder="選択する"
                                name="wedding_manyen_type"
                                isFullWidth
                                options={MARRIAGE_COST_TYPE_SELECT_ITEMS}
                                validate={required}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                  change(
                                    'future_marriage.wedding_manyen',
                                    MARRIAGE_COST.wedding_manyen[event.target.value],
                                  )
                                }
                              />
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      )}

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              {`結婚費用の想定${!isEdit ? '②:' : ''}`}
                              <br className="hidden md:block" />
                              挙式・披露宴
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              widthClassName="w-full md:w-[120px]"
                              name="future_marriage.wedding_manyen"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      {!isEdit && (
                        <div>
                          <div className="md:flex items-center pb-16 justify-between">
                            <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                              <Typography className="pr-16 text-14 text-black-800" isBold>
                                結婚費用の想定①:
                                <br className="hidden md:block" />
                                新婚旅行
                                <br />
                                （2人分）
                              </Typography>
                            </div>
                            <div className="flex items-center justify-end">
                              <SelectBox
                                placeholder="選択する"
                                name="honeymoon_manyen_type"
                                isFullWidth
                                options={MARRIAGE_COST_TYPE_SELECT_ITEMS}
                                validate={required}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                  change(
                                    'future_marriage.honeymoon_manyen',
                                    MARRIAGE_COST.honeymoon_manyen[event.target.value],
                                  )
                                }
                              />
                            </div>
                          </div>
                          <Divider className="border-black-300 border-dashed border-t-2" />
                        </div>
                      )}

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              {`結婚費用の想定${!isEdit ? '②:' : ''}`}
                              <br className="hidden md:block" />
                              新婚旅行
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              widthClassName="w-full md:w-[120px]"
                              name="future_marriage.honeymoon_manyen"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              ご祝儀
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              isFullWidth
                              name="future_marriage.congratulations_manyen"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              親からの援助
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              isFullWidth
                              name="future_marriage.support_from_parents_manyen"
                              validate={composeValidators(
                                required,
                                zeroOrMoreNumber,
                                (value: any) => commaNumberMaxLength(value, 10),
                              )}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>

                      <div>
                        <div className="md:flex items-center pb-16 justify-between">
                          <div className="flex items-center min-w-140 lg:min-w-164 pb-10 md:pb-0">
                            <Typography className="pr-16 text-14 text-black-800" isBold>
                              結婚関連費用合計
                            </Typography>
                          </div>
                          <div className="flex items-center justify-end">
                            <NumberField
                              widthClassName="w-full md:w-[120px]"
                              name="total_cost"
                              disabled={true}
                            />
                            <Typography
                              className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                              isBold
                            >
                              万円
                            </Typography>
                          </div>
                        </div>
                        <Divider className="border-black-300 border-dashed border-t-2" />
                      </div>
                    </Grid>
                  </>
                )}

                {marriage_type === '2' && <></>}
              </div>

              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureMarriageModalForm