import React, { FC, useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@/components/v2/atoms/Typography'
import { setPageMeta } from '@/containers/pageMetasSlice'
import {
  destroyInputInsurance,
  fetchInputInsurances,
} from '@/containers/inputs/inputInsurancesSlice'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import {
  INSURANCE_TYPE1,
  INSURANCE_TYPE2,
  PURPOSE,
  CONTRACTOR_TYPE,
  INSURED_TYPE,
  convertContractor,
  convertInsured,
  convertPeriodFinishAt,
  convertPeriod,
  convertCancellationRefundAmountManyen,
  convertCancellationRefundAt,
  convertMaturityMoney,
  convertPurpose,
  convertInsuranceFeePaymentOccurrenceInMonths,
  convertInsuranceType1,
  convertInsuranceType2,
  convertNonLifeInsuranceType1,
  convertNonLifeInsuranceFeeMonths,
} from '@/models/commonInsurancesModelFunc'
import {
  convertDateToTwoTypesYearsDateFormat,
  defaultToEmptyString,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Button from '@/components/v2/atoms/Button'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { useParams } from 'react-router-dom'
import { AccountContext } from '@/models/accountsModelFunc'
import { useAccountControl } from '@/hooks/useAccountControl'
import moment from 'moment'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const MyDataInsurance: FC = () => {
  const { showFlashMsg } = useFlashAlert()
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { account_id } = useParams<{ account_id?: string }>()

  const { handleMovePage } = useCustomHistory()
  const { inputInsurances, monthlyInsurancePayment } = useSelector(
    (state: RootState) => state.inputInsurances,
  )

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 保険',
        description: 'マイデータ | 保険',
        keyword: 'マイデータ | 保険',
      }),
    )
    if (inputInsurances.length == 0) dispatch(fetchInputInsurances())
  }, [])

  const inputInsuranceUpdatedAt = useMemo(() => {
    const unixTimes = inputInsurances.map((item) =>
      Number(moment(item?.updated_at).format('X')),
    )
    return unixTimes.length !== 0
      ? `(最終更新日: ${moment(String(Math.max(...unixTimes)), 'X').format(
          'YYYY/MM/DD',
        )})`
      : undefined
  }, [inputInsurances])

  return (
    <MyDataListWrapper
      title="保険"
      buttonTitle="新しく追加する"
      onClick={
        inputInsurances.length > 0
          ? () =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/insurances/new`
                  : '/v2/myData/insurances/new',
              )
          : undefined
      }
      updatedAt={inputInsuranceUpdatedAt}
    >
      {inputInsurances.length === 0 && (
        <div>
          <Typography
            className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
            isBold
          >
            保険が登録されていません。
            <br className="md:hidden" />
            下記のボタンから登録してみましょう。
          </Typography>
          <Button
            className="w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] mx-auto bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/insurances/new`
                  : '/v2/myData/insurances/new',
              )
            }
          >
            <Typography
              className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
              isBold
            >
              新しく追加する
            </Typography>
          </Button>
        </div>
      )}

      {inputInsurances.map((item: any, index: number) => {
        const handleDestroy = () => {
          const result = confirm('本当に削除してよろしいですか？')
          if (!result) return

          const destroyFunc = new Promise((resolve) => {
            const newValues = { params: { id: item!.id } }
            dispatch(destroyInputInsurance(newValues, () => resolve('')))
          })

          Promise.all([destroyFunc]).then(() => {
            dispatch(fetchInputInsurances())
            dispatch(updateResetFlg())
            showFlashMsg('削除しました')
          })
        }
        return (
          <React.Fragment key={item.id}>
            <div className="mt-30">
              <div className="sm:flex block justify-between">
                <CircleTitle
                  title={`保険${index + 1}`}
                  classNameTitle="text-[20px] md:text-[24px]"
                />
                <div className="flex justify-end my-[10px]">
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-[10px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() =>
                      handleMovePage(
                        isAdmin
                          ? `/v2/accounts/${account_id}/myData/insurances/edit/${item.id}`
                          : `/v2/myData/insurances/edit/${item.id}`,
                      )
                    }
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                      isBold
                    >
                      編集する
                    </Typography>
                  </Button>
                  <Button
                    className="w-[84px] md:w-[112px] h-[26px] md:h-[37px] mr-0 md:mr-[9px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => handleDestroy()}
                  >
                    <Typography
                      className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
                      isBold
                    >
                      削除する
                    </Typography>
                  </Button>
                </div>
              </div>

              <table
                key={item.id}
                className="w-[100%] mb-[60px] last:mb-0 text-[12px] md:text-[14px]"
              >
                <tbody>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="w-[30%] px-[16px] md:px-[30px]">
                      <Typography isBold>契約年月</Typography>
                    </td>
                    <td className="w-[70%] px-[16px] md:px-[30px]">
                      <Typography>
                        {convertDateToTwoTypesYearsDateFormat(
                          item?.contracted_at,
                          'yearMonthWithJapaneseYear',
                        )}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>保険会社名</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{defaultToEmptyString(item?.company_name)}</Typography>
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>保険種別</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      {item?.['is_life_insurance?'] ? (
                        <Typography>{`${convertInsuranceType1(
                          item?.insurance_type1,
                        )} ${convertInsuranceType2(item?.insurance_type2)}`}</Typography>
                      ) : (
                        <Typography>
                          {convertNonLifeInsuranceType1(item?.insurance_type1)}
                        </Typography>
                      )}
                    </td>
                  </tr>
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>利用目的</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>{convertPurpose(item?.purpose)}</Typography>
                    </td>
                  </tr>
                  {item?.['is_life_insurance?'] && (
                    <>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>契約者</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {convertContractor(
                              item?.contractor_type,
                              item?.contractor_name,
                            )}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>被保険者</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {convertInsured(item?.insured_type, item?.insured_name)}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>保険期間</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {item?.insured_type === INSURED_TYPE.otherPersonInsured
                              ? convertPeriodFinishAt(
                                  item?.insurance_period_type,
                                  item?.insurance_period_finish_at,
                                  item?.insured_type,
                                  item?.insured_name,
                                  'insured',
                                )
                              : convertPeriod(
                                  item?.insurance_period_type,
                                  item?.insurance_period_finish_at_age,
                                  item?.insured_type,
                                  item?.insured_name,
                                  'insured',
                                )}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>
                            保険料
                            <br className="block md:hidden" />
                            払い込み
                          </Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {item?.contractor_type === CONTRACTOR_TYPE.otherPersonContract
                              ? convertPeriodFinishAt(
                                  item?.insurance_fee_payment_period_type,
                                  item?.insurance_fee_payment_period_finish_at,
                                  item?.contractor_type,
                                  item?.contractor_name,
                                  'contractor',
                                )
                              : convertPeriod(
                                  item?.insurance_fee_payment_period_type,
                                  item?.insurance_fee_payment_period_finish_at_age,
                                  item?.contractor_type,
                                  item?.contractor_name,
                                  'contractor',
                                )}
                          </Typography>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className="h-[45px] odd:bg-black-100">
                    <td className="px-[16px] md:px-[30px]">
                      <Typography isBold>保険料</Typography>
                    </td>
                    <td className="px-[16px] md:px-[30px]">
                      <Typography>
                        {`${
                          item?.['is_life_insurance?']
                            ? convertInsuranceFeePaymentOccurrenceInMonths(
                                item?.insurance_fee_payment_occurrence_in_months,
                              )
                            : convertNonLifeInsuranceFeeMonths(
                                item?.insurance_fee_payment_occurrence_in_months,
                              )
                        } ${convertLocaleString(item?.insurance_fee)}円`}
                      </Typography>
                    </td>
                  </tr>
                  {item?.insurance_type1 == INSURANCE_TYPE1.life &&
                    item?.insurance_type2 != INSURANCE_TYPE2.incomeSecurity && (
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>
                            死亡保険
                            <br className="block md:hidden" />
                            金額
                          </Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {`${convertLocaleString(item?.insurance_amount_manyen)}万円`}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  {item.insurance_type1 === INSURANCE_TYPE1.life &&
                    item.insurance_type2 === INSURANCE_TYPE2.wholeLife &&
                    item.purpose === PURPOSE.savings && (
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>解約返戻金</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {item?.insured_type === INSURED_TYPE.otherPersonInsured
                              ? convertCancellationRefundAt(
                                  item?.cancellation_refund_at,
                                  item?.cancellation_refund_amount_manyen,
                                  item?.cancellation_refund_rate_percent,
                                  item?.cancellation_refund_amount_input_type,
                                )
                              : convertCancellationRefundAmountManyen(
                                  item?.cancellation_refund_at_age,
                                  item?.cancellation_refund_amount_manyen,
                                  item?.cancellation_refund_rate_percent,
                                  item?.cancellation_refund_amount_input_type,
                                  item?.contractor_type,
                                  item?.contractor_name,
                                )}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  {item.insurance_type1 === INSURANCE_TYPE1.life &&
                    item.insurance_type2 === INSURANCE_TYPE2.incomeSecurity && (
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>保険月額</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {`月額 ${item.insured_income_monthly_manyen}万円`}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  {item?.insurance_type1 === INSURANCE_TYPE1.life &&
                    item?.insurance_type2 === INSURANCE_TYPE2.endowment && (
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>満期金</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {convertMaturityMoney(
                              item.maturity_money_type,
                              item.maturity_money,
                            )}
                          </Typography>
                        </td>
                      </tr>
                    )}
                  {item?.insurance_type1 === INSURANCE_TYPE1.personalAnnuity && (
                    <>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>受取年齢</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {`${defaultToEmptyString(item?.receiving_pension_at_age)}歳`}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>受取額</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {`年間 ${convertLocaleString(
                              item.receiving_pension_amount,
                            )}円`}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>受取期間</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {`${defaultToEmptyString(item.receiving_years)}年`}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="h-[45px] odd:bg-black-100">
                        <td className="px-[16px] md:px-[30px]">
                          <Typography isBold>保証期間</Typography>
                        </td>
                        <td className="px-[16px] md:px-[30px]">
                          <Typography>
                            {`${defaultToEmptyString(item.receiving_guaranteed_years)}年`}
                          </Typography>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )
      })}
    </MyDataListWrapper>
  )
}

export default MyDataInsurance
