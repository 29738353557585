import React, { FC, useState, useEffect, useMemo, MouseEvent } from 'react'
import { Form } from 'react-final-form'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import Button from '@/components/v2/atoms/Button'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { fetchFamilyStructureV2 } from '@/containers/familyStructuresSlice'
import RadioGroup from '@/components/v2/molecules/RadioGroup'
import TextField from '@/components/v2/atoms/TextField'
import { PAYMENT_MODE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputCustomItems'
import { composeValidators, maxLength, required, zeroOrMoreNumber } from '@/utils/validate'
import ValidMessage from '@/components/v2/atoms/ValidMessage'
import Popover from '@/components/v1/atoms/Popover'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'
import {
  castNumberWithoutOperator,
  extractYearOrMonthFromDate,
  THIS_YEAR_NUMBER,
  defaultToEmptyString,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import {
  find as _find,
  filter as _filter,
  each as _each,
  set as _set,
  first as _first,
} from 'lodash'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import NumberField from '@/components/v2/molecules/NumberField'
import { PAYMENT_MODE } from '@/models/inputs/inputCustomItemsModelFunc'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  createFutureRiskV2,
  updateFutureRiskV2,
} from '@/containers/futures/futureRisksSlice'
import {
  FutureRiskCreateValues,
  FutureRiskUpdateValues,
} from '@/models/futures/futureRisksModel'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { fetchInputInsurances } from '@/containers/inputs/inputInsurancesSlice'
import clsx from 'clsx'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import SelectBox from '@/components/v2/molecules/SelectBox'
import InsuranceForm from '@/templates/v2/myData/insurances/_Form'
import { fetchCompanyRisk } from '@/containers/companyRisksSlice'
import {
  INSURED_TYPE,
  convertInsured,
  convertPeriodFinishAt,
  convertPeriod,
  convertInsuranceFeePaymentOccurrenceInMonths,
  convertInsuranceType1,
  convertInsuranceType2,
  convertNonLifeInsuranceFeeMonths,
} from '@/models/commonInsurancesModelFunc'

interface FutureRiskModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean
  /** 編集時のID */
  id: number | null
  /** 選択中の将来結婚ID */
  selectedMarriageId?: number | undefined
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

// 科目
export const CATEGORY_SELECT_ITEMS = [
  { value: 'company_bereaved', label: '会社の遺族年金' },
  { value: 'company_bereaved_education', label: '遺児・育英年金' },
  { value: 'company_bereaved_onetime_education', label: 'その他の育英一時金収入' },
]

// 適用対象
export const PAYMENT_TARGET_SELECT_ITEMS = [
  { value: '0', label: '本人' },
  { value: '1', label: '配偶者' },
  { value: '2', label: '子供' },
  { value: '3', label: '第一子' },
  { value: '4', label: '第二子' },
  { value: '5', label: '第三子' },
  { value: '6', label: '第四子' },
  { value: '7', label: '第五子' },
]

// 保険種別2
const INSURANCE_TYPE2: { [key: string]: string } = {
  wholeLife: 'whole_life',
  term: 'term',
  incomeSecurity: 'income_security',
  endowment: 'endowment',
}

// 葬儀費用
const funeralCost: { [key: string]: number } = {
  cremation: 70,
  domestic: 140,
  average: 200,
  plenty: 300,
}

const FutureRiskModalForm: FC<FutureRiskModalFormProps> = ({
  isOpen,
  onClose,
  isEdit,
  id,
  selectedMarriageId,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const { futureItems } = useSelector((state: RootState) => state.accounts)
  const futureRisks = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_risks,
  )
  const { inputInsurances } = useSelector((state: RootState) => state.inputInsurances)
  const { detailFutureBaseV2 } = useSelector((state: RootState) => state.futureBases)
  const [isInsuranceOpenModal, setIsOpenInsuranceModal] = useState<boolean>(false)
  const [targetInsuranceId, setTargetInsuranceId] = useState<number | undefined>(
    undefined,
  )
  const familyInfo = useSelector(
    (state: RootState) => state.accounts.futureItems?.family_info,
  )
  const futureMarriages = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_marriages,
  )
  const { person_current_age, spouse_current_age, spouse_marrying_age, is_married } =
    futureItems?.family_info || {}

  const targetFutureMarriage = _find(
    futureMarriages,
    (item) => item.id == selectedMarriageId,
  )

  const { companyRisk } = useSelector((state: RootState) => state.companyRiskForUser)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popoverOpenKey, setPopoverOpenIndex] = useState<string | null>(null)

  const handleCreate = (values: any) => {
    for (let index in values.future_risk.future_risk_custom_items_attributes) {
      delete values.future_risk.future_risk_custom_items_attributes[index][
        'company_risk_id'
      ]
      delete values.future_risk.future_risk_custom_items_attributes[index]['amount']
      delete values.future_risk.future_risk_custom_items_attributes[index]['id']
    }

    const newValues: any = {
      account: { future_risks_attributes: [{ ...values.future_risk }] },
    }

    return new Promise((resolve) =>
      dispatch(
        createFutureRiskV2(
          newValues,
          () => {
            dispatch(fetchAccountFutureItems(futureBaseId))
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            onClose()
          },
          resolve,
        ),
      ),
    )
  }

  const handleUpdate = (values: FutureRiskUpdateValues) => {
    const newValues: any = { future_risk: { ...values.future_risk } }

    dispatch(
      updateFutureRiskV2(newValues, id as number, () => {
        dispatch(fetchAccountFutureItems(futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleSubmit = (values: any) => {
    let newValues = { ...values }
    if (newValues.future_risk.funeral_cost_type !== 'custom') {
      newValues.future_risk.custom_funeral_cost_manyen =
        funeralCost[newValues.future_risk.funeral_cost_type] ?? 0
    }

    const customItemList = []
    if(!!newValues.future_risk_custom_items_attributes) {
      for (let customItem of newValues.future_risk_custom_items_attributes) {
        if (customItem.person_flg >= 3) {
          customItem.child_index = customItem.person_flg - 2
          customItem.person_flg = 2
        } else {
          customItem.child_index = null
        }
        customItemList.push(customItem)
      }
    }

    newValues.future_risk.future_risk_custom_items_attributes = customItemList
    delete newValues.future_risk_custom_items_attributes

    return isEdit ? handleUpdate(newValues) : handleCreate(newValues)
  }

  const handleOpenPopover = (event: MouseEvent<HTMLElement>, key: string) => {
    setAnchorEl(event.currentTarget)
    setPopoverOpenIndex(key)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    setPopoverOpenIndex(null)
  }

  const initialValues = useMemo(() => {
    const targetFutureRisks: any = _find(futureRisks, { id })
    const targetCompanyRisk = _first(companyRisk)

    if (isEdit) {
      // child_indexがある場合は、person_flgをセットしなおす
      const customItemList = []
      let customItems = targetFutureRisks?.future_risk_custom_items

      for (let customItem of customItems) {
        // customItemがreadonlyのため、別の配列に移す
        const newCustomItem: { [key: string]: any } = {}
        for (let key in customItem) {
          newCustomItem[key] = customItem[key]
        }

        if (newCustomItem.child_index !== null) {
          newCustomItem['person_flg'] = newCustomItem['child_index'] + 2
          newCustomItem['child_index'] = null
        }

        // const newCustomItemObj = Object.assign({}, newCustomItem)
        customItemList.push(newCustomItem)
      }

      return {
        future_risk: {
          person_flg: String(targetFutureRisks?.person_flg),
          death_age: targetFutureRisks?.death_age,
          dead_severance_pay_manyen: targetFutureRisks?.dead_severance_pay_manyen,
          condolence_pay_manyen: targetFutureRisks?.condolence_pay_manyen,
          incense_pay_manyen: targetFutureRisks?.incense_pay_manyen,
          living_cost_rate_percent: targetFutureRisks?.living_cost_rate_percent,
          includes_financial_asset: targetFutureRisks?.includes_financial_asset,
          funeral_cost_type: targetFutureRisks?.funeral_cost_type,
          custom_funeral_cost_manyen: targetFutureRisks?.custom_funeral_cost_manyen,
        },
        future_risk_custom_items_attributes: customItemList,
      }
    }

    if (targetCompanyRisk !== undefined) {
      return {
        future_risk: {
          person_flg: '0',
          death_age: 0,
          dead_severance_pay_manyen: targetCompanyRisk?.dead_severance_pay_manyen ?? 0,
          condolence_pay_manyen: targetCompanyRisk?.condolence_pay_manyen ?? 0,
          incense_pay_manyen: targetCompanyRisk?.incense_pay_manyen ?? 0,
          living_cost_rate_percent: 70, // 生活費水準 70
          includes_financial_asset: '0', // 金融資産 含める
          funeral_cost_type: 'cremation', // 葬儀費用 火葬
        },
        future_risk_custom_items_attributes: [
          ...targetCompanyRisk?.company_risk_custom_items,
        ],
      }
    }

    return {
      future_risk: {
        person_flg: '0',
        living_cost_rate_percent: 70, // 生活費水準 70
        includes_financial_asset: '0', // 金融資産 含める
        funeral_cost_type: 'cremation', // 葬儀費用 火葬
      },
      future_risk_custom_items_attributes: [],
    }
  }, [companyRisk])

  const inputLifeInsurances = useMemo(() => {
    return _filter(inputInsurances, (item) => item?.['is_life_insurance?'])
  }, [inputInsurances])

  // 想定するケース
  const personFlagData = useMemo(() => {
    if (
      detailFutureBaseV2?.simulation_info?.has_married ||
      detailFutureBaseV2?.simulation_info?.has_married_in_future
    ) {
      // 配偶者がいる場合
      return [
        { value: '0', label: 'ご本人が亡くなった場合' },
        { value: '1', label: '配偶者が亡くなった場合' },
      ]
    }

    // 配偶者がいない場合
    return [{ value: '0', label: 'ご本人が亡くなった場合' }]
  }, [])

  // 生活費水準 0~100
  const livingCostSelectItems = useMemo(() => {
    let select_items = []

    for (let i = 0; i < 11; i++) {
      select_items.push({ label: String(i * 10), value: i * 10 })
    }

    return select_items
  }, [])

  useEffect(() => {
    dispatch(fetchFamilyStructureV2())
    dispatch(fetchCompanyRisk())
  }, [])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="万が一の試算"
        classNameTitle="text-[19px] md:text-[24px]"
      />

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        validate={(values) => {
          const validateMessage = {}

          // // TODO プルダウンでの制御が決まり次第条件を修正する
          // const currentAge = familyInfo.person_current_age
          //   values.future_risk.person_flg === '0'
          //     ? familyStructures?.person_age || 0 // 適用対象がご本人の場合
          //     : familyStructures?.spouse_age || 0 // 適用対象が配偶者の場合

          const validatePaymentAge = (key: string) => {
            _each(values[key], (item, index) => {
              let currentAge: number = 0

              if (item.person_flg === '0') {
                currentAge = person_current_age ?? 0
              } else if (item.person_flg === '1') {
                const age = (is_married ? spouse_current_age : spouse_marrying_age) ?? 0

                currentAge = targetFutureMarriage
                  ? THIS_YEAR_NUMBER -
                    Number(
                      extractYearOrMonthFromDate(
                        targetFutureMarriage.partner_birth_day,
                        'year',
                      ),
                    )
                  : age
              }

              // if (castNumberWithoutOperator(item.payment_start_age) < currentAge) {
              //   _set(
              //     validateMessage,
              //     `${key}[${index}]payment_start_age`,
              //     '現在の年齢より後の年齢を入力してください',
              //   )
              // }

              if (item.payment_mode == PAYMENT_MODE.repeatedly) {
                if (
                  castNumberWithoutOperator(item.payment_start_age) >
                  castNumberWithoutOperator(item.payment_end_age)
                ) {
                  _set(
                    validateMessage,
                    `${key}[${index}]payment_end_age`,
                    '開始年齢より後の年齢を入力してください',
                  )
                }
              }
            })
          }

          validatePaymentAge('future_risk_custom_items_attributes')

          return validateMessage
        }}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: {
            mutators: { push },
          },
        }) => {
          const { funeral_cost_type } = values.future_risk as any
          const getId = () => {
            let tmpIdArr = values.future_risk_custom_items_attributes.map(
              (v: { tmpId: number; [key: string]: any }) => Number(v.tmpId || null),
            )
            tmpIdArr.push(0)
            return Math.max(...tmpIdArr) + 1
          }

          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <Grid className="pb-[40px] grid-cols-1 md:grid-cols-1 gap-x-[40px] gap-y-[16px]">
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      想定するケース
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <RadioGroup
                      name="future_risk.person_flg"
                      isDispRow
                      datas={personFlagData}
                      validate={required}
                    />
                  </div>
                </div>
                <div className="hidden md:block" />
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      死亡想定年齢
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField
                      name="future_risk.death_age"
                      isFullWidth
                    />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      歳
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  会社から貰える死亡保障について
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="flex min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      死亡退職金
                      <div className="flex items-center mx-[10px]">
                        <Tooltip
                          item="死亡退職金は、就業規則の退職金規定に記載があります。"
                          itemClassName="left-[-26px] w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                        >
                          <HelpCircleIcon />
                        </Tooltip>
                      </div>
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField
                      name="future_risk.dead_severance_pay_manyen"
                      isFullWidth
                    />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      弔慰金
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField name="future_risk.condolence_pay_manyen" isFullWidth />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      香典
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <NumberField name="future_risk.incense_pay_manyen" isFullWidth />
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      万円
                    </Typography>
                  </div>
                </div>
                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  会社からもらえるその他の保障について
                </Typography>

                <table className="lg:table w-full text-[14px]">
                  <tbody>
                    <tr className="border-b border-black-300 w-full hidden lg:table-row">
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          項目名
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          科目
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          適用頻度
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[20%]">
                        <Typography className="table mx-auto" isBold>
                          金額
                        </Typography>
                      </td>
                      <td className="pb-[10px] w-[10px]">
                        <Typography className="table mx-auto"></Typography>
                      </td>
                    </tr>

                    <FieldArray name="future_risk_custom_items_attributes">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          const targetAttributes =
                            values['future_risk_custom_items_attributes'][index]
                          const paymentMode = targetAttributes['payment_mode']

                          const isOnce = paymentMode == PAYMENT_MODE.once
                          const isRepeatedlyPaymentMode =
                            paymentMode == PAYMENT_MODE.repeatedly

                          return (
                            <React.Fragment
                              key={`${targetAttributes.tmpId}_income_${targetAttributes.id}`}
                            >
                              {fields.value[index] && (
                                <>
                                  <tr className="border-b border-black-300">
                                    {/* 項目名 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell mt-[30px] lg:mt-[0px]">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        項目名
                                      </Typography>
                                      <TextField
                                        widthClassName="w-full lg:w-[110px]"
                                        type="text"
                                        name={`${name}name`}
                                        placeholder="例）長男の入学、第二子の中学入学など"
                                        validate={required}
                                      />
                                    </td>

                                    {/* 科目 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        科目
                                      </Typography>
                                      <SelectBox
                                        widthClassName="w-full lg:w-[110px]"
                                        name={`${name}category`}
                                        placeholder="科目を選択"
                                        options={CATEGORY_SELECT_ITEMS}
                                        validate={required}
                                      />
                                    </td>

                                    {/* 適用頻度 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        適用頻度
                                      </Typography>
                                      <div className="lg:flex items-center w-full">
                                        <SelectBox
                                          widthClassName="w-full lg:w-[110px]"
                                          name={`${name}payment_mode`}
                                          placeholder="頻度を選択"
                                          options={PAYMENT_MODE_SELECT_ITEMS}
                                        />
                                        <div className="pl-[15px]">
                                          <div className="md:flex items-baseline mt-[16px] lg:mt-0">
                                            <SelectBox
                                              widthClassName="w-full lg:w-[110px]"
                                              name={`${name}person_flg`}
                                              placeholder="対象を選択"
                                              options={PAYMENT_TARGET_SELECT_ITEMS}
                                            />
                                            <Typography className="mx-[10px] min-w-[max-content] text-black-800">
                                              が
                                            </Typography>
                                            <div className="flex items-center">
                                              <NumberField
                                                widthClassName="lg:w-[48px] min-w-[40px]"
                                                name={`${name}payment_start_age`}
                                                disableValidationMessage
                                                validate={
                                                  !isOnce
                                                    ? composeValidators(
                                                      zeroOrMoreNumber,
                                                      (value: any) => maxLength(value, 3),
                                                    )
                                                    : undefined
                                                }
                                              />
                                              <Typography className="ml-[10px] min-w-[max-content] text-black-800">
                                                {isRepeatedlyPaymentMode
                                                  ? '歳から'
                                                  : '歳のとき'}
                                              </Typography>
                                            </div>
                                          </div>
                                          {/* カスタムバリデーションメッセージ */}
                                          <ValidMessage
                                            meta={{
                                              touched: true,
                                              error:
                                                errors
                                                  .future_risk_custom_items_attributes?.[
                                                  index
                                                ]?.['payment_start_age'],
                                            }}
                                          />
                                          {isRepeatedlyPaymentMode && (
                                            <>
                                              <div className="flex items-center mt-[10px] justify-end md:left-[34px] left-0 ">
                                                <NumberField
                                                  widthClassName="w-full lg:w-[48px]"
                                                  name={`${name}payment_end_age`}
                                                  disableValidationMessage
                                                  validate={
                                                    !isOnce
                                                      ? composeValidators(
                                                          zeroOrMoreNumber,
                                                          (value: any) => maxLength(value, 3),
                                                        )
                                                      : undefined
                                                  }
                                                />
                                                <Typography className="min-w-[max-content] text-black-800 ml-[10px]">
                                                  歳まで
                                                </Typography>
                                              </div>
                                              {/* カスタムバリデーションメッセージ */}
                                              <ValidMessage
                                                meta={{
                                                  touched: true,
                                                  error:
                                                    errors
                                                      .future_risk_custom_items_attributes?.[
                                                      index
                                                    ]?.['payment_end_age'],
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    {/* 金額 */}
                                    <td className="flex p-[10px] w-[95%] lg:w-[20%] lg:table-cell">
                                      <Typography className="lg:hidden block mx-auto min-w-[75px]">
                                        金額
                                      </Typography>
                                      <div className="flex flex-auto items-center">
                                        <NumberField
                                          name={`${name}amount_manyen`}
                                          widthClassName="w-full lg:w-[92px]"
                                          defaultValue={
                                            fields.value[index]?.amount_manyen
                                          }
                                        />
                                        <Typography className="ml-[10px] w-[43px] text-black-800 min-w-[fit-content]">
                                          {isRepeatedlyPaymentMode ? '万円/年' : '万円'}
                                        </Typography>
                                      </div>
                                    </td>

                                    {/* 閉じるボタン */}
                                    <td className="align-baseline lg:align-middle">
                                      <div
                                        className="cursor-pointer"
                                        onClick={(event) =>
                                          handleOpenPopover(event, `income-${index}`)
                                        }
                                      >
                                        <MoreVertIcon />
                                      </div>
                                      <Popover
                                        open={popoverOpenKey === `income-${index}`}
                                        anchorEl={anchorEl}
                                        onClick={handleClosePopover}
                                        onClose={handleClosePopover}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }}
                                      >
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => {
                                            new Promise((resolve) => {
                                              fields.push({
                                                ...fields.value[index],
                                                id: null,
                                                tmpId: getId(),
                                              })
                                              resolve('')
                                            }).then(() =>
                                              fields.move(fields.value.length, index + 1),
                                            )
                                          }}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            複製する
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            padding: '5px',
                                          }}
                                          onClick={() => fields.remove(index)}
                                        >
                                          <Typography style={{ margin: 0 }}>
                                            削除する
                                          </Typography>
                                        </div>
                                      </Popover>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          )
                        })
                      }}
                    </FieldArray>
                  </tbody>
                </table>

                <Button
                  className="w-full h-[53px] border border-dashed border-primary-500 text-[14px] text-primary-500 hover:bg-primary-50"
                  onClick={() => {
                    push('future_risk_custom_items_attributes', {
                      tmpId: getId(),
                    })
                  }}
                >
                  <Typography isBold>追加</Typography>
                </Button>

                <Typography className="flex md:text-[px] pb-[10px]" isBold>
                  生活費水準
                  <div className="flex items-center mx-[10px]">
                    <Tooltip
                      item="一般的には70%で設定します。。"
                      itemClassName="left-[-26px] w-160 p-8 text-10 text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                    >
                      <HelpCircleIcon />
                    </Tooltip>
                  </div>
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      現状の生活費の
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <SelectBox
                      name="future_risk.living_cost_rate_percent"
                      isFullWidth
                      options={livingCostSelectItems}
                      validate={required}
                    />
                  </div>
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                      isBold
                    >
                      %に抑える
                    </Typography>
                  </div>
                </div>

                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  金融資産
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      必要保障額に
                      <br />
                      金融資産を
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <SelectBox
                      name="future_risk.includes_financial_asset"
                      isFullWidth
                      options={[
                        { value: '0', label: '含める' },
                        { value: '1', label: '含めない' },
                      ]}
                      validate={required}
                    />
                  </div>
                </div>

                <div className="hidden md:block" />

                <Typography className="md:text-[px] pb-[10px]" isBold>
                  ご葬儀について
                </Typography>
                <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                  <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                    <Typography
                      className="min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
                      isBold
                    >
                      想定する葬儀費用
                    </Typography>
                  </div>

                  <div className="flex items-center justify-end">
                    <SelectBox
                      name="future_risk.funeral_cost_type"
                      isFullWidth
                      options={[
                        { value: 'cremation', label: '火葬式(70万円)' },
                        { value: 'domestic', label: '家族葬(140万円)' },
                        { value: 'average', label: '人並み(200万円)' },
                        { value: 'plenty', label: 'とことん(300万円)' },
                        { value: 'custom', label: 'カスタム(手入力)' },
                      ]}
                      validate={required}
                    />
                  </div>

                  {funeral_cost_type === 'custom' && (
                    <div className="flex items-center my-[10px] justify-end md:pl-16">
                      <NumberFieldDecimal
                        name="future_risk.custom_funeral_cost_manyen"
                        isFullWidth
                      />
                      <Typography
                        className="pl-16 text-14 text-black-800 min-w-[fit-content]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>

              <div className="hidden md:block" />
              <Typography className="md:text-[px] pb-[30px]" isBold>
                現在加入している生命・医療・年金保険
              </Typography>

              {inputLifeInsurances.length === 0 && (
                <React.Fragment>
                  <Typography
                    className="text-[14px] pb-[40px] md:text-[16px] text-center"
                    isBold
                  >
                    生命・医療・年金保険が登録されていません。下記のボタンから登録してみましょう。
                  </Typography>

                  <Button
                    className="bg-white border border-secondary-500 h-[37px] mb-[40px] mx-[auto] rounded-[4px] w-[252px] hover:bg-secondary-5"
                    onClick={() => setIsOpenInsuranceModal(true)}
                  >
                    <Typography
                      className="bottom-[1px] pl-[9px] relative text-[14px] text-secondary-500"
                      isBold
                    >
                      新しく追加する
                    </Typography>
                  </Button>
                </React.Fragment>
              )}

              {inputLifeInsurances.length > 0 && (
                <React.Fragment>
                  <Typography className="text-14 text-center pb-16 text-black-700" isBold>
                    シミュレーションの前に加入している生命・医療・年金保険についてご確認ください。
                    <br />
                    保険金額と被保険者は合っていますか？
                  </Typography>

                  <div className="mb-[64px] last:mb-0">
                    <table className="w-[100%]">
                      <tbody>
                        <tr className="hidden md:table-row h-[45px] w-[100%] text-[12px] md:text-[14px]">
                          <td className="hidden md:table-cell px-[16px] py-2 text-[16px] text-black-800 text-center">
                            保険会社名
                          </td>
                          <td className="hidden md:table-cell px-[16px] py-2 text-[16px] text-black-800 text-center">
                            保険種別
                          </td>
                          <td className="hidden md:table-cell px-[16px] py-2 text-[16px] text-black-800 text-center">
                            被保険者
                          </td>
                          <td className="hidden md:table-cell px-[16px] py-2 text-[16px] text-black-800 text-center">
                            保険期間
                          </td>
                          <td className="hidden md:table-cell px-[16px] py-2 text-[16px] text-black-800 text-center">
                            保険料
                          </td>
                          <td className="hidden md:table-cell px-[16px] py-2 text-[16px] text-black-800 text-center">
                            保険金額
                          </td>
                        </tr>
                        {inputLifeInsurances.map((item: any, index: number) => (
                          <>
                            <tr className="h-[45px]" key={index}>
                              <td className="md:table-cell block px-[16px] py-[8px] text-black-800 md:w-[20%]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    保険会社名
                                  </Typography>
                                  <Typography className="md:w-[100%] md:text-center">
                                    {defaultToEmptyString(item?.company_name)}
                                  </Typography>
                                </div>
                              </td>
                              <td className="md:table-cell block px-[16px] py-[8px] text-black-800 md:w-[20%]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    保険種別
                                  </Typography>
                                  <Typography className="md:w-[100%] md:text-center">
                                    {`${convertInsuranceType1(
                                      item?.insurance_type1,
                                    )} ${convertInsuranceType2(item?.insurance_type2)}`}
                                  </Typography>
                                </div>
                              </td>
                              <td className="md:table-cell block px-[16px] py-[8px] text-black-800 md:w-[13%]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    被保険者
                                  </Typography>
                                  <Typography className="md:w-[100%] md:text-center">
                                    {convertInsured(
                                      item?.insured_type,
                                      item?.insured_name,
                                    )}
                                  </Typography>
                                </div>
                              </td>
                              <td className="md:table-cell block px-[16px] py-[8px] text-black-800 md:w-[13%]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    保険期間
                                  </Typography>
                                  <Typography className="md:w-[100%] md:text-center">
                                    {item?.insured_type ===
                                    INSURED_TYPE.otherPersonInsured
                                      ? convertPeriodFinishAt(
                                          item?.insurance_period_type,
                                          item?.insurance_period_finish_at,
                                          item?.insured_type,
                                          item?.insured_name,
                                          'insured',
                                        )
                                      : convertPeriod(
                                          item?.insurance_period_type,
                                          item?.insurance_period_finish_at_age,
                                          item?.insured_type,
                                          item?.insured_name,
                                          'insured',
                                        )}
                                  </Typography>
                                </div>
                              </td>
                              <td className="md:table-cell block px-[16px] py-[8px] text-black-800 md:w-[16%]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    保険料
                                  </Typography>
                                  <Typography className="md:w-[100%] md:text-center">
                                    {`${
                                      item?.['is_life_insurance?']
                                        ? convertInsuranceFeePaymentOccurrenceInMonths(
                                            String(
                                              item?.insurance_fee_payment_occurrence_in_months,
                                            ),
                                          )
                                        : convertNonLifeInsuranceFeeMonths(
                                            item?.insurance_fee_payment_occurrence_in_months,
                                          )
                                    } ${convertLocaleString(item?.insurance_fee)}円`}
                                  </Typography>
                                </div>
                              </td>
                              <td className="md:table-cell block px-[16px] py-[8px] text-black-800 md:w-[16%]">
                                <div className="flex">
                                  <Typography isBold className="block md:hidden w-[50%]">
                                    保険金額
                                  </Typography>
                                  <Typography className="md:w-[100%] md:text-center">
                                    {item?.insurance_type2 ===
                                    INSURANCE_TYPE2.incomeSecurity
                                      ? `月々 ${convertLocaleString(
                                          item?.insured_income_monthly_manyen,
                                        )}万円`
                                      : `${convertLocaleString(
                                          item?.insurance_amount_manyen,
                                        )}万円`}
                                  </Typography>
                                </div>
                              </td>
                              <td className="px-[16px] text-center text-black-800">
                                <ButtonPrimary
                                  className="mx-[auto] w-[50px] h-[30px]"
                                  type="button"
                                  isDisabled={false}
                                  onClick={() => {
                                    setTargetInsuranceId(item.id)
                                    setIsOpenInsuranceModal(true)
                                  }}
                                >
                                  <Typography className="text-[14px] text-white" isBold>
                                    修正
                                  </Typography>
                                </ButtonPrimary>
                              </td>
                            </tr>
                            <div className="hidden sm:block" />
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Button
                    className="bg-white border border-secondary-500 h-[37px] mb-[40px] mx-[auto] rounded-[4px] w-[252px] hover:bg-secondary-5"
                    onClick={() => setIsOpenInsuranceModal(true)}
                  >
                    <Typography
                      className="bottom-[1px] pl-[9px] relative text-[14px] text-secondary-500"
                      isBold
                    >
                      新しく追加する
                    </Typography>
                  </Button>
                </React.Fragment>
              )}

              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                type="submit"
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />

      {isInsuranceOpenModal && (
        <Modal
          className="w-[95%] xl:w-[1140px]"
          isOpen={isInsuranceOpenModal}
          isDisableBackdropClick
          onClose={() => setIsOpenInsuranceModal(false)}
        >
          <InsuranceForm
            isSeiho={true}
            insuranceId={(targetInsuranceId ?? 0) > 0 ? targetInsuranceId : undefined}
            handleCancel={() => setIsOpenInsuranceModal(false)}
            submitCallback={() => {
              setIsOpenInsuranceModal(false)
              dispatch(fetchInputInsurances())
            }}
          />
        </Modal>
      )}
    </Modal>
  )
}

export default FutureRiskModalForm
