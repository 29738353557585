import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import { find as _find, includes as _includes } from 'lodash'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureRiskModalForm from '@/templates/v2/simulations/_futureRiskModalForm'
import {
  PAYMENT_TARGET_SELECT_ITEMS,
  CATEGORY_SELECT_ITEMS,
} from '@/templates/v2/simulations/_futureRiskModalForm'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureRiskV2 } from '@/containers/futures/futureRisksSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import { convertLocaleString, convertCostTypeToJp } from '@/models/commonsModelFunc'
import Tooltip from '@/components/v2/atoms/Tooltip'
import { PAYMENT_MODE } from '@/models/inputs/inputCustomItemsModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureRiskFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /** 選択中の将来結婚ID */
  selectedMarriageId?: number | undefined
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureRiskTableProps {
  /** アイテム */
  item: any

  /** td要素のクラス */
  tdClassName?: string
}

export const FutureRiskTable: FC<FutureRiskTableProps> = ({ item, tdClassName }) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>想定するケース</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>
              {item.person_flg == '0'
                ? 'ご本人が亡くなった場合'
                : '配偶者が亡くなった場合'}
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>死亡想定年齢</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>
              {convertLocaleString(item.death_age)} 歳
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>死亡退職金</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>
              {convertLocaleString(item.dead_severance_pay_manyen)} 万円
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>弔慰金</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>
              {convertLocaleString(item.condolence_pay_manyen)} 万円
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>香典</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>{convertLocaleString(item.incense_pay_manyen)} 万円</Typography>
          </td>
        </tr>

        {item.future_risk_custom_items.map((customItem: any, index: number) => {
          const isOnce = customItem.payment_mode == PAYMENT_MODE.once

          return (
            <React.Fragment key={`${index}_income`}>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px] md:px-[30px]">
                  {index === 0 && (
                    <Typography isBold>
                      会社から貰える
                      <br />
                      その他の保障
                    </Typography>
                  )}
                </td>
                <td className="w-[65%] px-[16px] md:px-[30px]">
                  <Typography>{customItem.name}</Typography>
                  <Typography>
                    {
                      _find(CATEGORY_SELECT_ITEMS, (items) => {
                        return items.value === customItem.category
                      })?.label
                    }
                  </Typography>
                  <Typography>
                    {
                      _find(PAYMENT_TARGET_SELECT_ITEMS, (items) => {
                        let targetPersonFlg: number = Number(customItem.person_flg)
                        if (customItem.child_index !== null) {
                          targetPersonFlg = customItem.child_index + 2
                        }
                        return Number(items.value) === targetPersonFlg
                      })?.label
                    }
                    が
                    <br />
                    {convertLocaleString(customItem.payment_start_age)}
                    {isOnce ? `歳のとき` : `歳から`}
                    <br />
                    {isOnce
                      ? undefined
                      : `${convertLocaleString(customItem.payment_end_age)}歳まで`}
                  </Typography>
                  <Typography>
                    {convertLocaleString(customItem.amount_manyen)}{' '}
                    {isOnce ? `万円` : `万円/年`}
                  </Typography>
                </td>
              </tr>
            </React.Fragment>
          )
        })}

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>生活費水準</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>{item.living_cost_rate_percent} %に抑える</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>
              必要保障額に
              <br />
              金融資産を
            </Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>
              {item.includes_financial_asset === 0 ? '含める' : '含めない'}
            </Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="w-[40%] px-[16px] md:px-[30px]">
            <Typography isBold>ご葬儀について</Typography>
          </td>
          <td className="w-[65%] px-[16px] md:px-[30px]">
            <Typography>{convertCostTypeToJp(item.funeral_cost_type)}</Typography>
            <Typography>
              {convertLocaleString(item.custom_funeral_cost_manyen)} 万円
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const FutureRiskForm: FC<FutureRiskFormProps> = ({
  selectedSimulationId,
  handleSelect,
  selectedMarriageId,
  futureBaseId,
  shouldInitialize,
  confirmEdit,
  openEditConfirmModal,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [riskId, setRiskId] = useState<number | null>(null)

  const futureRisks = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_risks,
  )

  const { detailFutureBaseV2 } = useSelector((state: RootState) => state.futureBases)

  // true:選択可 false:選択不可
  const isActive = (item: any): boolean => {
    // 結婚しているか [true:既婚 false:未婚]
    const isMarried: boolean =
      detailFutureBaseV2?.simulation_info?.has_married ||
      detailFutureBaseV2?.simulation_info?.has_married_in_future

    // 配偶者が対象か [true:対象が配偶者 false:そうじゃない]
    const isTargetPartner: boolean = item.person_flg === 1

    // 結婚している、もしくは配偶者の場合じゃなければtrue
    return isMarried || !isTargetPartner
  }

  useEffect(() => {
    if (futureBaseId == null || !shouldInitialize) return
    const item = _find(futureRisks, { future_base_id: futureBaseId })
    if (item != null) handleSelect(item)
  }, [shouldInitialize])

  const sortedFutureRisks = useMemo(() => {
    if (futureBaseId == null || futureRisks == null) return futureRisks
    const eqFbId = (item: any) => item.future_base_id === futureBaseId
    return [
      ...futureRisks.filter((item) => eqFbId(item)),
      ...futureRisks.filter((item) => !eqFbId(item)),
    ]
  }, [futureRisks])

  const titleIndex = (index: number) => {
    if (futureBaseId == null || confirmEdit == null || futureRisks == null)
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === futureBaseId
    return index + 1 - futureRisks.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    confirmEdit === false ? openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="万が一、亡くなった場合を想定"
      isSelected={!!selectedSimulationId}
      itemCount={sortedFutureRisks?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureRisks?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureRiskV2(item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (item.id === selectedSimulationId) handleSelect(item)
              dispatch(fetchAccountFutureItems(futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setRiskId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`${item.id}_${index}`}
              title={
                futureBaseId === item.future_base_id && confirmEdit != null
                  ? '登録中の万が一の試算'
                  : `万が一の試算${titleIndex(index)}`
              }
              isSelected={item.id === selectedSimulationId}
              inputType="radio"
              onSelect={() => {
                if (isActive(item)) handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              {!isActive(item) ? (
                <Tooltip
                  itemClassName="w-[330px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
                  item="配偶者がいないため設定できません"
                >
                  <FutureRiskTable item={item} />
                </Tooltip>
              ) : (
                <FutureRiskTable item={item} />
              )}
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setRiskId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureRiskModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={riskId}
          selectedMarriageId={selectedMarriageId}
          futureBaseId={futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureRiskForm
