import { useMemo } from 'react'
import {
  filter as _filter,
  isNumber as _isNumber,
  isUndefined as _isUndefined,
  findIndex as _findIndex,
  times as _times,
  reverse as _reverse,
} from 'lodash'
import moment, { Moment } from 'moment'
import { SelectItem } from '@/components/v1/molecules/SelectBox'
import { SelectBoxOption } from '@/components/v2/molecules/SelectBox'
import { AGE_SELECT_ITEMS } from '@/constants/formItem/commons'
import { isNotEmptyValue, combineTwoTypesYear, THIS_YEAR } from '@/models/commonsModelFunc'
import { TWO_TYPES_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'

export const buildRangeYearSelectItems = (
  startYear: number,
  endYear: number,
  isReverse?: boolean,
) => {
  const yearSelectItems = []

  for (let i = startYear; i <= endYear; i += 1) {
    yearSelectItems.push({
      value: String(i),
      label: String(i),
    })
  }

  return isReverse ? _reverse(yearSelectItems) : yearSelectItems
}
export const buildTwoTypesRangeYearSelectItems = (
  startYear: number,
  endYear: number,
  isReverse?: boolean,
) => {
  const yearSelectItems = []

  for (let i = startYear; i <= endYear; i += 1) {
    yearSelectItems.push({
      value: String(i),
      label: combineTwoTypesYear(i),
    })
  }

  return isReverse ? _reverse(yearSelectItems) : yearSelectItems
}

export const buildAgeLimitSelectItems = (age: number | null | undefined) => {
  return isNotEmptyValue(age)
    ? _filter(AGE_SELECT_ITEMS, (item) => item.value >= age!)
    : AGE_SELECT_ITEMS
}

export const buildRangeAgeSelectItems = (
  lower: number,
  upper: number,
  ageSelectItem: SelectItem[] = AGE_SELECT_ITEMS,
) => {
  return _filter(ageSelectItem, (item) => item.value >= lower && item.value <= upper)
}

export const buildStandardSelectItems = (
  selectItems: SelectBoxOption[],
  standardValue: any,
  placeholder?: string,
) => {
  const index = _findIndex(selectItems, { value: standardValue })
  if (index === -1) return selectItems

  const copiedSelectItems = [...selectItems]

  if (placeholder) {
    copiedSelectItems.splice(index, 0, {
      value: '',
      isDisabled: true,
      label: String(placeholder),
    })
  }

  return copiedSelectItems
}

export const renderStartAtYearOptions = (startAtYear?: string) => {
  const isSetPastYear = startAtYear !== undefined && Number(startAtYear) < Number(THIS_YEAR)
  const defaultYear = isSetPastYear ? startAtYear : THIS_YEAR
  const selectItems = isSetPastYear
    ? buildTwoTypesYearSelectItems(Number(defaultYear))
    : TWO_TYPES_YEAR_SELECT_ITEMS

  return buildStandardSelectItems(selectItems, defaultYear)
}

export const buildDateMonthSelectItems = (dateMonth: Moment | string, length: number) => {
  return useMemo(
    () =>
      _times(length, (index) => ({
        value: moment(dateMonth).subtract(index, 'months').format('YYYY/MM'),
        label: moment(dateMonth).subtract(index, 'months').format('YYYY年MM月'),
      })),
    [dateMonth, length],
  )
}

export const convertRadioGroupValue = (value: any) => {
  return _isNumber(value) ? String(value) : null
}

export const convertCheckboxValue = (value: any, checkValue: any, notCheckValue: any) => {
  if (_isUndefined(value)) return notCheckValue
  return value.length > 0 ? checkValue : notCheckValue
}

export const buildDaySelectItems = (year: number, month: number) => {
  // 年と月が指定されていない場合、デフォルトで31日とする
  if (!year || !month) {
    return _times(31, (index) => ({
      value: index + 1,
      label: `${index + 1}日`,
    }));
  }

  // 月は1から12の範囲内に制限
  const validMonth = Math.min(Math.max(month, 1), 12);

  // その月の日数を取得
  const daysInMonth = new Date(year, validMonth, 0).getDate();

  // 日付の選択肢を生成
  return _times(daysInMonth, (index) => ({
    value: index + 1,
    label: `${index + 1}日`,
  }));
};

export const buildTwoTypesYearSelectItems = (startYear: number) => {
  return _times(70, (index) => ({
    value: String(index + startYear),
    label: combineTwoTypesYear(index + startYear),
  }))
}