import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import Typography from '@/components/v2/atoms/Typography'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import FutureChildModalForm from '@/templates/v2/simulations/_futureChildModalForm'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Button from '@/components/v2/atoms/Button'
import { CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS } from '@/constants/formItem/futures/futureFamilyChildbirths'
import { find as _find } from 'lodash'
import {
  THIS_YEAR_NUMBER,
  convertLocaleString,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import { PERSON_FLG } from '@/models/familyStructuresModelFunc'
import { LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS } from '@/constants/formItem/futures/futureFamilyHours'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { destroyFutureFamilyV2 } from '@/containers/futures/futureFamiliesSlice'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import {
  convertSchoolType,
  convertUniversitySchoolType,
  SCHOOL_AGE,
} from '@/models/futures/futurePlanTuitionsModelFunc'
import FutureEducationCostModalForm from '@/templates/v2/simulations/_futureEducationCostModalForm'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureChildFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}
interface FutureChildTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureChildTable: FC<FutureChildTableProps> = ({ item }) => {
  return (
    <>
      <table
        key={item.id}
        className={clsx(
          'w-[100%] last:mb-0 text-[12px] md:text-[14px] cursor-pointer bg-white',
        )}
      >
        <tbody>
          <tr className="h-[45px] odd:bg-black-100">
            <td className="w-[40%] px-[16px]">
              <Typography isBold>将来のお子様</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>{item.future_family_childbirths.length} 名を想定</Typography>
            </td>
          </tr>

          {item.future_family_childbirths?.map((child: any, index: number) => {
            return (
              <React.Fragment key={`childbirth_${child.id}_${index}`}>
                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">
                    <Typography isBold>{`${
                      child.childbirth_index ?? ''
                    }人目`}</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {extractYearOrMonthFromDate(
                        child.childbirth_scheduled_at,
                        'year',
                      ) !== ''
                        ? extractYearOrMonthFromDate(
                            child.childbirth_scheduled_at,
                            'year',
                          )
                        : child.childbirth_scheduled_at}
                      年
                    </Typography>
                  </td>
                </tr>

                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">
                    <Typography isBold>出産費用</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>{`${convertLocaleString(
                      child.childbirth_cost_manyen,
                    )}万円`}</Typography>
                  </td>
                </tr>

                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">
                    <Typography isBold>ご本人の育休</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {
                        _find(CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS, {
                          value: child.childcare_leave_months_person,
                        })?.label
                      }
                    </Typography>
                  </td>
                </tr>

                <tr className="h-[45px] odd:bg-black-100">
                  <td className="w-[40%] px-[16px]">
                    <Typography isBold>配偶者の育休</Typography>
                  </td>
                  <td className="px-[16px]">
                    <Typography>
                      {
                        _find(CHILDCARE_LEAVE_MONTHS_SELECT_ITEMS, {
                          value: child.childcare_leave_months_spouse,
                        })?.label
                      }
                    </Typography>
                  </td>
                </tr>
              </React.Fragment>
            )
          })}

          {item.future_family_childbirths.length > 0 && (
            <>
              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography isBold>ご本人の時短勤務</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {
                      _find(LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS, {
                        value: _find(item?.future_family_hours, {
                          person_flg: PERSON_FLG.person,
                        })?.leaving_years_child_becomes,
                      })?.label
                    }
                    {_find(item?.future_family_hours, {
                      person_flg: PERSON_FLG.person,
                    })?.leaving_years_child_becomes != 0 &&
                      `通常時の${
                        _find(item?.future_family_hours, {
                          person_flg: PERSON_FLG.person,
                        })?.income_ratio_percent
                      }%`}
                  </Typography>
                </td>
              </tr>

              <tr className="h-[45px] odd:bg-black-100">
                <td className="w-[40%] px-[16px]">
                  <Typography isBold>配偶者の時短勤務</Typography>
                </td>
                <td className="px-[16px]">
                  <Typography>
                    {
                      _find(LEAVING_YEARS_CHILD_BECOMES_SELECT_ITEMS, {
                        value: _find(item?.future_family_hours, {
                          person_flg: PERSON_FLG.spouse,
                        })?.leaving_years_child_becomes,
                      })?.label
                    }
                    {_find(item?.future_family_hours, {
                      person_flg: PERSON_FLG.spouse,
                    })?.leaving_years_child_becomes != 0 &&
                      `通常時の${
                        _find(item?.future_family_hours, {
                          person_flg: PERSON_FLG.spouse,
                        })?.income_ratio_percent
                      }%`}
                  </Typography>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  )
}

export const FutureEducationCostTable: FC<any> = ({ item }) => {
  return (
    <table className="w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white">
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <th className="pl-[10px] text-left">
            <Typography isBold>学校</Typography>
          </th>
          <th className="pl-[10px] text-left">
            <Typography isBold>学年</Typography>
          </th>
          <th className="pl-[10px] text-left">
            <Typography isBold>学費</Typography>
          </th>
          <th className="pl-[10px] text-left">
            <Typography isBold>学外費</Typography>
          </th>
          <th className="pl-[10px] text-left">
            <Typography isBold>合計額</Typography>
          </th>
        </tr>

        {educationCostContentArr.map((educationCostContent: any, index: number) => (
          <tr
            className="h-[45px] odd:bg-black-100"
            key={`future_plan_tuitions_${educationCostContent.id}_${index}`}
          >
            <td className="w-[40%] pl-[10px]">{educationCostContent.school(item)}</td>
            <td className="w-[15%] pl-[10px]">
              {educationCostContent.school_grade(item)}
            </td>
            <td className="w-[15%] pl-[10px]">
              {educationCostContent.on_campus_cost(item)}
            </td>
            <td className="w-[15%] pl-[10px]">
              {educationCostContent.off_campus_cost(item)}
            </td>
            <td className="w-[15%] pl-[10px]">{educationCostContent.total(item)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const educationCostContentArr = [
  {
    school: (item: any) => <Typography>0〜3歳児保育</Typography>,
    school_grade: (item: any) => <Typography>0歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[0]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[0]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.toddler0,
  },
  {
    school: (item: any) => <Typography>0〜3歳児保育</Typography>,
    school_grade: (item: any) => <Typography>1歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[0]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[0]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.toddler1,
  },
  {
    school: (item: any) => <Typography>0〜3歳児保育</Typography>,
    school_grade: (item: any) => <Typography>2歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[0]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_3_manyen +
            item.future_plan_tuitions[0]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.toddler2,
  },
  {
    school: (item: any) => <Typography>0〜3歳児保育</Typography>,
    school_grade: (item: any) => <Typography>3歳</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[0]?.tuition_4_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_4_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[0]?.off_campus_expenses_4_manyen +
            item.future_plan_tuitions[0]?.tuition_4_manyen,
        )}万円`}
      </Typography>
    ),

    comperison: (item: any) => SCHOOL_AGE.toddler3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[1]?.school_type)} 幼稚園／保育園
      </Typography>
    ),
    school_grade: (item: any) => <Typography>年少</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[1]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[1]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[1]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[1]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.kindergarten1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[1]?.school_type)} 幼稚園／保育園
      </Typography>
    ),
    school_grade: (item: any) => <Typography>年中</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[1]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[1]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[1]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[1]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.kindergarten2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[1]?.school_type)} 幼稚園／保育園
      </Typography>
    ),
    school_grade: (item: any) => <Typography>年長</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[1]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[1]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[1]?.off_campus_expenses_3_manyen +
            item.future_plan_tuitions[1]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.kindergarten3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[2]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[2]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[2]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[2]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[2]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_3_manyen +
            item.future_plan_tuitions[2]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool3,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>4年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[2]?.tuition_4_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_4_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_4_manyen +
            item.future_plan_tuitions[2]?.tuition_4_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool4,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>5年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[2]?.tuition_5_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_5_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_5_manyen +
            item.future_plan_tuitions[2]?.tuition_5_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool5,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[2]?.school_type)} 小学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>6年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[2]?.tuition_6_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_6_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[2]?.off_campus_expenses_6_manyen +
            item.future_plan_tuitions[2]?.tuition_6_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.primarySchool6,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[3]?.school_type)} 中学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[3]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[3]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[3]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[3]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.juniorHighSchool1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[3]?.school_type)} 中学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[3]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[3]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[3]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[3]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.juniorHighSchool2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[3]?.school_type)} 中学校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[3]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[3]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[3]?.off_campus_expenses_3_manyen +
            item.future_plan_tuitions[3]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.juniorHighSchool3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[4]?.school_type)} 高校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[4]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[4]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[4]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[4]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.highSchool1,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[4]?.school_type)} 高校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[4]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[4]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[4]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[4]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.highSchool2,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertSchoolType(item.future_plan_tuitions[4]?.school_type)} 高校
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[4]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[4]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[4]?.off_campus_expenses_3_manyen +
            item.future_plan_tuitions[4]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) => SCHOOL_AGE.highSchool3,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.future_plan_tuitions[5]?.school_type)} 大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[5]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[5]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      !!item.future_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university1 : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.future_plan_tuitions[5]?.school_type)} 大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[5]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[5]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      !!item.future_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university2 : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.future_plan_tuitions[5]?.school_type)} 大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>3年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[5]?.tuition_3_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_3_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_3_manyen +
            item.future_plan_tuitions[5]?.tuition_3_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      !!item.future_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university3 : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.future_plan_tuitions[5]?.school_type)} 大学
      </Typography>
    ),
    school_grade: (item: any) => <Typography>4年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[5]?.tuition_4_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_4_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[5]?.off_campus_expenses_4_manyen +
            item.future_plan_tuitions[5]?.tuition_4_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      !!item.future_plan_tuitions[5]?.tuition_1_manyen ? SCHOOL_AGE.university4 : 0,
  },

  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.future_plan_tuitions[6]?.school_type)} 大学院
      </Typography>
    ),
    school_grade: (item: any) => <Typography>1年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[6]?.tuition_1_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[6]?.off_campus_expenses_1_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[6]?.off_campus_expenses_1_manyen +
            item.future_plan_tuitions[6]?.tuition_1_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      !!item.future_plan_tuitions[6]?.tuition_1_manyen ? SCHOOL_AGE.graduateSchool1 : 0,
  },
  {
    school: (item: any) => (
      <Typography>
        {convertUniversitySchoolType(item.future_plan_tuitions[6]?.school_type)} 大学院
      </Typography>
    ),
    school_grade: (item: any) => <Typography>2年生</Typography>,
    on_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(item.future_plan_tuitions[6]?.tuition_2_manyen)}万円`}
      </Typography>
    ),
    off_campus_cost: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[6]?.off_campus_expenses_2_manyen,
        )}万円`}
      </Typography>
    ),
    total: (item: any) => (
      <Typography>
        {`${convertLocaleString(
          item.future_plan_tuitions[6]?.off_campus_expenses_2_manyen +
            item.future_plan_tuitions[6]?.tuition_2_manyen,
        )}万円`}
      </Typography>
    ),
    comperison: (item: any) =>
      !!item.future_plan_tuitions[6]?.tuition_1_manyen ? SCHOOL_AGE.graduateSchool2 : 0,
  },
]

const FutureChildForm: FC<FutureChildFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [id, setId] = useState<number | null>(null)

  const [isOpenTuitionModal, setIsOpenTuitionModal] = useState<boolean>(false)
  const [isEditTuition, setIsEditTuition] = useState<boolean>(false)
  const [childbirthId, setChildbirthId] = useState<number | null>(null)

  const futureFamilyBases = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_family_bases,
  )

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    const item = _find(futureFamilyBases, { future_base_id: props.futureBaseId })
    if (item != null) handleSelect(item)
  }, [props.shouldInitialize])

  const sortedFutureFamilyBases = useMemo(() => {
    if (props.futureBaseId == null || futureFamilyBases == null) return futureFamilyBases
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureFamilyBases.filter((item) => eqFbId(item)),
      ...futureFamilyBases.filter((item) => !eqFbId(item)),
    ]
  }, [futureFamilyBases])

  const titleIndex = (index: number) => {
    if (
      props.futureBaseId == null ||
      props.confirmEdit == null ||
      futureFamilyBases == null
    )
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureFamilyBases.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="将来のお子様の検討"
      isSelected={!!selectedSimulationId}
      itemCount={sortedFutureFamilyBases?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureFamilyBases?.map((item: any, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureFamilyV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (item.id === selectedSimulationId) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`family_base_${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? `登録中の将来のお子様予定`
                  : `将来のお子様予定${titleIndex(index)}`
              }
              isSelected={item.id === selectedSimulationId}
              inputType="radio"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              <div className={clsx('mb-[20px]')}>
                <FutureChildTable item={item} />
                {item.future_family_childbirths?.map((child: any, index: number) => {
                  return child.future_plan_tuitions &&
                    child.future_plan_tuitions.length > 0 ? (
                    <React.Fragment key={`child_tuition_${child.id}_${index}`}>
                      <div className="flex justify-between pl-10">
                        {`${child.childbirth_index ?? ''}人目の教育費`}

                        <Button
                          className="mr-[16px]"
                          isDisabled={!isManipulatedApproval}
                          onClick={() => {
                            setChildbirthId(child.id)
                            setIsEditTuition(true)
                            setIsOpenTuitionModal(true)
                          }}
                        >
                          <i className="ico edit text-[14px] text-black-700" />
                          <Typography className="pl-[5px] text-[14px] text-black-700">
                            編集
                          </Typography>
                        </Button>
                      </div>
                      <FutureEducationCostTable item={child} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`child_tuition_${child.id}_${index}`}>
                      <div className="flex justify-between my-20 pl-10">
                        {`${child.childbirth_index ?? ''}人目の教育費`}
                        <Button
                          className="w-[120px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                          isDisabled={!isManipulatedApproval}
                          onClick={() => {
                            setChildbirthId(child.id)
                            setIsEditTuition(false)
                            setIsOpenTuitionModal(true)
                          }}
                        >
                          <Typography
                            className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                            isBold
                          >
                            教育費を登録する
                          </Typography>
                        </Button>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </SimulationItemAccordion>
          )
        })}
      </div>

      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureChildModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={id}
          futureBaseId={props.futureBaseId}
        />
      )}

      {isOpenTuitionModal && (
        <FutureEducationCostModalForm
          isOpen={isOpenTuitionModal}
          isEdit={isEditTuition}
          onClose={() => setIsOpenTuitionModal(false)}
          id={childbirthId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureChildForm