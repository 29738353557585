import React, { FC, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import { isNull as _isNull } from 'lodash'
import CautionIcon from '@/assets/images/caution.svg'
import ExternalLink from '@/assets/images/v2/external-link.svg'
import InfoIcon from '@/assets/images/v2/info.svg'
import TrashIcon from '@/assets/images/v2/trash.svg'
import Button from '@/components/v2/atoms/Button'
import Chip from '@/components/v2/atoms/Chip'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import Tooltip from '@/components/v2/atoms/Tooltip'
import Typography from '@/components/v2/atoms/Typography'
import { INPUT_ASSET_TYPE_SELECT_ITEMS } from '@/constants/formItem/inputs/inputAssets'
import { fetchAccount } from '@/containers/accountsSlice'
import {
  destroyInputAsset,
  fetchInputAssetSummary,
} from '@/containers/inputs/inputAssetsSlice'
import { setPageMeta } from '@/containers/pageMetasSlice'
import { useAccountControl } from '@/hooks/useAccountControl'
import { useCustomHistory } from '@/hooks/useCustomHistory'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AccountContext } from '@/models/accountsModelFunc'
import { convertIsTaxChargable } from '@/models/commonAssetsModelFunc'
import {
  defaultToEmptyString,
  convertLocaleString,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'
import {
  hasSummaryOf,
  filterInputAsset,
  convertInputAssetType,
  convertInputAssetPersonFlg,
  convertAccountType,
  isNisaAccount,
  convertRolloverType,
  convertReservedAmountMonthly,
  ACCOUNT_TYPE,
  isInvestmentAssetType,
} from '@/models/inputs/inputAssetsModelFunc'
import { AssetType, InputAsset } from '@/models/inputs/inputAssetsModel'
import { RootState } from '@/store'
import MyDataListWrapper from '@/templates/v2/myData/_ListWrapper'
import MyDataAssetInvestment from '@/templates/v2/myData/AssetInvestment'
import { getMoneytreeVaultUrl } from '@/utils/moneytree'
import moment from 'moment'
import { updateResetFlg } from '@/containers/futures/futureBasesSlice'

const classes = {
  sp: {
    itemBlock: 'flex items-center space-x-12 text-14',
  },
  pc: {
    headerLeftCell: 'py-2 text-left',
    headerRightCell: 'py-2 text-right',
    leftCell: 'py-8 text-left',
    rightCell: 'py-8 text-right',
  },
  detailButton: 'relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500',
}

const MyDataAsset: FC = () => {
  const { showFlashMsg } = useFlashAlert()
  const dispatch = useDispatch()
  const { isAdmin } = useContext(AccountContext)
  const { isMyDataManipulatedApproval } = useAccountControl()
  const { account_id } = useParams<{ account_id?: string }>()

  const { handleMovePage } = useCustomHistory()
  const { inputAssetsSummaryList, inputAssets } = useSelector(
    (state: RootState) => state.inputAssets,
  )
  const { account } = useSelector((state: RootState) => state.accounts)

  const inputAssetArr = useMemo(() => {
    if (_isNull(inputAssetsSummaryList)) return []

    return [...inputAssetsSummaryList?.input_assets]
  }, [inputAssetsSummaryList])

  useEffect(() => {
    dispatch(
      setPageMeta({
        title: 'マイデータ | 金融資産',
        description: 'マイデータ | 金融資産',
        keyword: 'マイデータ | 金融資産',
      }),
    )
    if (_isNull(inputAssetsSummaryList)) dispatch(fetchInputAssetSummary())
    if ((account?.id ?? 0) < 1) dispatch(fetchAccount())
  }, [])

  const moveMoneytreeStatusPage = () => {
    const url = getMoneytreeVaultUrl('/v2/myData/assets')
    window.open(url)
  }

  const renderInfoIconWithToolTip = (inputAsset: InputAsset) => {
    return (
      <Tooltip
        itemClassName="w-[150px] md:w-[170px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
        item={
          <>
            {!isNisaAccount(inputAsset?.account_type) && (
              <Typography>
                課税対象：
                {convertIsTaxChargable(inputAsset?.is_tax_chargable)}
              </Typography>
            )}
            {isNisaAccount(inputAsset?.account_type) && (
              <>
                <Typography>
                  利用開始年：
                  {`${extractYearOrMonthFromDate(
                    inputAsset?.investment_start_at,
                    'year',
                  )}年`}
                </Typography>
                {inputAsset?.account_type == ACCOUNT_TYPE.nisa && (
                  <Typography>
                    ロールオーバー：{convertRolloverType(inputAsset?.rollover_type)}
                  </Typography>
                )}
              </>
            )}
          </>
        }
      >
        <InfoIcon />
      </Tooltip>
    )
  }

  const renderMtConnectionStatus = (inputAsset: InputAsset) => {
    return (
      <>
        {inputAsset?.['is_connected_with_moneytree?'] ? (
          inputAsset?.aggregation_status_str ? (
            <Tooltip
              item={`${inputAsset.aggregation_status_str}。赤文字部分をクリックしてMoneytreeのページで連携状態を確認してください。`}
              itemClassName="w-[220px] p-[8px] text-[10px] text-alert-600 bg-alert-400 leading-tight before:border-t-alert-400"
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={moveMoneytreeStatusPage}
              >
                <CautionIcon />
                <Typography className="text-alert-600">連携失敗</Typography>
              </div>
            </Tooltip>
          ) : (
            <Typography className="text-secondary-500">連携中</Typography>
          )
        ) : (
          <Typography className="">手入力</Typography>
        )}
      </>
    )
  }

  // MEMO: PC表示用一覧
  const inputAssetTableRowPc = (inputAsset: InputAsset) => (
    <tr className="border-b border-black-300">
      <td className={`${classes.pc.leftCell} pl-[10px] max-w-[165px]`}>
        <div className="mb-5 break-words">
          <Typography>
            {defaultToEmptyString(inputAsset?.institution_name) +
              defaultToEmptyString(inputAsset?.current_name)}
          </Typography>
        </div>
        <div className="flex">
          <Chip className="px-8 bg-tertiary-500 pb-2 text-white text-[10px] max-h-[18px]">
            {convertInputAssetPersonFlg(
              inputAsset.person_flg as number | null,
              inputAsset.family_structure_child?.child_index,
            )}
          </Chip>
          {isNisaAccount(inputAsset?.account_type) && (
            <Chip className=" ml-5 px-8 bg-white pb-2 text-gray-500 text-[10px] max-h-[18px] border border-gray-500">
              {convertAccountType(inputAsset?.account_type)}
            </Chip>
          )}
        </div>
      </td>
      <td className={`${classes.pc.leftCell} pl-[5px]`}>
        {renderInfoIconWithToolTip(inputAsset)}
      </td>
      <td className={`${classes.pc.leftCell} pl-[10px]`}>
        {renderMtConnectionStatus(inputAsset)}
      </td>
      <td
        className={clsx(`${classes.pc.rightCell} max-w-[150px]`, {
          'text-alert-600': (inputAsset?.current_value ?? 0) < 0,
        })}
      >
        {`残高：${convertLocaleString(inputAsset?.current_value)}`}円
        {isInvestmentAssetType(inputAsset?.asset_type) && (
          <>
            <br />
            {`積立額：${convertReservedAmountMonthly(
              inputAsset?.reserved_amount_monthly,
            )}`}
          </>
        )}
      </td>
      <td className={`${classes.pc.rightCell} pr-[10px]`}>
        {`${defaultToEmptyString(inputAsset.interest_rate_yearly_percent)}%`}
      </td>
      <td className={`${classes.pc.leftCell}`}>
        <div className="flex justify-end">
          <Button
            className="w-[130px] md:w-[110px] lg:w-[120px] h-[40px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
            isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
            onClick={() =>
              handleMovePage(
                isAdmin
                  ? `/v2/accounts/${account_id}/myData/assets/edit/${inputAsset.id}`
                  : `/v2/myData/assets/edit/${inputAsset.id}`,
              )
            }
          >
            <Typography className={classes.detailButton} isBold>
              詳細・
              <br />
              積立を設定する
            </Typography>
          </Button>

          <div className="m-auto cursor-pointer hover:bg-black-200">
            <TrashIcon onClick={() => handleDestroy(inputAsset.id)} />
          </div>
        </div>
      </td>
    </tr>
  )

  // MEMO: SP表示用一覧
  const inputAssetTableRowSp = (inputAsset: InputAsset) => (
    <div className="flex flex-col pb-24 space-y-16 border-b border-black-300">
      <div className="flex justify-end items-center space-x-12 text-14">
        <Button
          className="w-[150px] h-[26px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
          isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
          onClick={() =>
            handleMovePage(
              isAdmin
                ? `/v2/accounts/${account_id}/myData/assets/edit/${inputAsset.id}`
                : `/v2/myData/assets/edit/${inputAsset.id}`,
            )
          }
        >
          <Typography className={classes.detailButton} isBold>
            詳細・積立を設定する
          </Typography>
        </Button>

        <Button
          className="w-[84px] h-[26px] rounded-[4px] bg-white border border-black-600 hover:bg-secondary-50"
          isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
          onClick={() => handleDestroy(inputAsset.id)}
        >
          <Typography
            className="relative bottom-[1px] text-[12px] md:text-[14px] text-black-600"
            isBold
          >
            削除する
          </Typography>
        </Button>
      </div>

      <Typography className="text-16 max-w-[300px] break-all" isBold>
        <div className="flex">
          <Typography className="mr-[5px]">
            {defaultToEmptyString(inputAsset?.institution_name) +
              defaultToEmptyString(inputAsset?.current_name)}
          </Typography>
          {renderInfoIconWithToolTip(inputAsset)}
        </div>
      </Typography>

      <div className={classes.sp.itemBlock}>
        <Typography isBold>連携設定</Typography>
        {renderMtConnectionStatus(inputAsset)}
      </div>

      <div className={classes.sp.itemBlock}>
        <Typography isBold>名義人</Typography>
        <Typography>
          {convertInputAssetPersonFlg(
            inputAsset.person_flg as number | null,
            inputAsset.family_structure_child?.child_index,
          )}
        </Typography>
      </div>

      <div className={classes.sp.itemBlock}>
        <Typography isBold>種別</Typography>
        <Typography>{convertInputAssetType(inputAsset?.asset_type, true)}</Typography>
      </div>

      {isInvestmentAssetType(inputAsset?.asset_type) && (
        <div className={classes.sp.itemBlock}>
          <Typography isBold>口座種類</Typography>
          <Typography>{convertAccountType(inputAsset?.account_type)}</Typography>
        </div>
      )}

      <div className={classes.sp.itemBlock}>
        <Typography isBold>残高</Typography>
        <Typography
          className={clsx('flex-1', {
            'text-alert-600': (inputAsset?.current_value ?? 0) < 0,
          })}
        >
          {convertLocaleString(inputAsset?.current_value)}円
        </Typography>
      </div>
      {isInvestmentAssetType(inputAsset?.asset_type) && (
        <div className={classes.sp.itemBlock}>
          <Typography isBold>積立額</Typography>
          <Typography>
            {convertReservedAmountMonthly(inputAsset?.reserved_amount_monthly)}
          </Typography>
        </div>
      )}
      <div className={classes.sp.itemBlock}>
        <Typography isBold>想定利回り</Typography>
        <Typography>{`${defaultToEmptyString(
          inputAsset.interest_rate_yearly_percent,
        )}%`}</Typography>
      </div>
    </div>
  )

  const handleDestroy = (id: number) => {
    const result = confirm('本当に削除してよろしいですか？')
    if (!result) return

    const destroyFunc = new Promise((resolve) => {
      dispatch(destroyInputAsset(id, () => resolve('')))
    })

    Promise.all([destroyFunc]).then(() => {
      dispatch(fetchInputAssetSummary())
      dispatch(fetchAccount())
      dispatch(updateResetFlg())
      showFlashMsg('削除しました')
    })
  }

  const inputAssetUpdatedAt = useMemo(() => {
    const unixTimes = inputAssets.map((item) =>
      Number(moment(item?.updated_at).format('X')),
    )
    return unixTimes.length !== 0
      ? `(最終更新日: ${moment(String(Math.max(...unixTimes)), 'X').format(
          'YYYY/MM/DD',
        )})`
      : undefined
  }, [inputAssets])

  return (
    <MyDataListWrapper title="金融資産">
      <div className="mb-[50px]">
        <div className="sm:flex block justify-between">
          <div className="flex flex-row gap-x-[8px] gap-y-[4px] items-baseline">
            <CircleTitle title="金融資産" classNameTitle="text-[20px] md:text-[24px]" />
            <Typography className="text-[13px] md:text-[16px] text-[#9E9E9E]">
              {inputAssetUpdatedAt}
            </Typography>
          </div>

          <div className="md:flex md:justify-end my-[10px] items-baseline">
            {!_isNull(inputAssetsSummaryList) &&
              inputAssetsSummaryList.input_assets.length > 0 && (
                <>
                  {account?.['has_connected_with_moneytree?'] && (
                    <div className="flex md:block justify-end">
                      <Button
                        className="px-[5px] md:px-[10px] h-[26px] mt-[10px] md:mt-0 mb-[10px] md:mb-0  md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                        isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                        onClick={() =>
                          window.open(getMoneytreeVaultUrl('/v2/myData/assets'))
                        }
                      >
                        <Typography
                          className="flex items-center relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500 min-w-[max-content]"
                          isBold
                        >
                          Moneytreeを連携して口座を追加
                          <div className="ml-[5px] md:ml-[10px]">
                            <ExternalLink className="relative" />
                          </div>
                        </Typography>
                      </Button>
                    </div>
                  )}
                  <div className="flex md:block justify-end">
                    <Button
                      className="w-[100px] md:w-[112px] h-[26px] md:h-[37px] mr-[0] md:mr-[9px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                      isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                      onClick={() =>
                        handleMovePage(
                          isAdmin
                            ? `/v2/accounts/${account_id}/myData/assets/new`
                            : '/v2/myData/assets/new',
                        )
                      }
                    >
                      <Typography
                        className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                        isBold
                      >
                        新しく追加する
                      </Typography>
                    </Button>
                  </div>
                </>
              )}
          </div>
        </div>
        {!_isNull(inputAssetsSummaryList) &&
          inputAssetsSummaryList.input_assets.length === 0 && (
            <div>
              <Typography
                className="pb-[15px] md:pb-[24px] text-[15px] md:text-[18px] text-center"
                isBold
              >
                金融資産が登録されていません。
                <br className="md:hidden" />
                下記のボタンから登録してみましょう。
              </Typography>
              <div className="md:flex md:justify-evenly">
                {account?.['has_connected_with_moneytree?'] && (
                  <Button
                    className="px-[5px] md:px-[10px] h-[26px] mt-[10px] md:mt-0 mb-[10px] md:mb-0 md:h-[37px] mx-auto md:mx-[0] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                    isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                    onClick={() => window.open(getMoneytreeVaultUrl('/v2/myData/assets'))}
                  >
                    <Typography
                      className="flex items-center relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500 min-w-[max-content]"
                      isBold
                    >
                      Moneytreeを連携して口座を追加
                      <div className="ml-[5px] md:ml-[10px]">
                        <ExternalLink className="relative" />
                      </div>
                    </Typography>
                  </Button>
                )}
                <Button
                  className="mx-auto md:mx-[0] w-[104px] md:w-[112px] h-[26px] md:h-[37px] rounded-[4px] bg-white border border-secondary-500 hover:bg-secondary-50"
                  isDisabled={isAdmin ? !isMyDataManipulatedApproval() : false}
                  onClick={() =>
                    handleMovePage(
                      isAdmin
                        ? `/v2/accounts/${account_id}/myData/assets/new`
                        : '/v2/myData/assets/new',
                    )
                  }
                >
                  <Typography
                    className="relative bottom-[1px] text-[12px] md:text-[14px] text-secondary-500"
                    isBold
                  >
                    新しく追加する
                  </Typography>
                </Button>
              </div>
            </div>
          )}

        {!_isNull(inputAssetsSummaryList) &&
          inputAssetsSummaryList.input_assets.length > 0 && (
            <div className="flex flex-col md:flex-row md:items-center justify-between mb-40 p-16 md:p-24 rounded-4 bg-secondary-50">
              <Typography className="text-18" isBold>
                金融資産総額
              </Typography>
              <div className="flex items-baseline ml-auto mr-0">
                <Typography className="text-30" isBold>
                  {convertLocaleString(
                    inputAssetsSummaryList?.financial_asset_total_amount,
                  )}
                </Typography>
                <Typography className="pl-5 text-16" isBold>
                  円
                </Typography>
              </div>
            </div>
          )}

        {/* Memo: PC表示 */}
        {!_isNull(inputAssetsSummaryList) &&
          inputAssetsSummaryList.input_assets.length > 0 && (
            <table className="hidden md:table w-full">
              <thead>
                <tr className="border-b border-black-300">
                  <th className={`${classes.pc.headerLeftCell} w-[25%] pl-[5px]`}>
                    接続先名称・名義人
                  </th>
                  <th className={`${classes.pc.headerLeftCell} w-[5%] pl-[10px]`} />
                  <th className={`${classes.pc.headerLeftCell} w-[12%] pl-[10px]`}>
                    連携設定
                  </th>
                  <th className={`${classes.pc.headerRightCell} w-[23%] max-w-[150px]`}>
                    残高・積立額
                  </th>
                  <th className={`${classes.pc.headerRightCell} w-[15%] pr-[10px]`}>
                    想定利回り
                  </th>
                  <th className={`${classes.pc.headerRightCell} w-[20%]`} />
                </tr>
              </thead>
              <tbody>
                {INPUT_ASSET_TYPE_SELECT_ITEMS.map((item, index) => (
                  <React.Fragment key={index}>
                    {hasSummaryOf(item?.value as AssetType, inputAssetArr) && (
                      <>
                        <tr className="bg-tertiary-300 sticky top-[87px] z-[10]">
                          <td className="py-8 pl-[10px] text-left">
                            {convertInputAssetType(item?.value as AssetType, true)}
                          </td>
                          <td colSpan={3} className="py-8">
                            <span className="mr-5">総額</span>
                            {convertLocaleString(
                              inputAssetsSummaryList?.summary?.[item?.value as AssetType]
                                ?.total_amount,
                            )}
                            円
                          </td>
                          <td colSpan={2} />
                        </tr>
                        {filterInputAsset(item?.value as AssetType, inputAssetArr).map(
                          (inputAsset, assetIndex) => (
                            <React.Fragment key={assetIndex}>
                              {inputAssetTableRowPc(inputAsset)}
                            </React.Fragment>
                          ),
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}

        {/* Memo: SP表示 */}
        {!_isNull(inputAssetsSummaryList) &&
          inputAssetsSummaryList.input_assets.length > 0 && (
            <div className="md:hidden">
              {INPUT_ASSET_TYPE_SELECT_ITEMS.map((item, index) => (
                <React.Fragment key={index}>
                  {hasSummaryOf(item?.value as AssetType, inputAssetArr) && (
                    <>
                      <div className="mb-16 px-5 py-8 bg-tertiary-300 leading-[28px]">
                        <Typography className="text-16" isBold>
                          {convertInputAssetType(item?.value as AssetType, true)}
                        </Typography>
                        <div className="flex justify-end items-center space-x-[3px] text-18">
                          <Typography isBold>総額</Typography>
                          <Typography>
                            {convertLocaleString(
                              inputAssetsSummaryList?.summary?.[item?.value as AssetType]
                                ?.total_amount,
                            )}
                            円
                          </Typography>
                        </div>
                      </div>
                      <div className="space-y-24">
                        {filterInputAsset(item?.value as AssetType, inputAssetArr).map(
                          (inputAsset, assetIndex) => (
                            <React.Fragment key={assetIndex}>
                              {inputAssetTableRowSp(inputAsset)}
                            </React.Fragment>
                          ),
                        )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
      </div>

      {/** MEMO: 新しい金融資産のフォームが正式に反映されるまで一旦コメントアウト */}
      {/* <MyDataAssetInvestment /> */}
    </MyDataListWrapper>
  )
}

export default MyDataAsset
