import React, { FC, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import ChevronRightIcon from '@/assets/images/v2/chevron-right.svg'
import ButtonPrimary from '@/components/v2/atoms/ButtonPrimary'
import Grid from '@/components/v2/atoms/Grid'
import Modal from '@/components/v2/atoms/Modal'
import Typography from '@/components/v2/atoms/Typography'
import CircleTitle from '@/components/v2/molecules/CircleTitle'
import SelectBox from '@/components/v2/molecules/SelectBox'
import {
  FutureEstateInvestmentSale,
  FutureEstateInvestmentSaleCreateValues,
} from '@/models/futures/futureEstateInvestmentSalesModel'

import { convertDateToJapaneseFormat } from '../../../models/commonsModelFunc'
import { TWO_TYPES_YEAR_SELECT_ITEMS } from '@/constants/formItem/commons'
import { buildRangeAgeSelectItems, renderStartAtYearOptions } from '@/utils/formItem'
import { calcSaleCostManyen } from '@/models/futures/futureHousingSalesModelFunc'
import {
  castNumberWithoutOperator,
  isNotEmptyValue,
  extractYearOrMonthFromDate,
} from '@/models/commonsModelFunc'

import NumberField from '@/components/v2/molecules/NumberField'
import {
  composeValidators,
  zeroOrMoreNumber,
  commaNumberMaxLength,
  required,
} from '@/utils/validate'
import { find as _find, isNull as _isNull } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import {
  createFutureEstateInvestmentSaleV2,
  updateFutureEstateInvestmentSaleV2,
} from '@/containers/futures/futureEstateInvestmentSalesSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import Tooltip from '@/components/v2/atoms/Tooltip'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import SubmitErrorMessage from '@/components/v2/molecules/SubmitErrorMessage'
import clsx from 'clsx'
interface FutureEstateInvestmentSaleModalFormProps {
  /** true: オープン, false: クローズ */
  isOpen: boolean
  /** onCloseメソッド */
  onClose: () => void
  /** onCloseメソッド */
  isEdit: boolean

  /** 編集時のID */
  id: number | null
  /* FutureItemsを参照するID */
  futureBaseId?: number
}

const FutureEstateInvestmentSaleModalForm: FC<FutureEstateInvestmentSaleModalFormProps> = ({
  isOpen,
  onClose,
  isEdit,
  id,
  futureBaseId,
}) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()

  const [purchasedYear, setPurchasedYear] = useState<any>(2022)

  const futureEstateInvestmentSales = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investment_sales,
  )

  const futureEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_estate_investments,
  )

  const inputEstateInvestments = useSelector(
    (state: RootState) => state.accounts.futureItems?.input_estate_investments,
  )

  const futureLendHousings = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_lend_housings,
  )

  const {
    applicableHousing: {
      sellable_housings: { future_lend_housing_ids },
    },
  } = useSelector((state: RootState) => state.futureBases)

  const targetEstateInvestmentSale: FutureEstateInvestmentSale | undefined = useMemo(
    () => (!_isNull(id) ? _find(futureEstateInvestmentSales, { id }) : undefined),
    [futureEstateInvestmentSales],
  )

  const inputEsateItems = useMemo(
    () =>
      inputEstateInvestments?.map((item: any) => ({
        value: `InputEstateInvestment_${item.id}`,
        label: `${convertDateToJapaneseFormat(item.delivery_date, 'year')}購入 ${
          item.property_price_manyen
        }万円`,
      })),
    [],
  )

  const futureEstateItems = useMemo(
    () =>
      futureEstateInvestments?.map((item: any) => ({
        value: `FutureEstateInvestment_${item.id}`,
        label: `${convertDateToJapaneseFormat(item.purchased_at, 'year')}購入 ${
          item.property_price_manyen
        }万円`,
      })),
    [],
  )

  const futureLendHousingItems = useMemo(
    () =>
      future_lend_housing_ids.reduce((acc: any[], id: number) => {
        const targetLendHousing = _find(futureLendHousings, (lend) => lend.id === id)
        if (!targetLendHousing) return acc
        acc.push({
          value: `FutureLendHousing_${targetLendHousing?.id}`,
          label: `${convertDateToJapaneseFormat(
            targetLendHousing?.lend_start_at,
            'year',
          )}賃貸として貸し出し 月間賃料${targetLendHousing?.rent_income_monthly}円`,
        })
        return acc
      }, []),
    [],
  )

  const propertySelectItems = [
    ...inputEsateItems!,
    ...futureEstateItems!,
    ...futureLendHousingItems,
  ]

  const updatePurchaseYear = (value: any) => {
    const estateInvestmentId = Number(value.split('_')[1])

    const futureLendHousing = _find(
      futureLendHousings,
      (item) => item.id === estateInvestmentId,
    )
    const futureEstate = _find(
      futureEstateInvestments,
      (item) => item.id === estateInvestmentId,
    )
    const inputEstate = _find(
      inputEstateInvestments,
      (item) => item.id === estateInvestmentId,
    )

    return setPurchasedYear(
      !!futureLendHousing
        ? Number(extractYearOrMonthFromDate(futureLendHousing?.lend_start_at, 'year'))
        : futureEstate
        ? Number(extractYearOrMonthFromDate(futureEstate.purchased_at, 'year'))
        : Number(extractYearOrMonthFromDate(inputEstate?.delivery_date, 'year')),
    )
  }

  const sellAtSelectItems = useMemo(() => {
    return renderStartAtYearOptions(purchasedYear)
  }, [purchasedYear])

  const handleCreate = (values: FutureEstateInvestmentSaleCreateValues) => {
    const pickedValues: any = values
    const { future_estate_investment_sales, estate_investment } = pickedValues

    const estateInvestmentId = Number(estate_investment.split('_')[1])
    const estateInvestmentType = estate_investment.split('_')[0]

    const newValues: any = {
      account: {
        future_estate_investment_sales_attributes: [
          {
            estate_investment_id: estateInvestmentId,
            estate_investment_type: estateInvestmentType,
            sale_amount_manyen: future_estate_investment_sales.sale_amount_manyen,
            cost_manyen: future_estate_investment_sales.cost_manyen,
            sell_at: `${future_estate_investment_sales?.sell_at_year}/01/01`,
          },
        ],
      },
    }

    return new Promise((resolve) =>
      dispatch(
        createFutureEstateInvestmentSaleV2(
          newValues,
          () => {
            dispatch(fetchAccountFutureItems(futureBaseId))
            showFlashMsg(isEdit ? '更新しました' : '作成しました')
            onClose()
          },
          resolve,
        ),
      ),
    )
  }

  const handleUpdate = (values: any) => {
    const { estate_investment } = values

    const estateInvestmentId = Number(estate_investment.split('_')[1])
    const estateInvestmentType = estate_investment.split('_')[0]

    const newValues: any = {
      future_estate_investment_sale: {
        ...values.future_estate_investment_sales,
        estate_investment_id: estateInvestmentId,
        estate_investment_type: estateInvestmentType,
        sell_at: `${values.future_estate_investment_sales?.sell_at_year}/01/01`,
      },
    }

    dispatch(
      updateFutureEstateInvestmentSaleV2(newValues, id as any, () => {
        dispatch(fetchAccountFutureItems(futureBaseId))
        showFlashMsg(isEdit ? '更新しました' : '作成しました')
        onClose()
      }),
    )
  }

  const handleSubmit = (values: any) => {
    return isEdit ? handleUpdate(values) : handleCreate(values)
  }

  const initialValues = useMemo(() => {
    return isEdit
      ? {
          estate_investment: `${targetEstateInvestmentSale?.estate_investment_type}_${targetEstateInvestmentSale?.estate_investment_id}`,
          future_estate_investment_sales: {
            ...targetEstateInvestmentSale,
            sell_at_year: extractYearOrMonthFromDate(
              targetEstateInvestmentSale?.sell_at,
              'year',
            ),
          },
        }
      : {}
  }, [])

  return (
    <Modal
      className="w-[95%] xl:w-[1000px]"
      isOpen={isOpen}
      isDisableBackdropClick
      onClose={onClose}
    >
      <CircleTitle
        className="pb-[32px]"
        title="不動産投資の売却を設定する"
        classNameTitle="text-[19px] md:text-[24px]"
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit,
          values,
          errors,
          submitErrors,
          submitFailed,
          form: { change },
        }) => {
          return (
            <form onSubmit={handleSubmit} className="pb-30">
              <div>
                <Grid className="pb-[64px] grid-cols-1 md:grid-cols-2 gap-x-[40px] gap-y-[16px]">
                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                    <div className="flex items-center min-w-[98px] lg:min-w-[164px] pb-[10px] lg:pb-0">
                      <Typography
                        className="text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        売却する物件
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        isFullWidth
                        name="estate_investment"
                        placeholder="選択してください"
                        options={propertySelectItems}
                        onChange={(event: any) => updatePurchaseYear(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                    <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                      <Typography
                        className="text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        売却予定（年）
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <SelectBox
                        isFullWidth
                        name="future_estate_investment_sales.sell_at_year"
                        placeholder="年を選択"
                        options={sellAtSelectItems}
                      />
                    </div>
                  </div>

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                    <div className="flex items-center min-w-[140px] lg:min-w-[164px] pb-[10px] lg:pb-0 ">
                      <Typography
                        className="text-[14px] text-black-800 flex items-center"
                        isBold
                      >
                        売却金額
                      </Typography>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        isFullWidth
                        name="future_estate_investment_sales.sale_amount_manyen"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                        onBlur={(event: any) => {
                          const value = event.target.value
                          if (!isNotEmptyValue(value)) return
                          change(
                            'future_estate_investment_sales.cost_manyen',
                            calcSaleCostManyen(castNumberWithoutOperator(value)),
                          )
                        }}
                      />
                      <Typography
                        className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  </div>
                  <div className="hidden md:block" />

                  <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2">
                    <div className="flex items-center min-w-[148px] lg:min-w-[203px] pb-[10px] lg:pb-0 ">
                      <div>
                        <Typography
                          className="text-[14px] text-black-800 flex items-center"
                          isBold
                        >
                          売却にかかる費用
                          <Tooltip
                            item={
                              <>
                                <Typography className="u-fs12">
                                  例）仲介手数料・印紙代など
                                </Typography>
                              </>
                            }
                            itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50 pr-16 "
                          >
                            <HelpCircleIcon />
                          </Tooltip>
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-auto items-baseline">
                      <NumberField
                        isFullWidth
                        name="future_estate_investment_sales.cost_manyen"
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 10),
                        )}
                      />
                      <Typography
                        className="pl-[8px] text-[14px] text-black-800 min-w-[40px]"
                        isBold
                      >
                        万円
                      </Typography>
                    </div>
                  </div>
                  <div className="lg:flex pb-[16px]">
                    <Typography className="text-10 text-black-700">
                      自動で入力される売却にかかる費用は、
                      <br className="hidden md:block" />
                      （売却金額 x 3％ + 6万円) x 1.1 + 10万円 で算出しています。
                      <br className="hidden md:block" />
                      修正が必要な場合は入力内容を変更してください。
                    </Typography>
                  </div>
                </Grid>
              </div>

              <SubmitErrorMessage show={!submitErrors && submitFailed} />
              <ButtonPrimary
                className={clsx(
                  { ['opacity-50']: Object.keys(errors).length > 0 },
                  'w-[fit-content] md:w-[246px] h-[64px] mx-[auto]',
                )}
                onClick={() => handleSubmit(values)}
              >
                <Typography
                  className="bottom-2 flex-auto pl-30 relative text-16 text-white"
                  isBold
                >
                  保存する
                </Typography>
                <ChevronRightIcon className="mr-17" />
              </ButtonPrimary>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default FutureEstateInvestmentSaleModalForm