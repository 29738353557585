import React, { FC, useContext, useEffect } from 'react'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Clear as ClearIcon } from '@material-ui/icons'
import { isUndefined as _isUndefined } from 'lodash'
import {
  MONTH_KANJI_SELECT_ITEMS,
  NEW_DAY_KANJI_SELECT_ITEMS,
  TWO_TYPES_YEAR_SELECT_ITEMS,
} from '@/constants/formItem/commons'
import {
  INPUT_BONUS_FLG_RADIO_ITEMS,
  INPUT_RATE_CHANGE_RADIO_ITEMS,
  INPUT_REPAYMENT_DATE_SELECT_ITEMS,
} from '@/constants/formItem/inputs/inputHousings'
import { DescriptionContext } from '@/hooks/useDescriptionTemplates'
import { API_FLAG, THIS_YEAR } from '@/models/commonsModelFunc'
import { selectLoanObjectNumber } from '@/models/commonHousingModelFunc'
import { InputAdvance } from '@/models/inputs/inputAdvancesModel'
import { selectLoanChartObjectName } from '@/models/inputs/inputLoanChartsModelFunc'
import { InputLoanRate } from '@/models/inputs/inputLoanRatesModel'
import { buildStandardSelectItems, buildDaySelectItems } from '@/utils/formItem'
import {
  commaNumberMaxLength,
  composeValidators,
  zeroOrMoreNumber,
  required,
} from '@/utils/validate'

import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import SelectBox from '@/components/v2/molecules/SelectBox'
import NumberField from '@/components/v2/molecules/NumberField'
import NumberFieldDecimal from '@/components/v2/molecules/NumberFieldDecimal'
import IconButton from '@/components/v2/atoms/IconButton'
import PlusCircleIcon from '@/assets/images/v2/plus-circle.svg'
import CrossIcon from '@/assets/images/v2/cross.svg'
import HelpCircleIcon from '@/assets/images/v2/help-circle.svg'
import Tooltip from '@/components/v2/atoms/Tooltip'

interface RepaymentPlanProps {
  /** true: 建物用ローン, false: 土地用ローン・分割なし */
  isBuilding?: boolean
  /** true: 配偶者, false: ご本人 */
  isSpouse?: boolean
}

const RepaymentPlan: FC<RepaymentPlanProps> = (props) => {
  const { isBuilding, isSpouse } = props
  const { pickupDescriptionTemplates, setSendPageValueKeys } = useContext(
    DescriptionContext,
  )
  const { change, getState } = useForm()
  const { values } = getState()
  const loanObjectNum = selectLoanObjectNumber(isBuilding, isSpouse)

  const {
    input: { value: rateChangeMonthly },
  } = useField(selectLoanChartObjectName(loanObjectNum, 'rate_change_monthly'))
  const {
    input: { value: bonusFlg },
  } = useField(selectLoanChartObjectName(loanObjectNum, 'bonus_flg'))


  const {
    input: { value: repaymentEndYear },
  } = useField(
    selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_year'),
  );
  const {
    input: { value: repaymentEndMonth },
  } = useField(
    selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_month'),
  );

  const {
    input: { value: repaymentYear },
  } = useField(
    selectLoanChartObjectName(loanObjectNum, 'repayment_date_this_month_at_year'),
  );
  const {
    input: { value: repaymentMonth },
  } = useField(
    selectLoanChartObjectName(loanObjectNum, 'repayment_date_this_month_at_month'),
  );

  useEffect(() => {
    pickupDescriptionTemplates(values)
  }, [rateChangeMonthly, bonusFlg])

  useEffect(() => {
    setSendPageValueKeys([
      selectLoanChartObjectName(loanObjectNum, 'repayment_date_this_month_at_year'),
      selectLoanChartObjectName(loanObjectNum, 'repayment_date_this_month_at_month'),
      selectLoanChartObjectName(loanObjectNum, 'repayment_date_this_month_at_day'),
      selectLoanChartObjectName(loanObjectNum, 'remaining_principal_monthly_total'),
      selectLoanChartObjectName(loanObjectNum, 'bonus_flg'),
      selectLoanChartObjectName(loanObjectNum, 'remaining_principal_bonus_total'),

      selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_year'),
      selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_month'),
      selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_day'),
      selectLoanChartObjectName(loanObjectNum, 'rate_change_monthly'),
      selectLoanChartObjectName(loanObjectNum, 'input_loan_rates_attributes'),
      selectLoanChartObjectName(loanObjectNum, 'input_loan_rates_monthly_attributes'),
    ])
  }, [])

  const handleAddInputRateMonthlyBlock = (
    loanRates: Partial<InputLoanRate>[],
    index: number,
    change: (key: string, values: Partial<InputAdvance>[]) => void,
  ) => {
    const isEnd = index + 1 == loanRates.length
    isEnd ? loanRates.push({}) : loanRates.splice(index + 1, 0, {})

    change(
      selectLoanChartObjectName(loanObjectNum, 'input_loan_rates_monthly_attributes'),
      loanRates,
    )
  }

  const renderYearSelectItems = () => {
    return buildStandardSelectItems(TWO_TYPES_YEAR_SELECT_ITEMS, THIS_YEAR, '年を選択')
  }

  return (
    <>
      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] text-[14px] text-black-800"
          isBold
        >
          返済計画表に記載の
          <br className="hidden lg:block" />
          最終回返済日
        </Typography>
        <div className="flex flex-auto items-center">
          <SelectBox
            isFullWidth
            name={selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_year')}
            isPlaceholderHidden
            options={renderYearSelectItems()}
            validate={required}
          />
          <SelectBox
            isFullWidth
            className="mx-[10px]"
            name={selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_month')}
            placeholder="月を選択"
            options={MONTH_KANJI_SELECT_ITEMS}
            validate={required}
          />
          <SelectBox
            isFullWidth
            name={selectLoanChartObjectName(loanObjectNum, 'repayment_end_at_day')}
            placeholder="日を選択"
            options={buildDaySelectItems(Number(repaymentEndYear), Number(repaymentEndMonth))}
            validate={required}
          />
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-center">
        <div>
          <Typography
            className="flex items-center min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            今月の返済予定日
            <div className="mx-[10px]">
              <Tooltip
                item="手元の返済計画表から今月の返済予定日の欄をご確認ください"
                itemClassName="w-[80px] sm:w-[200px] p-[8px] text-[10px] text-secondary-500 bg-secondary-50 leading-tight before:border-t-secondary-50"
              >
                <HelpCircleIcon />
              </Tooltip>
            </div>
          </Typography>
        </div>
        <div className="flex flex-auto items-center">
          <SelectBox
            name={selectLoanChartObjectName(
              loanObjectNum,
              'repayment_date_this_month_at_year',
            )}
            isFullWidth
            isPlaceholderHidden
            options={buildStandardSelectItems(
              INPUT_REPAYMENT_DATE_SELECT_ITEMS,
              THIS_YEAR,
              '年を選択',
            )}
            validate={required}
          />
          <SelectBox
            className="mx-[10px]"
            name={selectLoanChartObjectName(
              loanObjectNum,
              'repayment_date_this_month_at_month',
            )}
            placeholder="月を選択"
            isFullWidth
            options={MONTH_KANJI_SELECT_ITEMS}
            validate={required}
          />
          <SelectBox
            name={selectLoanChartObjectName(
              loanObjectNum,
              'repayment_date_this_month_at_day',
            )}
            placeholder="日を選択"
            isFullWidth
            options={buildDaySelectItems(Number(repaymentYear), Number(repaymentMonth))}
            validate={required}
          />
        </div>
      </div>

      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          毎月支払い分の残元金合計
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberField
            name={selectLoanChartObjectName(
              loanObjectNum,
              'remaining_principal_monthly_total',
            )}
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 10),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            円
          </Typography>
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          ボーナス払いの有無
        </Typography>
        <SelectBox
          name={selectLoanChartObjectName(loanObjectNum, 'bonus_flg')}
          placeholder="選択する"
          isFullWidth
          options={INPUT_BONUS_FLG_RADIO_ITEMS}
          validate={required}
        />
      </div>

      {bonusFlg && Number(bonusFlg) == API_FLAG.on && (
        <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
          <Typography
            className="min-w-[140px] lg:min-w-[210px] pb-[10px] md:pb-0 text-[14px] text-black-800"
            isBold
          >
            ボーナス支払い分の
            <br className="hidden md:block" />
            残元金合計
          </Typography>
          <div className="flex flex-auto items-baseline">
            <NumberField
              name={selectLoanChartObjectName(
                loanObjectNum,
                'remaining_principal_bonus_total',
              )}
              isFullWidth
              validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
                commaNumberMaxLength(value, 10),
              )}
            />
            <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
              円
            </Typography>
          </div>
        </div>
      )}

      <div className="md:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] md:pb-0 text-[14px] text-black-800"
          isBold
        >
          現在の金利
        </Typography>
        <div className="flex flex-auto items-baseline">
          <NumberFieldDecimal
            name={selectLoanChartObjectName(
              loanObjectNum,
              'input_loan_rates_attributes[0]changed_rate_percent',
            )}
            isFullWidth
            validate={composeValidators(required, zeroOrMoreNumber, (value: any) =>
              commaNumberMaxLength(value, 6),
            )}
          />
          <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
            ％
          </Typography>
        </div>
      </div>

      <div className="lg:flex pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
        <Typography
          className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
          isBold
        >
          金利の変化
        </Typography>
        <SelectBox
          name={selectLoanChartObjectName(loanObjectNum, 'rate_change_monthly')}
          placeholder="選択する"
          isFullWidth
          options={INPUT_RATE_CHANGE_RADIO_ITEMS}
          validate={required}
        />
      </div>

      {rateChangeMonthly && Number(rateChangeMonthly) == API_FLAG.on && (
        <div>
          <Typography
            className="min-w-[140px] lg:min-w-[210px] pb-[10px] lg:pb-0 text-[14px] text-black-800"
            isBold
          >
            金利の変化
          </Typography>
          <div className="pb-[16px] border-black-300 border-dashed border-b-2 items-baseline">
            <FieldArray
              name={selectLoanChartObjectName(
                loanObjectNum,
                'input_loan_rates_monthly_attributes',
              )}
            >
              {({ fields }) =>
                fields.map((name, index) => (
                  <React.Fragment key={name}>
                    <div className="flex flex-auto items-baseline mb-[10px]">
                      {index > 0 && (
                        <IconButton
                          icon="remove"
                          children={<ClearIcon />}
                          onClick={() => fields.remove(index)}
                        />
                      )}
                      <SelectBox
                        name={`${name}rate_start_at_year`}
                        isFullWidth
                        isPlaceholderHidden
                        options={renderYearSelectItems()}
                        validate={required}
                      />
                      <SelectBox
                        isFullWidth
                        name={`${name}rate_start_at_month`}
                        placeholder="月を選択"
                        options={MONTH_KANJI_SELECT_ITEMS}
                        validate={required}
                        className="ml-[10px]"
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800 w-[121px]">
                        から
                      </Typography>
                      <NumberFieldDecimal
                        name={`${name}changed_rate_percent`}
                        isFullWidth
                        validate={composeValidators(
                          required,
                          zeroOrMoreNumber,
                          (value: any) => commaNumberMaxLength(value, 6),
                        )}
                      />
                      <Typography className="pl-[8px] text-[14px] text-black-800" isBold>
                        %
                      </Typography>
                      <div className="min-w-[10%] mr-[-50px] pl-[15px]">
                        {index > 0 && (
                          <CrossIcon
                            onClick={() => fields.remove(index)}
                            className="cursor-pointer"
                          />
                        )}
                      </div>
                    </div>

                    {!_isUndefined(fields) && fields.length == index + 1 && (
                      <Button
                        className="ml-auto border-[0.5px] border-black-700 rounded-[4px]"
                        onClick={() =>
                          handleAddInputRateMonthlyBlock([...fields.value], index, change)
                        }
                      >
                        <div className="flex items-center ">
                          <PlusCircleIcon className="ml-[10px] mt-[2px]" />
                          <Typography
                            className="min-w-[120p] p-[10px] text-[14px] text-black-800 flex items-center"
                            isBold
                          >
                            入力枠を追加する
                          </Typography>
                        </div>
                      </Button>
                    )}
                  </React.Fragment>
                ))
              }
            </FieldArray>
          </div>
        </div>
      )}
    </>
  )
}

export default RepaymentPlan
