import React, { FC, useState, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { includes as _includes, isEmpty as _isEmtpy, filter as _filter } from 'lodash'
import Button from '@/components/v2/atoms/Button'
import Typography from '@/components/v2/atoms/Typography'
import { FutureAsset } from '@/models/futures/futureAssetsModel'
import { fetchAccountFutureItems } from '@/containers/accountsSlice'
import { destroyFutureAssetV2 } from '@/containers/futures/futureAssetsSlice'
import { useFlashAlert } from '@/hooks/useFlashAlert'
import { AssetAnnual } from '@/models/assetAnnualsModel'
import { convertAnnual } from '@/models/assetAnnualsModelFunc'
import {
  convertInvestmentType,
  CONTINUAL_TRANSFER_TYPE,
  convertContinualTransferType,
  convertTransferType,
  TRANSFER_TYPE,
  LAST_AGE,
} from '@/models/commonAssetsModelFunc'
import {
  THIS_YEAR_NUMBER,
  convertDateToTwoTypesYearsDateFormat,
  convertLocaleString,
} from '@/models/commonsModelFunc'
import { SimulationDetailEditContext } from '@/models/futures/futureBasesModelFunc'
import SimulationAccordion from '@/templates/v2/simulations/_simulationAccordion'
import FutureAssetModalForm from '@/templates/v2/simulations/_futureAssetModalForm'
import SimulationNewButton from '@/templates/v2/simulations/_simulationNewButton'
import { RootState } from '@/store'
import SimulationItemAccordion from '@/templates/v2/simulations/_simulationItemAccordion'

interface FutureMarriageFormProps {
  /** 選択シミュレーションID */
  selectedSimulationId: number[] | undefined
  /** 選択メソッド */
  handleSelect: (item: any) => void
  /* FutureItemsを参照するID */
  futureBaseId?: number
  /* 初期化すべきかどうか */
  shouldInitialize?: boolean
  /* 編集を確認したか */
  confirmEdit?: boolean
  /* 編集確認モーダルを展開するメソッド */
  openEditConfirmModal?: () => void
}

interface FutureAssetTableProps {
  /** アイテム */
  item: any
  /** td要素のクラス */
  tdClassName?: string
}

export const FutureAssetTable: FC<FutureAssetTableProps> = ({ item, tdClassName }) => {
  return (
    <table
      key={item.id}
      className={clsx('w-[100%] mb-[20px] last:mb-0 text-[12px] md:text-[14px] bg-white')}
    >
      <tbody>
        <tr className="h-[45px] odd:bg-black-100">
          <td className={`${tdClassName ?? 'w-[50%]'} px-[16px]`}>
            <Typography isBold>振替頻度</Typography>
          </td>
          <td className="px-[16px]">
            <Typography>{convertTransferType(item.transfer_type)}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px]">
            <Typography isBold>資産の運用方法</Typography>
          </td>
          <td className="px-[16px]">
            <Typography>{convertInvestmentType(item.investment_type)}</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px]">
            <Typography isBold>
              {item.transfer_type == TRANSFER_TYPE.once ? '振替年' : '開始年'}
            </Typography>
          </td>
          <td className="px-[16px]">
            <Typography>
              {convertDateToTwoTypesYearsDateFormat(
                item.investment_start_at,
                'yearMonthWithJapaneseYear',
              )}
            </Typography>
          </td>
        </tr>

        {item.transfer_type == TRANSFER_TYPE.countinual && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>終了年</Typography>
            </td>
            <td className="px-[16px]">
              {item?.investment_end_at_age == String(LAST_AGE) ? (
                <Typography>終身まで</Typography>
              ) : (
                <Typography>
                  {convertDateToTwoTypesYearsDateFormat(
                    item.investment_end_at,
                    'yearMonthWithJapaneseYear',
                  )}
                </Typography>
              )}
            </td>
          </tr>
        )}

        {item.transfer_type == TRANSFER_TYPE.once && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>振替金額</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>{convertLocaleString(item.amount)}円</Typography>
            </td>
          </tr>
        )}

        {item.transfer_type == TRANSFER_TYPE.countinual && (
          <tr className="h-[45px] odd:bg-black-100">
            <td className="px-[16px]">
              <Typography isBold>積立額の設定方法</Typography>
            </td>
            <td className="px-[16px]">
              <Typography>
                {convertContinualTransferType(item.continual_transfer_type)}
              </Typography>
            </td>
          </tr>
        )}

        {item.transfer_type == TRANSFER_TYPE.countinual &&
          item.continual_transfer_type == CONTINUAL_TRANSFER_TYPE.input && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography isBold>年間積立額</Typography>
              </td>
              <td className="px-[16px]">
                <Typography>
                  {convertLocaleString(item.continual_transfer_input)}円
                </Typography>
              </td>
            </tr>
          )}

        {item.transfer_type == TRANSFER_TYPE.countinual &&
          item.continual_transfer_type == CONTINUAL_TRANSFER_TYPE.rate && (
            <tr className="h-[45px] odd:bg-black-100">
              <td className="px-[16px]">
                <Typography className="min-w-[max-content]" isBold>
                  年間貯蓄額に対する積立額の比率
                </Typography>
              </td>
              <td className="px-[16px]">
                <Typography>{item.continual_transfer_rate_percent}%</Typography>
              </td>
            </tr>
          )}

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px]">
            <Typography isBold>運用利回り</Typography>
          </td>
          <td className="px-[16px]">
            <Typography>{item.annual_percent}%</Typography>
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px]">
            <Typography isBold>運用利回りの変化</Typography>
          </td>
          <td className="px-[16px]">
            {_isEmtpy(item.asset_annuals) ? (
              <Typography>変化なし</Typography>
            ) : (
              <>
                {item.asset_annuals.map((annual: AssetAnnual, index: number) => (
                  <React.Fragment key={index}>
                    <Typography>{convertAnnual(annual)}</Typography>
                  </React.Fragment>
                ))}
              </>
            )}
          </td>
        </tr>

        <tr className="h-[45px] odd:bg-black-100">
          <td className="px-[16px]">
            <Typography isBold>課税対象</Typography>
          </td>
          <td className="px-[16px]">
            <Typography>
              {item.is_tax_chargable ? '課税対象とする' : '課税対象としない'}
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const FutureAssetForm: FC<FutureMarriageFormProps> = (props) => {
  const dispatch = useDispatch()
  const { showFlashMsg } = useFlashAlert()
  const { isManipulatedApproval } = useContext(SimulationDetailEditContext)

  const { selectedSimulationId, handleSelect } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [assetId, setAssetId] = useState<number | null>(null)

  const futureAssets = useSelector(
    (state: RootState) => state.accounts.futureItems?.future_assets,
  )

  useEffect(() => {
    if (props.futureBaseId == null || !props.shouldInitialize) return
    _filter(futureAssets, { future_base_id: props.futureBaseId }).forEach((item) =>
      handleSelect(item),
    )
  }, [props.shouldInitialize])

  const sortedFutureAssets = useMemo(() => {
    if (props.futureBaseId == null || futureAssets == null) return futureAssets
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return [
      ...futureAssets.filter((item) => eqFbId(item)),
      ...futureAssets.filter((item) => !eqFbId(item)),
    ]
  }, [futureAssets])

  const titleIndex = (index: number) => {
    if (props.futureBaseId == null || props.confirmEdit == null || futureAssets == null)
      return index + 1
    const eqFbId = (item: any) => item.future_base_id === props.futureBaseId
    return index + 1 - futureAssets.filter((item) => eqFbId(item)).length
  }

  const handleExecIfConfired = (exec: (item: any) => void, item: any) => {
    props.confirmEdit === false ? props.openEditConfirmModal?.() : exec(item)
  }

  return (
    <SimulationAccordion
      className="pb-[16px]"
      title="資産運用の検討"
      isSelected={selectedSimulationId != null && selectedSimulationId.length !== 0}
      selectedCount={selectedSimulationId?.length}
      itemCount={sortedFutureAssets?.length}
    >
      <div className="flex flex-col gap-y-[12px] pt-[6px]">
        {sortedFutureAssets?.map((item: FutureAsset, index: number) => {
          const handleDestroyV2 = (item: any) => {
            const result = confirm('本当に削除してよろしいですか？')
            if (!result) return

            const destroyFunc = new Promise((resolve) => {
              dispatch(
                destroyFutureAssetV2(item, item.id, () => {
                  resolve('')
                  showFlashMsg('削除しました')
                }),
              )
            })

            Promise.all([destroyFunc]).then(() => {
              if (_includes(selectedSimulationId, item.id)) handleSelect(item)
              dispatch(fetchAccountFutureItems(props.futureBaseId))
            })
          }

          const handleEdit = (item: any) => {
            setAssetId(item.id)
            setIsEdit(true)
            setIsOpenModal(true)
          }

          return (
            <SimulationItemAccordion
              key={`${item.id}_${index}`}
              title={
                props.futureBaseId === item.future_base_id && props.confirmEdit != null
                  ? '登録中の資産運用'
                  : `資産運用${titleIndex(index)}`
              }
              isSelected={_includes(selectedSimulationId, item.id)}
              inputType="checkbox"
              onSelect={() => {
                handleExecIfConfired(handleSelect, item)
              }}
            >
              <div className="flex justify-end mb-[5px]">
                <Button
                  className="mr-[16px]"
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleEdit, item)
                    e.stopPropagation()
                  }}
                >
                  <i className="ico edit text-[14px] text-black-700" />
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    編集
                  </Typography>
                </Button>
                <Button
                  isDisabled={!isManipulatedApproval}
                  onClick={(e) => {
                    handleExecIfConfired(handleDestroyV2, item)
                    e.stopPropagation()
                  }}
                >
                  <Typography className="pl-[5px] text-[14px] text-black-700">
                    削除
                  </Typography>
                </Button>
              </div>
              <FutureAssetTable item={item} />
            </SimulationItemAccordion>
          )
        })}
      </div>
      <SimulationNewButton
        isDisabled={!isManipulatedApproval}
        onClick={() => {
          setAssetId(null)
          setIsEdit(false)
          setIsOpenModal(true)
        }}
      />

      {isOpenModal && (
        <FutureAssetModalForm
          isOpen={isOpenModal}
          isEdit={isEdit}
          onClose={() => setIsOpenModal(false)}
          id={assetId}
          futureBaseId={props.futureBaseId}
        />
      )}
    </SimulationAccordion>
  )
}

export default FutureAssetForm